/** the new version of lsutil, using ES6 classes and modules */
/* jshint -W014 */
/* global recalcSizes, CKEDITOR */
/* eslint-disable eqeqeq */

/**
 * LSUtils
 * static class
 * .AddOnload(func) -- adds "func" to list of functions called when window receives "onload";
 * .ExtendByNames   -- takes an object id, and a prototype name, and reblesses the object into
 *                     the prototype's class.
 */
 const LSUtil = {
  __window_loaded: false,
  __na_option_value: "________NO_SELECTION__________",
  lightwindow: null
};
/**
 * @this {Element}
 */
LSUtil.GetValue = function (element) {
  if (typeof element === 'string') {
    element = document.getElementById(element);
  }
  if (!element) {
    return '';
  }
  if (element.tagName === "SELECT") {
    const one = ! element.multiple;
    let selectedOptions = [];
    for (let i = 0; i < element.options.length; i++) {
      if (element.options[i].selected && !element.options[i].disabled && !element.options[i].parentNode.disabled) {
        if ( one ) {
          return element.options[i].value ? element.options[i].value : "";
        }
        selectedOptions.push(element.options[i].value);
      }
    }
    return selectedOptions;

  } else if (element.tagName === "INPUT" && element.type === "radio") {

    let name = element.name;
    let selectedOption = document.querySelector(`input[name="${name}"]:checked`);
    return selectedOption ? selectedOption.value : "";

  } else if (element.tagName === "INPUT" && element.type === "checkbox") {

    return element.checked;

  } else {

    try {
      try {
        // element.value may be an array of values.
        const parsedValue = JSON.parse(element.value);
        if (typeof parsedValue === 'number') {
          return element.value;
        } else {
          return parsedValue;
        }
      } catch (e) {
        return element.value;
      }
    } catch (e) {
      return "";
    }
  }
};
/**
 * @this {Element}
 */
LSUtil.SendChange = function (element) {
  var event = document.createEvent("HTMLEvents");
  event.initEvent("change", true, true, window);
  element.dispatchEvent(event);
};

LSUtil.IsLoaded = function (func) {
  return LSUtil.__window_loaded;
};

/**
 * @this {Element}
 */
LSUtil.AddOnload = function (func) {
  if (LSUtil.__window_loaded) {
    func();
    return;
  }
  var old_onload = window.onload;

  /**
   * @this {Element}
   */
  window.onload = function () {
    func();
    if (old_onload) {
      old_onload();
    }
  };
};

LSUtil.__listFuncs = [];
LSUtil.AddListLoad = function (func) {
  if (LSUtil.__listFuncs.indexOf(func) === -1) {
    LSUtil.AddOnload(func);
    LSUtil.__listFuncs.push(func);
  }
};

LSUtil.CallListLoad = function () {
  var i;
  for (i = 0; i < LSUtil.__listFuncs.length; i++) {
    LSUtil.__listFuncs[i]();
  }
};

/**
 * @this {Element}
 */
LSUtil.ParentForm = function (element) {
  var ancestors = $(element).ancestors();
  var form = null;
  var i;
  for (i = 0; i < ancestors.length; i++) {
    if (ancestors[i].match("form")) {
      form = ancestors[i];
    }
  }

  return form;
};

LSUtil.AddOnload(function () {
  LSUtil.__window_loaded = true;
  $(document.body).fire("nni:OnLoaded");
});

/**
 * @this {Element}
 */
LSUtil.ObservePostLoad = function (handler) {
  Event.observe($(document.body), "nni:OnLoaded", handler);
};

var entityMap = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;"
};

LSUtil.escapeHtml = function (string) {
  // eslint-disable-next-line no-useless-escape
  return String(string).replace(/[&<>"'`=\/]/g, function (s) {
    return entityMap[s];
  });
};

/**
 * @this {Element}
 */
LSUtil.SimpleHTML2Txt = function (text) {
  text = text.replace(/\s+/g, " ");
  text = text.replace(/<br\s*\/>/gi, "\n");
  text = text.replace(/<table.+?>/gi, "\n");
  text = text.replace(/<\/\s*tr\s*>/gi, "\n");
  text = text.replace(/<.+?>/g, " ");
  text = text.replace(/<\s*script.*?>.+?<\/script>/gi, "");
  return text;
};

LSUtil.simulateClick = function (target) {
  var evt = document.createEvent("MouseEvents");
  evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  var canceled = !target.dispatchEvent(evt);
  if (canceled) {
    // A handler called preventDefault
    throw "Canceled";
  } else {
    // None of the handlers called preventDefault
    throw "Not canceled";
  }
};

/**
 * Get caret within a text input
 */
LSUtil.get_caret_pos = function (oField) {
  var iCaretPos = 0;
  if (Prototype.Browser.IE) {
    var oSel = document.selection.createRange();
    oSel.moveStart("character", -oField.value.length);
    iCaretPos = oSel.text.length;
  } else {
    iCaretPos = oField.selectionEnd;
  }
  return iCaretPos;
};

/**
 * Set Caret Pos (for a field)
 * Sets the caret position within a text input.
 */

LSUtil.set_caret_pos = function (oField, iCaretPos) {
  if (Prototype.Browser.IE) {
    var oSel = document.selection.createRange();
    oSel.moveStart("character", -oField.value.length);
    oSel.moveStart("character", iCaretPos);
    oSel.moveEnd("character", 0);
    oSel.select();
  } else {
    oField.selectionStart = iCaretPos;
    oField.selectionEnd = iCaretPos;
    oField.focus();
  }
};

/**
 * @this {Element}
 */
LSUtil.Debug = function () {
  var args = ["LegalServer: "];
  var i;
  for (i = 0; i < arguments.length; i++) {
    if (typeof arguments[i] == "object") {
      // Assume it's an exception:
      var e = arguments[i];
      var text = "Caught exception: ";
      if (e.name) {
        text += "[" + e.name + "] ";
      }

      if (e.fileName) {
        text += e.fileName;
        if (e.lineNumber) {
          text += "(" + e.lineNumber + ")";
        }
        text += ": ";
      }

      if (e.message) {
        text += e.message;
      }

      if (e.stack) {
        text += "\n\n" + e.stack;
      }

      args[i + 1] = text;
    } else {
      args[i + 1] = arguments[i];
    }
  }

  try {
    // eslint-disable-next-line no-console
    console.log.apply(console, args);
  } catch (e2) {
    // no firebug.  try for other browsers
    try {
      // eslint-disable-next-line no-console
      console.log(args.join("; "));
    } catch (e3) {
      // do nothing.
    }
  }
  try {
    // eslint-disable-next-line no-console
    console.open();
     
  } catch (e4) {}
  var j;
  var cur_arg;
  if (LSUtil.IsDebug()) {
    try {
      var debug_div = document.createElement("div"); ///new Element('div', { style: 'color: red;' });
      debug_div.style = "color: red";
      for (j = 0; j < args.length; j++) {
        cur_arg = args[j];
        var debug_item = document.createElement("pre");
        debug_item.appendChild(document.createTextNode(cur_arg));
        debug_div.appendChild(debug_item);
      }

      var element = document.getElementById("debug_errors");
      if (element !== null) {
        element.appendChild(debug_div);
      }
    } catch (e5) {
      alert(e5);
      /* eslint-disable-next-line no-console */
      console.log(e5);
    }
  }
};
/**
 * @this {Element}
 */
LSUtil.IsDebug = function () {
  // Use try/catch in case the variable isn't set.
  try {
    var ls_debug = __ls_debug__;
    return ls_debug;
  } catch (e) {
    return false;
  }
};

var LSUtil_CSSLoaded = {};
/**
 * @this {Element}
 */
LSUtil.loadCSSInclude = function (item) {
  if (LSUtil_CSSLoaded[item]) {
    return;
  }
  LSUtil_CSSLoaded[item] = true;
  var headTag = document.getElementsByTagName("head")[0];
  var link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = item;

  headTag.appendChild(link);
};
/**
 * @this {Element}
 */
LSUtil.loadCSSIncludes = function (list) {
  $A(list).each(function (item) {
    LSUtil.loadCSSInclude(item);
  });
};

if (!LSUtil_JSLoaded) {
  var LSUtil_JSLoaded = {};
}
if (!LSUtil_SRIhashes) {
  // This will be populated dynamically.
  var LSUtil_SRIhashes = {};
}

/**
 * @this {Element}
 */
LSUtil.loadJSInclude = function (item, handler) {
  if (LSUtil_JSLoaded[item]) {
    if (handler) {
      handler(item);
    }
    return;
  }
  var existing_item;
  // first, check to see if this was loaded with a script tag
  var existing_scripts = $$('body script[src="' + item + '"]');

  var full_url = location.protocol + "//" + location.host + item;
  if (item.indexOf("http://") === -1) {
    existing_scripts.concat($$('body script[src="' + full_url + '"]'));
  }

  if (existing_scripts.length > 0) {
    existing_item = existing_scripts[0];
    if (existing_item.src == item || existing_item.src === full_url) {
      // it's a dupe, skip it
      LSUtil_JSLoaded[item] = true;
      if (handler) {
        // execute completion callback
        handler(item);
      }
      return;
    }
  }
  LSUtil_JSLoaded[item] = true;
  var headTag = document.getElementsByTagName("head")[0];
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = item;
  script.crossorigin = "anonymous";
  script.rel = "noopener noreferrer";
  if (item in LSUtil_SRIhashes) {
    // SRI hash was found. Add it.
    script.integrity = LSUtil_SRIhashes[item];
  }

  if (handler) {
    var done = false;
    /**
     * @this {Element}
     */
    script.onload = script.onreadystatechange = function () {
      if (!done && (!this.readyState || this.readyState == "loaded" || this.readyState == "complete")) {
        done = true;
        // IE memory leak
        script.onload = script.onreadystatechange = null;
        headTag.removeChild(script);
        handler(script.src);
      }
    };
  }

  headTag.appendChild(script);
};
/**
 * @this {Element}
 */
LSUtil.loadJSIncludes = function (list, handler) {
  if (!list.length) {
    if (handler) {
      handler();
    }
    return;
  }

  //    var handler = handler;
  var done = 0;
  var nitem = list.length;
  /**
   * @this {Element}
   */
  var itemHandler = function () {
    done++;
    if (done == nitem) {
      handler();
    }
  };

  $A(list).each(function (item) {
    LSUtil.loadJSInclude(item, itemHandler);
  });
};

LSUtil.ajaxSearchSelector = Class.create(Autocompleter.Base, {
  initialize: function (
    element,
    update,
    label_element,
    parent_element,
    method,
    options,
    multiple,
    always_include_remove_link,
    include_checkbox,
    checkbox_checked
  ) {
    options.afterUpdateElement = this.afterUpdateElement.bind(this);
    this.baseInitialize(element, update, options);
    this.options.onComplete = this.onComplete.bind(this);
    this.method = method;
    this.parent_element = $(parent_element);
    this.label_element = $(label_element);
    this.multiple = multiple;
    this.always_include_remove_link = always_include_remove_link;
    this.include_checkbox = include_checkbox;
    this.checkbox_checked = checkbox_checked;
    this.checkbox = null;
    this.update = $(update);
    this.element = $(element);
    this.last_token = null;
    this.top_offset = 0;
    this.bottom_offset = 0;
    this.num_results = 0;
    this.blur_timeout = null;
    this.max_range = 0;
    this.params = null;
    this.delim = ":";
    this.remove_initialized = false;
    this.label_values = [];
    this.no_results = false;
    this.memo = null;

    if (options.delim) {
      this.delim = options.delim;
    }

    if (options.notFoundMessage) {
      this.notFoundMessage = options.notFoundMessage;
    } else {
      this.notFoundMessage = "No Results";
    }

    if ((this.multiple && this.emptiable) || this.always_include_remove_link || this.emptiable) {
      this.initRemoveLinks();
    }

    this.emptiable = false;

    // Move to dedicated function
    if (this.include_checkbox) {
      this.addCheckbox();
    }
  },
  addCheckbox: function () {
    var id = this.parent_element.id + "_search";
    var div = $(id).parentElement;
    var sub_div = new Element("div", { id: id + "_checkbox_div", class: "ajax_checkbox_div" });
    div.appendChild(sub_div);
    var checkbox = new Element("INPUT", { id: id + "_checkbox", class: "ajax_checkbox" });
    checkbox.setAttribute("type", "checkbox");
    if (this.checkbox_checked) {
      checkbox.checked = true;
    }
    this.checkbox = checkbox;
    div.appendChild(checkbox);
    var checkbox_label = new Element("label", { for: id + "_checkbox" }).update("Show All Values");
    div.appendChild(checkbox_label);
  },
  getElementValue: function () {
    return this.memo.id;
  },
  /**
   * extend selectEntry so we can short-circuit if this.no_results is true
   * this fixes a bug where we could select a value for "No Results"
   */
  selectEntry: function () {
    if (this.no_results) {
      return;
    }
    this.active = false;
    this.updateElement(this.getCurrentEntry());
  },
  getUpdatedChoices: function () {
    if (parseInt(this.update.style.left.substr(0, this.update.style.left.length - 2), 10) <= 0) {
      // elements hidden on form load won't display properly
      // unless we move the update box to the proper position
      Element.clonePosition(this.update, this.element, {
        setHeight: false,
        offsetTop: Element.getHeight(this.element) + 2
      });
    }
    var token = this.getToken();
    if (token != this.last_token) {
      // rest as this is a new search
      this.top_offset = this.bottom_offset = this.num_results = this.max_range = 0;
    }
    this.last_token = token;

    var params = [this.getToken(), this.top_offset];
    if (this.params) {
      params = params.concat(this.params);
    }
    if (this.include_checkbox) {
      params.push(this.checkbox.checked);
    }

    this.parent_element.ajaxRequest(this.method, params, {
      method: "post",
      onSuccess: this.options.onComplete
    });
  },
  onComplete: function (transport, data) {
    var text = "";
    var i;
    var id;
    var label;
    var result;
    this.no_results = false; // init this; set to true below if we get no valid results back
    if (data.results) {
      //  New return format
      if (!this.num_results) {
        //  First search, so keep track of the number of results
        this.num_results = parseInt(data.num_results, 10);
      }
      if (data.results.length > this.max_range) {
        this.max_range = data.results.length;
      }
      if (data.status) {
        this.updateChoices('<ul><li class="auto_complete"><i>' + data.status + "</i><span></span>" + "</li></ul>");
        return;
      } else if (this.num_results === 0) {
        this.no_results = true;
        this.updateChoices(
          '<ul><li class="auto_complete no_results" data-selectable="0"><i>' +
            this.notFoundMessage +
            "</i><span></span></li></ul>"
        );
        return;
      }
      for (i = 0; i < data.results.length; i++) {
        result = data.results[i];
        id = result.id;
        label = result.label;
        text =
          text +
          '<li class="auto_complete"><span class="auto_complete_label">' +
          label +
          '</span><span class="auto_complete_id" style="display: none">' +
          id +
          "</span></li>";
      }
      this.bottom_offset = this.top_offset + data.results.length;
      if (this.bottom_offset > this.num_results) {
        this.bottom_offset = this.num_results;
      }

      //  Add first and last
      if (this.top_offset > 0) {
        text =
          '<li class="auto_complete"><span class="auto_complete_label"> --- Previous ---' +
          '</span><span class="auto_complete_id" style="display: none">Previous</span></li>' +
          text;
      }
      if (this.bottom_offset < this.num_results) {
        text =
          text +
          '<li class="auto_complete"><span class="auto_complete_label"> --- Next ---' +
          '</span><span class="auto_complete_id" style="display: none">Next</span></li>';
      }

      text = "<ul>" + text + "</ul>";

      this.updateChoices(text);
    } else {
      //  Old return format

      if (data.length === 0) {
        this.no_results = true;
        this.updateChoices(
          '<ul><li class="auto_complete no_results" data-selectable="0"><i>' +
            this.notFoundMessage +
            "</i><span></span></li></ul>"
        );
        return;
      }

      for (i = 0; i < data.length; i++) {
        result = data[i];
        id = result.id;
        label = result.label;
        text =
          text +
          '<li class="auto_complete"><span class="auto_complete_label">' +
          label +
          '</span><span class="auto_complete_id" style="display: none">' +
          id +
          "</span></li>";
      }

      text = "<ul>" + text + "</ul>";

      this.updateChoices(text);
    }
  },
  //  Almost identical to the scriptaculous function, save much of the operations only occur if not
  //  Previous/Next and that the hide() function was moved in here from onClick
  // 2022-08-31: Added autoFocus based on unwanted behavior found in LS-93016
  updateElement: function (selectedElement, autoFocus) {
    if (this.options.updateElement) {
      this.options.updateElement(selectedElement);
      return;
    }
    var id = selectedElement.firstDescendant().next().innerHTML;
    var memo = {};
    memo.label = null;
    memo.id = null;
    memo.search = this.element.value;

    if (id != "Previous" && id != "Next") {
      memo.label = selectedElement.firstDescendant().innerHTML;
      memo.id = selectedElement.firstDescendant().next().innerHTML;
      var value = "";
      if (this.options.select) {
        var nodes = document.getElementsByClassName(this.options.select, selectedElement) || [];
        if (nodes.length > 0) {
          value = Element.collectTextNodes(nodes[0], this.options.select);
        }
      } else {
        value = Element.collectTextNodesIgnoreClass(selectedElement, "informal");
      }

      var lastTokenPos = this.findLastToken();
      if (lastTokenPos != -1) {
        var newValue = this.element.value.substr(0, lastTokenPos + 1);
        var whitespace = this.element.value.substr(lastTokenPos + 1).match(/^\s+/);
        if (whitespace) {
          newValue += whitespace[0];
        }
        this.element.value = newValue + value;
      } else {
        this.element.value = value;
      }
      if (autoFocus !== false) {
          this.element.focus();
      }
      this.hide();
      this.memo = memo;
      if (
        this.callbackElFunc != undefined &&
        this.callbackElFunc != null &&
        this.callbackElFunc.length != null &&
        this.callbackElFunc.length > 0
      ) {
        this.callbackElFunc.forEach(
          function (callbackFuncArray) {
            var callbackEl = $(callbackFuncArray[0]);
            var callbackFunc = callbackFuncArray[1];
            var isAjaxFunc = false;
            if (callbackFuncArray.length > 2) {
              isAjaxFunc = callbackFuncArray[2];
            }
            if (!isAjaxFunc) {
              callbackEl[callbackFunc]();
            } else {
              this.onSuccessFunc = null;
              if (callbackFuncArray.length > 3) {
                this.onSuccessFunc = callbackFuncArray[3];
              }
              callbackEl.ajaxRequest(callbackFunc, this.memo.id, {
                method: "post",
                onSuccess: function (transport, data) {
                  // @todo Allow passing the ajaxRequest options arg instead.
                  var onSuccessFuncArray = this.onSuccessFunc;
                  if (onSuccessFuncArray != null) {
                    var onSuccessEl = $(onSuccessFuncArray[0]);
                    var onSuccessFunc = onSuccessFuncArray[1];
                    onSuccessEl[onSuccessFunc]();
                  }
                }.bind(this)
              });
            }
          }.bind(this)
        );
      }
    } else {
      if (this.blur_timeout) {
        clearTimeout(this.blur_timeout);
      }
    }
    if (this.options.afterUpdateElement) {
      this.options.afterUpdateElement(this.element, selectedElement);
    }

    this.parent_element.fire("nni:change", memo);
  },
  //  Almost identical to the scriptaculous function, save that it does not hide (that's done in our
  //  updateElement)
  onClick: function (event) {
    var element = Event.findElement(event, "LI");
    this.index = element.autocompleteIndex;
    this.selectEntry();
  },
  //  Almost identical to the scriptaculous function, save it keeps track of the timeout
  onBlur: function (event) {
    // needed to make click events working
    this.blur_timeout = setTimeout(this.hide.bind(this), 250);
    this.hasFocus = false;
    this.active = false;
  },
  /**
   * @this {Element}
   */
  afterUpdateElement: function (element, selectedElement) {
    var label = selectedElement.firstDescendant().innerHTML;
    var id = selectedElement.firstDescendant().next().innerHTML;

    if (id != "Previous" && id != "Next") {
      element.value = "";
      this.addSelection(id, label);
    } else {
      if (id == "Previous") {
        this.bottom_offset = this.top_offset;
        this.top_offset -= this.max_range;
      } else {
        this.top_offset = this.bottom_offset;
        this.bottom_offset += this.max_range;
      }
      this.updateChoices('<ol><li class="auto_complete"><i>Updating Results</i><span></span></li></ol>');
      this.activate();
    }
  },
  /**
   * @this {Element}
   */
  addSelection: function (id, label) {
    if (typeof id != "string" && !(id instanceof String)) {
      id = String(id);
    }
    var ids = this.parent_element.value.split(this.delim);
    if (ids[0] == 0) {
      ids = [];
    }

    if (ids.indexOf(id) != -1) {
      // dupe
      return;
    }

    if (!this.multiple) {
      // clear the id list and html
      ids = [];
      this.label_element.innerHTML = "";
      this.label_values = [];
    }

    ids.push(id);
    this.parent_element.value = ids.join(this.delim);
    // @todo Add a fire/trigger event here for onchange event on the this.parent_element when prototype supports native event firing
    // temporarily use nni:autocomplete_change to avoid breaking code that doesn't expect an nni:change to come from here
    if (window.jQuery) {
      // needed for reports. @todo: use jquery/prototype event bridge: https://github.com/Widen/jquery-prototype-custom-event-bridge
      jQuery(this.parent_element).trigger("nni:autocomplete_change");
    }
    var new_div = new Element("div", { id: this.parent_element.id + "_" + this.normalizeId(id) }).update(label + " ");
    this.label_values.push(label);
    this.label_element.appendChild(new_div);

    if ((this.multiple && this.emptiable) || this.always_include_remove_link || this.emptiable) {
      this.addRemoveLink(id);
    }
  },
  /**
   * @this {Element}
   */
  addRemoveLink: function (id) {
    var div = $(this.parent_element.id + "_" + this.normalizeId(id));
    if (div && div.select(".ajax_remove_link") && div.select(".ajax_remove_link").length === 0) {
      /* jshint -W107 */
      var a = new Element("a", {
        id: div.id + "_remove",
        class: "ajax_remove_link",
        href: "javascript:void(0)"
      }).update(" [Remove]");
      /* jshint +W107 */
      div.appendChild(a);
      Event.observe(a, "click", this.removeSelection.bindAsEventListener(this, id));
    }
  },
  /**
   * @this {Element}
   */
  initRemoveLinks: function () {
    var i;
    if (this.remove_initialized) {
      return;
    }
    this.remove_initialized = true;
    var ids = this.parent_element.value.split(this.delim);

    if (ids[0] == 0) {
      return;
    }

    for (i = 0; i < ids.length; i++) {
      this.addRemoveLink(ids[i]);
    }
  },
  /**
   * @this {Element}
   */
  removeSelection: function (e, id) {
    var ids = this.parent_element.value.split(this.delim);
    if (ids[0] == 0) {
      ids = [];
    }
    $(this.parent_element.id + "_" + this.normalizeId(id)).remove();

    var index = ids.indexOf(id);
    if (index != -1) {
      ids.splice(index, 1);
    }

    this.parent_element.value = ids.join(this.delim);
    this.parent_element.fire("nni:change");
    if (window.jQuery) {
      // needed for reports. @todo: use jquery/prototype event bridge: https://github.com/Widen/jquery-prototype-custom-event-bridge
      jQuery(this.parent_element).trigger("nni:change");
    }

    return false;
  },
  /**
   * @this {Element}
   */
  findLastToken: function () {
    //  copy-pasted from old scriptaculous
    var lastTokenPos = -1;
    var i;
    for (i = 0; i < this.options.tokens.length; i++) {
      var thisTokenPos = this.element.value.lastIndexOf(this.options.tokens[i]);
      if (thisTokenPos > lastTokenPos) {
        lastTokenPos = thisTokenPos;
      }
    }
    return lastTokenPos;
  },
  /**
   * @this {Element}
   */
  setEmptiable: function (value) {
    this.emptiable = value;
    if (value) {
      this.initRemoveLinks();
    }
  },

  /**
   * @this {Element}
   */
  setAjaxParams: function (params) {
    this.params = params;
  },

  setCallbackElFunc: function (func) {
    this.callbackElFunc = func;
  },

  normalizeId: function (id) {
    return id.replace(/[^\w]/g, "_");
  },
  describe: function () {
    return this.label_values.join("; ");
  }
});

/* the same as regular autocompleter, but with proper positioning of the choice list. */
LSUtil.Autocompleter = Class.create();
Object.extend(Object.extend(LSUtil.Autocompleter.prototype, Ajax.Autocompleter.prototype), {
  initialize: function (element, update, url, options) {
    this.element = $(element);
    this.update = $(update);
    options.onShow = options.onShow || this.onShow.bind(this);
    this.baseInitialize(element, update, options);
    this.options.asynchronous = true;
    this.options.onComplete = this.trueOnComplete.bind(this);
    this.options.defaultParams = this.options.parameters || null;
    this.url = url;
  },
  onShow: function (element, update) {
    if (!update.style.position || update.style.position == "absolute") {
      Element.absolutize(update);
      if (
        !(
          Prototype.Browser.IE && parseInt(navigator.userAgent.substring(navigator.userAgent.indexOf("MSIE") + 5)) == 8
        ) ||
        element.getHeight() > 0
      ) {
        try {
          // jquery does this much better
          jQuery(update).position(jQuery(element).position());
          jQuery(update).width(jQuery(element).width());
        } catch (e) {
          // fall back to the terrible prototype.js code
          Element.clonePosition(update, element, {
            setHeight: false,
            offsetTop: element.offsetHeight
          });
        }
      } else {
        var node = element;
        var node_id = 0;
        var invisible_nodes = [];
        // If we're hidden, we report dimensions as 0, at least
        // in MSIE 8. So, recurse up the parent tree and show divs.
        // Then, calculate dimension stuff. Finally, revert previously
        // hidden divs.
        if (node.offsetParent) {
          do {
            if (!node.visible()) {
              node.show();
              invisible_nodes.push(node.id);
            }
            /* jshint -W084 */
             
          } while ((node = node.up("div")));
          /* jshint +W084 */

          Position.clone(element, update, {
            setHeight: false,
            offsetTop: element.offsetHeight
          });

          /* jshint -W084 */
           
          while ((node_id = invisible_nodes.pop())) {
            /* jshint +W084 */
            $(node_id).hide();
          }
        }
      }
    }
    Effect.Appear(update, { duration: 0.15 });
  },

  /* don't replace typed entries with 'No Results' */
  selectEntry: function () {
    var selectedElement = this.getCurrentEntry();
    var value = Element.collectTextNodes(selectedElement);
    if (value != "No Results") {
      Ajax.Autocompleter.prototype.selectEntry.call(this);
    }
  },
  trueOnComplete: function (request) {
    // elements hidden on form load won't display properly
    // unless we move the update box to the proper position
    //TKTS #65913 switches the logic here to no longer use Element.clonePosition which has known issues.
    this.update.style.position = "absolute";
    this.update.style.top = Element.getHeight(this.element) + 2 + this.element.positionedOffset().top + "px";
    this.update.style.left = this.element.positionedOffset().left + "px";
    return this.onComplete(request);
  }
});

window.debug_warning_count = 0;
/**
 * @this {Element}
 */
LSUtil.ExtendByNames = function (obj_name, prototype_name, args) {
  var obj;
  var prototype;

  try {
    obj = $(obj_name);
  } catch (e) {
    return;
  }

  if (!obj) {
    window.debug_warning_count++;
    LSUtil.Debug(
      "Object with id <" +
        obj_name +
        "> not found. You may have an object misidentifying itself." +
        " Check modules/form/form_input.php for more info."
    );
    return;
  }

  prototype = window[prototype_name];

  if (prototype) {
    args.unshift(obj);
     
    prototype.rebless.apply(prototype, args);
  }
};

LSUtil.DebugExtendByNames = function (obj_name, prototype_name, args, file, line) {
  try {
    return LSUtil.ExtendByNames(obj_name, prototype_name, args);
  } catch (e) {
    e.fileName = file;
    e.lineNumber = line;
    LSUtil.Debug(e);
  }
};

LSUtil.Data = {};
/**
 * @this {Element}
 */
LSUtil.Data.FormatMoney = function (amount) {
  if (!amount) {
    amount = "0.00";
  } else if (!isNaN(amount)) {
    amount = amount.toString();
  }
  var delimiter = ","; // replace comma if desired
  var a1 = amount.split(".", 2);
  var d = a1[1];
  var i = parseInt(a1[0], 10);
  if (isNaN(i)) {
    return currency_symbol;
  }
  var minus = "";
  if (i < 0) {
    minus = "-";
  }
  i = Math.abs(i);
  var n = String(i);
  var a = [];
  while (n.length > 3) {
    var nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (!d || d.length < 1) {
    amount = n + ".00";
  } else {
    d = d.substr(0, 2);
    amount = n + "." + d;
  }

  amount = minus + amount;
  return currency_symbol + amount;
};

/**
 * @this {Element}
 */
LSUtil.ArrayIntersect = function (ar1, ar2) {
  var result = [];
  var i, j;
  for (i = 0; i < ar1.length; i++) {
    for (j = 0; j < ar2.length; j++) {
      if (String(ar1[i]) == String(ar2[j])) {
        result.push(ar1[i]);
      }
    }
  }

  return result;
};

LSUtil.__FCKeditor_Callbacks = {};
LSUtil.__FCKeditor_Callbacks_Called = {};
/**
 * @this {Element}
 */
LSUtil.registerFCKeditorCallback = function (instanceId, func) {
  if (instanceId in LSUtil.__FCKeditor_Callbacks_Called) {
    // already ran
    func();
  } else {
    if (!(instanceId in LSUtil.__FCKeditor_Callbacks)) {
      LSUtil.__FCKeditor_Callbacks[instanceId] = [];
    }

    LSUtil.__FCKeditor_Callbacks[instanceId].push(func);
  }
};

window.FCKeditor_OnComplete = function (editorInstance) {
  var i;
  LSUtil.__FCKeditor_Callbacks_Called[editorInstance.Name] = true;

  var funcList = LSUtil.__FCKeditor_Callbacks[editorInstance.Name];
  if (funcList) {
    for (i = 0; i < funcList.length; i++) {
      funcList[i]();
    }
  }
};

// Some global functions, Prototype Style.
window.$FInt = function (inp, radix) {
  var val = LSUtil.GetValue(inp);

  //inp should be a scalar value, but if it's an array with one element, we'll use that.
  if (Array.isArray( val) && val.length === 1) {
    val = val[0];
  }
  if (val) {
    if (!radix) {
      radix = 10;
    }
    if (typeof val == "string") {
      val = val.replace(/[^0-9]/, "");
    }
    return parseInt(val, radix);
  }

  return val;
};

window.$FList = function (inp) {
  var val = LSUtil.GetValue(inp);
  if (val == LSUtil.__na_option_value) {
    val = null;
  }
  return val;
};

window.$FFloat = function (inp, from_value) {
  var val = from_value ? String(inp) : LSUtil.GetValue(inp);
  if (typeof val == "number") {
    val = val.toString();
  }
  if (val) {
    var negative = val.charAt(0) == "-";
    // eslint-disable-next-line no-useless-escape
    val = val.replace(/[^0-9\.]/g, "");
    if (negative) {
      return parseFloat("-" + val);
    }
    return parseFloat(val);
  }

  return val;
};

window.$FBool = function (inp) {
  var obj = $(inp);
  var val = null;
  if (obj.tagName == "DIV") {
    // we have a radio group. (yesno most likely)
    var i = 0;
    var radios = obj.select("input[type=radio]");
    for (i = 0; i < radios.length; i++) {
      if (radios[i].checked) {
        val = radios[i].value;
        break;
      }
    }
  } else {
    val = LSUtil.GetValue(inp);
  }
  var bool_result = window.LSbool(val);
  if (bool_result!==null) {
    return bool_result;
  }

  return undefined;
};

window.LSbool = function (val) {
  if (typeof val == "string") {
    val = val.toLowerCase();
  }

  if (val === false || val == "no" || val == "off" || val == "false" || val == "f" || val === 0 || val == "0") {
    return false;
  }

  if (val === true || val == "yes" || val == "on" || val == "true" || val == "t" || val) {
    return true;
  }

  return null;

};

/**
 * @this {Element}
 */
LSUtil.selectElement = function (selectBox, key) {
  var i;
  for (i = 0; i < selectBox.options.length; i++) {
    if (selectBox.options[i].value == key) {
      selectBox.selectedIndex = i;
      break;
    }
  }

  return i;
};

/**
 * @this {Element}
 */
//Function.prototype.subclass = function (data) {
//}

//
// Base class implementing classic inheritence in JS
// See LSBase.txt for details
//
 
function LSBase() {}
/**
 * @this {Element}
 */
LSBase.rebless = function (obj) {
  var newobj = new this();
  var i, args;
  Object.extend(obj, newobj);
  if (obj.init) {
    args = [];
    for (i = 1; i < arguments.length; i++) {
      args[i - 1] = arguments[i];
    }

     
    obj.init.apply(obj, args);
  }
};
/**
 * @this {Element}
 */
LSBase.create = function () {
  var obj = new this();
  //    obj.ajaxRequest = LSBase.ajaxRequest;

  if (obj.init) {
     
    obj.init.apply(obj, arguments);
  }

  return obj;
};

LSBase.prototype = {
  /**
   * @this {Element}
   */
   
  init: function () {},
  /**
   * @this {Element}
   */
  parentcall: function (parent_class, method) {
    var args = [];
    var i;
    for (i = 1; i < arguments.length; i++) {
      args[i - 2] = arguments[i];
    }

    if (typeof parent_class.prototype[method] != "function") {
      throw "Couldn't find parent method.";
    }
    return parent_class.prototype[method].apply(this, args);
  },

  /**
   * Find controls in this element's form by searching on their bound field's attributes.
   * You can pass in more than set of field attributes to search for and they will be "OR"ed together in the selector.
   *
   * Example:  To find all elements in the form that are either bound to the field event.event_type OR the field
   * todo.deadline_type, you can pass in this parameter:
   *
   *  [
   *    {
   *       module: 'event',
   *       column: 'event_type'
   *    },
   *    {
   *       module: 'todo',
   *       column: 'deadline_type'
   *    }
   *  ]
   *
   * @param Array attributes Collection of objects with module and/or column attributes
   *
   * @return Array Array of Element that match the attributes passed in.
   */
  find_elements_by_field_attributes: function (attributes) {
    var search_components = [];
    if (!Array.isArray(attributes)) {
      attributes = [attributes];
    }
    attributes.forEach(function (attr) {
      var item = "";
      if (attr.module) {
        item += "[data-field-module=" + attr.module + "]";
      }
      if (attr.column) {
        item += "[data-field-column=" + attr.column + "]";
      }
      search_components.push(item);
    });
    return jQuery(this).closest("form").find(search_components.join(",")).get();
  }
};

/**
 * @this {Element}
 */
LSBase.ajaxRequest_makeoptions = function (element, method, parameters, options) {
  var ancestors = Element.extend(element).ancestors();
  var form = null;
  var i;
  for (i = 0; i < ancestors.length; i++) {
    if (ancestors[i].match("form")) {
      form = ancestors[i];
      break;
    } else if (ancestors[i].match("div.form_placeholder")) {
      form = ancestors[i];
      break;
    }
  }

  if (!form) {
    throw "Couldn't find ancestor form";
  }
  if (!options) {
    options = {};
  }

  if (!parameters) {
    parameters = [];
  } else if (!(parameters instanceof Array)) {
    parameters = [parameters];
  }

  var form_name = form.attributes.getNamedItem("name").value;
  var hidden_els = form.getElementsBySelector("input[type=hidden][name=" + form_name + "]");
  var report_id_els = form.getElementsBySelector("input[type=hidden][name=__report_session_id]");
  var hidden_el = hidden_els[0];
  options.parameters = {
    __form_arguments: Object.toJSON(parameters),
    __form_method: method,
    __form_marker: form_name,
    __form_element_id: element.id,
    __batch: false
  };
  // this allows us to get pure value returns minus "OK\n" (for jQuery/LS4)
  if (options.no_ok) {
    options.parameters.no_ok = options.no_ok;
  }
  options.parameters[form_name] = LSUtil.GetValue(hidden_el);
  if (report_id_els.length > 0) {
    // this is used for dynamic reports in edit mode
    options.parameters.__report_session_id = LSUtil.GetValue(report_id_els[0]);
  }
  options.method = "post";

  if (!options.onFailure) {
    /**
     * @this {Element}
     */
    options.onFailure = function (transport) {
      LSUtil.Debug("AJAX ERROR: " + LSUtil.SimpleHTML2Txt(transport.responseText));
    };
  }

  if (options.onSuccess) {
    var oldhandler = options.onSuccess;
    /**
     * @this {Element}
     */
    options.onSuccess = function (transport) {
      var data;
      var body = transport.responseText;
      var ajax_success_header = transport.getResponseHeader("X-Ajax-Success");
      if (body.substring(0, 3) != "OK\n") {
        if (ajax_success_header === "OK") {
          data = body.evalJSON(true);
        } else {
          throw "Bad success response from AJAX";
        }
      } else {
        data = body.substring(3).evalJSON(true);
      }
      oldhandler(transport, data);
    };
  }

  return options;
};

/**
 * @this {Element}
 */
LSBase.ajaxRequest = function (method, parameters, options, batch) {
  if (batch == undefined) {
    batch = false;
  }
  var return_value;
  if (batch) {
    LSBase.addToBatch(this, method, parameters, options);
    return_value = null;
  } else {
    // at some point, try uncommenting this: options.no_ok = true;
    var options2 = LSBase.ajaxRequest_makeoptions(this, method, parameters, options);

    return_value = new Ajax.Request("/modules/form/ajax.php", options2);
  }
  return return_value;
};

/**
 * processors for a synchronous ajax queue
 *
 * needed for some pages where multiple form elements trigger on load js and interact
 */
var synchronousRequestQueue = [];
var runningSynchronousRequest = false;
LSBase.processSynchronousQueue = function () {
  if (synchronousRequestQueue.length == 0) {
    return;
  }
  if (runningSynchronousRequest) {
    return false;
  }
  var options2 = synchronousRequestQueue.shift();

  var oldOnComplete = options2.onComplete;
  options2.onComplete = function (response) {
    if (oldOnComplete !== undefined) {
      oldOnComplete(response);
    }
    runningSynchronousRequest = false;
    LSBase.processSynchronousQueue();
  };

  runningSynchronousRequest = true;
  var request = new Ajax.Request("/modules/form/ajax.php", options2);
  return request;
};

/**
 * @this {Element}
 */
LSBase.ajaxRequestSynchronous = function (method, parameters, options) {
  var options2 = LSBase.ajaxRequest_makeoptions(this, method, parameters, options);
  synchronousRequestQueue.push(options2);

  LSBase.processSynchronousQueue();
};

var LSBaseBatch = [];
var LSBaseBatchTimer = null;

/**
 * @this {Element}
 */
LSBase.addToBatch = function (element, method, parameters, options) {
  if (LSBaseBatchTimer) {
    clearTimeout(LSBaseBatchTimer);
  }
  // execute the batch 0.1s after the last call
  LSBaseBatchTimer = setTimeout(function () {
    LSBase.ajaxBatchTimeout();
  }, 100);
  LSBaseBatch.push({ element: element, method: method, parameters: parameters, options: options });
};
/**
 * @this {Element}
 */
LSBase.ajaxRequestBatch_makeoptions = function (batch) {
  var elements = batch.pluck("element");
  var methods = batch.pluck("method");
  var parameters = batch.pluck("parameters");
  var options = batch.pluck("options");
  if (!(parameters instanceof Array)) {
    throw "Elements must be an array of element";
  }
  var ancestors = $(elements[0]).ancestors();
  var form = null;
  var i;
  for (i = 0; i < ancestors.length; i++) {
    if (ancestors[i].match("form")) {
      form = ancestors[i];
      break;
    } else if (ancestors[i].match("div.form_placeholder")) {
      form = ancestors[i];
      break;
    }
  }

  if (!form) {
    throw "Couldn't find ancestor form";
  }
  var new_options = {};

  if (!(parameters instanceof Array)) {
    throw "Parameters must be an array of parameters";
  }

  if (!(methods instanceof Array)) {
    throw "Method must be an array";
  }

  var form_name = form.attributes.getNamedItem("name").value;
  var hidden_els = form.getElementsBySelector("input[type=hidden][name=" + form_name + "]");
  var report_id_els = form.getElementsBySelector("input[type=hidden][name=report_session_id]");
  var hidden_el = hidden_els[0];
  new_options.parameters = {
    __form_arguments: Object.toJSON(parameters),
    __form_method: Object.toJSON(methods),
    __form_marker: form_name,
    __form_element_id: Object.toJSON(elements.pluck("id")),
    __batch: true
  };
  new_options.parameters[form_name] = LSUtil.GetValue(hidden_el);
  if (report_id_els.length > 0) {
    new_options.parameters.report_session_id = LSUtil.GetValue(report_id_els[0]);
  }
  new_options.method = "post";

  /**
   * @this {Element}
   */
  new_options.onFailure = function (transport) {
    var j;
    for (j = 0; j < options.length; j++) {
      if (options[j].onFailure) {
        options[j].onFailure(transport, null);
      } else {
        LSUtil.Debug("AJAX ERROR: " + LSUtil.SimpleHTML2Txt(transport.responseText));
      }
    }
  };

  /**
   * @this {Element}
   */
  new_options.onSuccess = function (transport) {
    var body = transport.responseText;
    var j;
    if (body.substring(0, 3) != "OK\n") {
      throw "Bad success response from AJAX";
    }
    var data = body.substring(3).evalJSON(true);
    if (!(data instanceof Array)) {
      throw "Response data is not an array";
    }

    for (j = 0; j < options.length; j++) {
      if (!data[j]) {
        throw "Not enough responses for batch ajax";
      }
      if (options[j].onSuccess) {
        options[j].onSuccess(transport, data[j]);
      }
    }
  };

  return new_options;
};

/**
 * @this {Element}
 */
LSBase.ajaxBatchTimeout = function () {
  LSBaseBatchTimer = null;
  var options = LSBase.ajaxRequestBatch_makeoptions(LSBaseBatch);
  LSBaseBatch = [];
  return new Ajax.Request("/modules/form/ajax.php", options);
};
/**
 * @this {Element}
 */
LSBase.fireChange = function () {
  if (document.createEvent) {
    var event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, true, window);
    this.dispatchEvent(event);
  } else {
    this.fireEvent("onchange");
  }
};

/**
 * @this {Element}
 */
LSBase.subclass = function (data) {
  /**
   * @this {Element}
   */
   
  var f = function () {};

  // Pass along the essential static methods.
  f.subclass = LSBase.subclass;
  f.create = LSBase.create;
  f.rebless = LSBase.rebless;
  f.ajaxRequest = LSBase.ajaxRequest;
  f.ajaxRequestSynchronous = LSBase.ajaxRequestSynchronous;
  f.fireChange = LSBase.fireChange;

  // Create a prototype object that new elements of this
  // class will be based on, and add in all of the properties
  // the user passed in.
  f.prototype = new this();
  f.prototype.ajaxRequest = LSBase.ajaxRequest;
  f.prototype.ajaxRequestSynchronous = LSBase.ajaxRequestSynchronous;
  f.prototype.fireChange = LSBase.fireChange;
  var prop;
  if (data !== undefined) {
    for (prop in data) {
      f.prototype[prop] = data[prop];
    }
  }

  // Make sure that objects of our class know who their class is
  // IE breaks when we do this. so don't do it for IE.
  if (!Prototype.Browser.IE) {
    f.prototype.constructor = f;
  }
  f.prototype.parent = this;
  // Make sure the class knows who it's parent is.
  f.__parent_class = this;

  return f;
};

LSUtil.FCKeditor = {};
LSUtil.FCKeditor.Observer = Class.create(Abstract.TimedObserver, {
  /**
   * @this {Element}
   */
  getValue: function () {
    return LSUtil.FCKeditor.currentValue(this.element);
  }
});

/**
 * @this {Element}
 */
LSUtil.FCKeditor.currentValue = function (el) {
  var val;
  if (typeof CKEDITOR !== "undefined" && CKEDITOR.instances[el.id] != null) {
    var html = CKEDITOR.instances[el.id].getData();
    if (!html) {
      //  GetHTML will return null when not fully initialized
      html = "";
    }
    val = html;
  } else {
    val = LSUtil.GetValue(el);
  }
  return val;
};

window.nni_book = LSBase.subclass({
  /**
   * @this {Element}
   */
  init: function (selected_tab, tab_info, parent_url, selected_tab_lazy_load) {
    this.tab_info = $H(tab_info);
    this.cache_tabs = true;
    this.pageContainer = this.getElementsByClassName("bookPage")[0];
    if (selected_tab_lazy_load !== undefined) {
      this.selected_tab_lazy_load = selected_tab_lazy_load;
    } else {
      this.selected_tab_lazy_load = false;
    }
    var tabs = {};

    this.selected_tab = selected_tab;

    this.tab_info.each(
      function (item) {
        var func = this.handleClick.bindAsEventListener(this, item.key);
        tabs[item.key] = $(item.key);
        tabs[item.key].target_url = item.value;
        Event.observe($(item.key), "click", func);
      }.bind(this)
    );

    this.tabs = $H(tabs);
    this.pages = {};
    if (this.selected_tab_lazy_load) {
      this.showTab(selected_tab);
    } else {
      this.pages[selected_tab] = $(selected_tab + "_content");
    }
    this.parent_url = parent_url;
  },
  setCacheTabs: function (val) {
    this.cache_tabs = val;
  },
  /**
   * @this {Element}
   */
  handleClick: function (ev, tab_no) {
    if (tab_no == this.selected_tab) {
      return;
    }
    this.updateForcePage(tab_no);
    this.showTab(tab_no);
  },
  updateForcePage: function (tab_no) {
    var current_url = window.location.href;
    var new_url = current_url;
    if (new_url.match(/force_page=[a-zA-Z0-9\-_]*/)) {
      new_url = new_url.replace(/(\?|&)force_page=[a-zA-Z0-9\-_]*&*/, "$1");
    }
    if (new_url.match(/l_[a-f0-9]+_reset=1/)) {
      new_url = new_url.replace(/(\?|&)l_[a-f0-9]+_reset=1&*/, "$1");
    }
    // clean up any ending & that might be left by above
    if (new_url.match(/&^/)) {
      new_url = new_url.replace(/&^/, "");
    }
    // @todo: force_page for correct page?
    if (current_url !== new_url) {
      history.replaceState(null, null, new_url);
    }
  },
  showTab: function (tab_no) {
    if (!this.pages[tab_no]) {
      this.pages[tab_no] = new Element("div", { id: tab_no + "_content" });
      var style =
        "text-align: center; vertical-align: middle; " +
        "padding:15px; background-color:#ffffff;" +
        "border: 1px solid #B8B8B8;";
      this.pages[tab_no].innerHTML =
        '<div class="book" style="' +
        style +
        '">' +
        '<i class="fa fa-2x fa-refresh fa-spin"></i><br/><br />Loading ... </div>';
      this.pageContainer.select(".tabs")[0].appendChild(this.pages[tab_no]);

      this.loadTab(tab_no);
    }

    if (this.cache_tabs) {
      this.pages[this.selected_tab].hide();
    } else {
      this.pages[this.selected_tab].remove();
      this.pages[this.selected_tab] = undefined;
    }
    this.pages[tab_no].show();

    this.tabs.each(function (item) {
      if (item.key == tab_no) {
        item.value.addClassName("selected_book_tab"); // old templates
        item.value.writeAttribute("aria-selected", "true");
        item.value.select("a")[0].addClassName("select");
      } else {
        item.value.removeClassName("selected_book_tab");
        item.value.writeAttribute("aria-selected", "false");
        item.value.select("a")[0].removeClassName("select");
      }
    });

    this.selected_tab = tab_no;
    // dynamic_form_container_tabblock.saveSelectedTab(tab_no, this.block_id);
  },
  reloadTab: function (tab_id) {
    // below was broken and nobody noticed.  this function doesn't seem to be used.
    //if ($( tab_no+'_content')) {
    //$( tab_no+'_content').remove();
    //}
    this.loadTab(tab_id);
  },
  reloadCurrentTab: function () {
    this.loadTab(this.selected_tab);
  },
  /**
   * @this {Element}
   */
  loadTab: function (tab_id) {
    var url;
    this.fire("nni:tabclick");
    if (tab_id.indexOf("lazytab") != -1) {
      //  May have "extra state," so check for the '?'
      if (this.tabs.get(tab_id).target_url.indexOf("?") != -1) {
        url = this.tabs.get(tab_id).target_url + "&lazy_load_id=" + tab_id;
      } else {
        url = this.tabs.get(tab_id).target_url + "?lazy_load_id=" + tab_id;
      }
    } else if (tab_id.indexOf("subtab") != -1) {
      url = "/modules/template/ajaxtab.php?tab_path=" + this.tabs.get(tab_id).target_url;
      url += "&parent_path=" + this.parent_url + "&lazy_load_id=" + tab_id;
    } else {
      url = this.tabs.get(tab_id).target_url + "?_ajax_id=" + this.id + "&tab=1&" + "&page=" + tab_id;
    }
    if (window.location.search.indexOf("_RESET_LISTVIEW") !== -1) {
      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }
      url += "_RESET_LISTVIEW=1";
    }
     
    var aj_req = new Ajax.Request(url, {
      method: "get",
      /**
       * @this {Element}
       */
      onSuccess: function (transport) {
        var response = transport.responseJSON;
        if (transport.getHeader("X-Login-Form")) {
          this.pages[tab_id].update("Tab cannot be fetched while you are not logged in.  Please log in again.");
          return;
        }
        if (!response) {
          if (transport.responseText) {
            this.pages[tab_id].update("Error occurred while loading tab: " + transport.responseText);
          } else {
            this.pages[tab_id].update(
              "There has been a problem loading the page. Please wait for the new page to load. "
            );
          }
          throw transport.responseText;
        }
        if (response.stylesheets) {
          LSUtil.loadCSSIncludes(response.stylesheets);
        }
        if (response.js_includes) {
          LSUtil.loadJSIncludes(
            response.js_includes,
            function () {
              this.pages[tab_id].update(response.html);
              /* jshint -W061 */
               
              eval(response.inline_js);
              /* jshint +W061 */
            }.bind(this)
          );
        } else {
          this.pages[tab_id].update(response.html);
          if (response.inline_js) {
            /* jshint -W061 */
             
            eval(response.inline_js);
            /* jshint +W061 */
          }
        }
        this.fire("nni:change");
        try {
          recalcSizes();
        } catch (e) {
           
                }
      }.bind(this),
      onFailure: function (transport) {
        this.pages[tab_id].update("Error occurred while loading tab");
      }.bind(this)
    });
  }
});

/**
 * @this {Element}
 */
LSUtil.disableSubmit = function (form) {
  var elements = $(form).getElementsBySelector("input[type=submit]");
  var i;
  for (i = 0; i < elements.length; i++) {
    var html = new Element("input", {
      type: "submit",
      value: elements[i].value,
      disabled: true,
      class: elements[i].classNames().toArray().join(" ")
    });
    elements[i].hide();
    elements[i].insert({ after: html });
  }
};

/**
 * @this {Element}
 */
LSUtil.enableSubmit = function (form) {
  var elements = $(form).getElementsBySelector("input[type=submit]");
  var i;
  for (i = 0; i < elements.length; i++) {
    if (elements[i].disabled) {
      elements[i].hide();
    } else {
      elements[i].show();
    }
  }
};

LSUtil.convertDateString = function (ds, inc_time) {
  if (ds == null) {
    return "";
  }
  if (inc_time == undefined) {
    inc_time = true;
  }
  var d = new Date(ds);
  var ret = ds.getMonth() + 1 + "/" + ds.getDate() + "/" + ds.getFullYear();
  if (inc_time) {
    var hr = d.getHours();
    var mm = d.getMinutes();
    var ampm = "am";

    if (hr >= 12) {
      ampm = "pm";
      if (hr > 12) {
        hr -= 12;
      }
    }
    if (hr < 10) {
      hr = "0" + hr;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    ret += " " + hr + ":" + mm + " " + ampm;
  }
  return ret;
};

LSUtil.formatDateIso = function (date_object) {
  return (
    date_object.getUTCFullYear() +
    "-" +
    (date_object.getUTCMonth() + 1 < 10 ? "0" + (date_object.getUTCMonth() + 1) : date_object.getUTCMonth() + 1) +
    "-" +
    (date_object.getUTCDate() < 10 ? "0" + date_object.getUTCDate() : date_object.getUTCDate())
  );
};

/**
 * Get "today's" date, accounting for the local timezone.
 */
LSUtil.today_iso = function () {
  var yourDate = new Date();
  var offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  var result = yourDate.toISOString().split("T")[0];
  return result;
};

/**
 * convert 03/01/2022 or 01/03/2022, depending on browser locale, to 2022-01-03
 */
LSUtil.format_local_date_to_iso = function (in_text) {
  if (in_text.indexOf("/") !== -1) {
    var new_val = "";
    var split_date = in_text.split("/");
    // march is the number 2 with getUTCMonth()
    if (new Date("03/01/2022").getUTCMonth() === 2) {
      new_val = split_date[2] + "-" + split_date[0] + "-" + split_date[1];
    } else {
      new_val = split_date[2] + "-" + split_date[1] + "-" + split_date[0];
    }
    return new_val;
  } else {
    return in_text;
  }
};

LSUtil.format_date_static = function (text, ignore_day) {
  // eslint-disable-next-line no-useless-escape
  var regex = /^(\d{1,2})[\-\/](\d{1,2})[\-\/](\d{1,4})$/;
  if (ignore_day) {
    // eslint-disable-next-line no-useless-escape
    regex = /^(\d{1,2})[\-\/](\d{1,4})$/;
  }

  var fields = text.match(regex);
  if (!fields) {
    //  allow for 8 digits
    if (ignore_day) {
      if (text.length == 6 && text.match(/^\d{6}$/)) {
        fields = [];
        fields[1] = text.substring(0, 2);
        fields[2] = text.substring(2, 6);
      }
    } else if (text.length == 8 && text.match(/^\d{8}$/)) {
      fields = [];
      fields[1] = text.substring(0, 2);
      fields[2] = text.substring(2, 4);
      fields[3] = text.substring(4, 8);
    } else {
      return false;
    }
  }

  fields[1] = parseInt(fields[1].replace(/^0+/, ""), 10);
  fields[2] = parseInt(fields[2].replace(/^0+/, ""), 10);
  if (!ignore_day) {
    fields[3] = parseInt(fields[3].replace(/^0+/, ""), 10);
  }

  var year = ignore_day ? fields[2] : fields[3];
  var month = fields[1];
  var day = ignore_day ? 1 : fields[2];

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  if (month > 12 || month < 1) {
    return false;
  }

  if (day > 31 || day < 1) {
    return false;
  }

  // Format month and day to two digits.
  if (month < 10) {
    month = "0" + month;
  }

  if (day < 10) {
    day = "0" + day;
  }

  if (year < 100) {
    var curDate = new Date();
    var curYear = parseInt(curDate.getFullYear().toString().slice(2), 10);
    if (year > curYear + 4) {
      year += 1900;
    } else {
      year += 2000;
    }
  } else if (year < 1000) {
    return false;
  }

  // check if the final calculated date is actually valid
  // Date() converts invalid dates into valid ones, so if day, month, or year is changed we know we are invalid
  var datecmp = [parseInt(year, 10), parseInt(month, 10), parseInt(day, 10)];
  var myDate = new Date(datecmp[0], datecmp[1] - 1, datecmp[2]);
  if (myDate.getFullYear() != datecmp[0] || myDate.getMonth() + 1 != datecmp[1] || myDate.getDate() != datecmp[2]) {
    return false;
  }

  return month + "/" + (this.ignore_day ? "" : day + "/") + year;
};


export {
  LSUtil as LSUtil,
  LSBase as LSBase
};
