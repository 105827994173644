<template>
  <div class="api-parameter-row">
    <div class="inputs">
      <div class="api-parameter-type">
        <select v-model="type">
          <option value="">
            Choose type...
          </option>
          <option value="static">
            Static parameter
          </option>
          <option value="dynamic">
            Value from a field
          </option>
          <option value="structure">
            Data Structure
          </option>
        </select>
      </div>
      <div
        v-if="type !== 'structure'"
        class="api-parameter-key"
      >
        <input
          v-model="key"
          type="text"
          placeholder="Parameter key"
          @change="change"
        >
      </div>
      <div
        v-if="type === 'static'"
        class="api-parameter-static-value"
      >
        <input
          v-model="staticValue"
          type="text"
          placeholder="Static value"
          @change="change"
        >
      </div>
      <div
        v-if="type === 'dynamic'"
        class="api-parameter-dynamic-source"
      >
        <div>
          <Multiselect
            v-model="selectedSourceField"
            :options="fieldList"
            :placeholder="'Choose field...'"
            group-label="short_name"
            group-values="children"
            track-by="lookup_name"
            label="short_name"
            @select="changeField"
            @remove="changeField"
          />
        </div>
      </div>
      <div
        v-if="type === 'dynamic'"
        class="api-parameter-ignore-null"
      >
        <label><input
          v-model="ignoreNull"
          type="checkbox"
          :value="true"
          @change="change"
        ><span>Ignore null values</span>
        </label>
      </div>
      <div
        v-if="type === 'dynamic'"
        class="api-parameter-path"
      >
        <label><input
          v-model="isPathParam"
          type="checkbox"
          :value="true"
          @change="change"
        ><span>Path parameter</span></label><span
          v-if="lookupAvailable"
          class="api_send_lookup_value"
        ><label><input
          v-model="sendLookupValue"
          type="checkbox"
          :value="true"
          @change="change"
        ><span>Send lookup value</span></label>
        </span>
      </div>
      <div
        v-if="type === 'structure'"
        class="api-parameter-structure-source"
      >
        <parameters-config-item
          :root="true"
          :config_item="dataStructureTypeConfig"
          :field_choices="appState.fieldChoices"
          :parent-is-array="false"
          @update_item="structure_change"
          @remove="structure_change"
        />
      </div>
    </div>
    <div class="api-parameter-remove">
      <button
        class="btn_bare btn_danger"
        type="button"
        @click="$emit('remove')"
      >
        <i class="fa fa-times" />
      </button>
    </div>
  </div>
</template>
<script lang="ts">
 
import Multiselect from "vue-multiselect";
import { v4 as uuidv4 } from "uuid";
import ParametersConfigItem from "./parameters_config_item.vue";
import { findObjectInGroupedData } from "../../util.js";

export default {
  components: {
    "parameters-config-item": ParametersConfigItem,
    Multiselect
  },
  props: {
    // The value of the parameter
    value: {
      type: Object,
      required: true
    },
    // The app state
    appState: {
      type: Object,
      required: true
    }
  },
  emits: ["change", "remove"],
  data: function() {
    const name = this.value.dataStructureTypeConfig ? this.value.dataStructureTypeConfig.name : "";
    const items = this.value.dataStructureTypeConfig ? this.value.dataStructureTypeConfig.items : [];
    const is_array = this.value.dataStructureTypeConfig ? this.value.dataStructureTypeConfig.is_array : false;
    const dataStructureTypeConfig = {
      field_choice: "structure",
      is_path_param: false,
      is_array: is_array,
      parentIsArray: false, // Ensure the root is not considered as array
      items,
      literal: "",
      name,
      uuid: uuidv4(),
      variable: "",
      greedy_update: true
    };
    return {
      id: this.value.id,
      type: this.value.type,
      key: this.value.key,
      isPathParam: this.value.isPathParam,
      ignoreNull: this.value.ignoreNull,
      staticValue: this.value.staticValue,
      sourceField: this.value.sourceField,
      sendLookupValue: this.value.sendLookupValue,
      dataStructureTypeConfig,
      selectedSourceField: { short_name: 'Loading', children: [{lookup_name: null, short_name: null}] }
    };
  },
  computed: {
    lookupAvailable: function() {
      const lookupField = this.appState.matterFieldList.find(item => item.lookup_name === this.sourceField);
      return lookupField ? lookupField.lookup_available : false;
    },
    fieldList: function() {
      const ungrouped = [].concat(this.appState.matterFieldList.filter((item) => !(item.grouping) || item.grouping === ""));

      ungrouped.push({
        lookup_name: "matter:housenumber",
        short_name: "House number"
      });
      ungrouped.push({
        lookup_name: "matter:streetname",
        short_name: "Street name"
      });
      const retval = [{
        short_name: "Ungrouped",
        children: ungrouped
      }];
      const groupedFields = {};
      this.appState.matterFieldList.forEach((item) => {
        if (item.grouping && item.grouping !== "") {
          if (!(item.grouping in groupedFields)) {
            groupedFields[item.grouping] = [];
          }
          groupedFields[item.grouping].push(item);
        }
      });
      Object.keys(groupedFields).forEach((k) => {
        const groupItem = {
          short_name: k,
          lookup_name: k,
          children: groupedFields[k].map((child) => {
            return { lookup_name: child.lookup_name, short_name: child.short_name };
          })
        };
        retval.push(groupItem);
      });

      return retval;
    }
  },
  mounted() {
    this.selectedSourceField = findObjectInGroupedData(this.fieldList, this.value.sourceField, "lookup_name") || {
      lookup_name: null,
      short_name: null
    };
  },
  methods: {
    changeField() {
      this.sourceField = this.selectedSourceField.lookup_name;
      this.change();
    },
    change: function() {
      this.$emit("change", this.$data);
    },
    structure_change: function(value) {
      this.dataStructureTypeConfig = value;
      this.change();
    }
  }
};
</script>
<style lang="scss" scoped>
.api-parameter-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;

  .inputs {
    display: flex;
    width: calc(100% - 32px);

    .api-parameter-type {
      padding-right: 6px;
      width: 20%;

      select {
        width: 100%;
      }
    }

    .api-parameter-key {
      padding-right: 6px;
      width: 30%;

      input {
        width: 100%;
      }
    }

    .api-parameter-static-value {
      padding-right: 6px;
      width: 30%;

      input {
        width: 100%;
      }
    }

    .api-parameter-dynamic-source {
      padding-right: 6px;
      width: 30%;

      select {
        width: 100%;
      }
    }

    .api-parameter-path {
      display: flex;
      align-items: center;
    }

    .api-parameter-is-array {
      display: flex;
      align-items: center;
    }

    .api_send_lookup_value {
      padding-left: 15px;
    }
  }
}
</style>
