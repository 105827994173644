<template>
  <div class="parameter-root">
    <div class="item">
      <div>
        <div>
          <select
            ref="field_selectors"
            v-model.lazy="config_item_local.field_choice"
          >
            <option value="">
              Please select...
            </option><option
              v-for="opt in field_choices"
              :key="opt.key"
              :value="opt.key"
            >
              {{ opt.label }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="!config_item_local.parentIsArray">
        <input
          v-model.lazy="config_item_local.name"
          type="text"
          placeholder="API param name"
        >
      </div>
      <div>
        <template v-if="config_item_local.field_choice === 'structure'" />
        <input
          v-else-if="config_item_local.field_choice === 'literal'"
          v-model.lazy="config_item_local.literal"
          type="text"
          placeholder="Value to send"
        >
        <input
          v-else
          v-model.lazy="config_item_local.variable"
          type="text"
          placeholder="Variable ###VALUE### placeholder including optional literal value to send"
        >
      </div>
      <div>
        <button
          type="button"
          @click="$emit('remove', config_item_local.uuid)"
        >
          <i class="fa fa-times-circle" />
        </button>
      </div>
    </div>
    <div
      v-if="config_item_local.field_choice === 'structure'"
      class="api_form_element_parameters_config_item_locals child_structure"
    >
      <div
        v-if="config_item_local.items.length === 0"
        class="no_parameters"
      >
        No parameters set
      </div>
      <parametersConfigItem
        v-for="child_config_item in config_item_local.items"
        :key="child_config_item.uuid"
        :config_item="child_config_item"
        :root="false"
        :field_choices="field_choices"
        :parent-is-array="config_item_local.is_array"
        @remove="remove_child($event)"
        @update_item="update_child_item($event, child_config_item.uuid)"
      />
      <div class="footer">
        <button
          type="button"
          @click="add_item"
        >
          Add child
        </button>
      </div>
    </div>
    <div class="path-parameter">
      <label>
        <input
          v-model="config_item_local.is_path_param"
          type="checkbox"
          :value="true"
        >
        Path parameter
      </label>
    </div>
    <div
      v-if="config_item_local.field_choice === 'structure'"
      class="api-parameter-is-array"
    >
      <label>
        <input
          v-model="config_item_local.is_array"
          type="checkbox"
          :value="true"
        >
        Send Object as Array
      </label>
    </div>
  </div>
</template>

<script lang="ts">
 
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ParametersConfigItem',
  components: {  },
  props: {
     
    field_choices: {
      type: Array,
      required: true
    },
     
    config_item: {
      type: Object,
      required: true
    },
    root: {
      type: Boolean,
      required: true
    },
    parentIsArray: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['remove', 'update_item'],
  data: function() {
    return {
      config_item_local: {
        uuid: '',
        field_choice: '',
        name: '',
        literal: '',
        variable: '',
        is_path_param: false,
        is_array: this.config_item.is_array,
        ignore_null: false,
        items: [],
        greedy_update: this.config_item.greedy_update === true,
        parentIsArray: this.parentIsArray === true
      }
    };
  },
  watch: {
    config_item_local: {
      handler: function (after, before) {
        // eslint-disable-next-line eqeqeq
        if (this.config_item_local.greedy_update || JSON.stringify(after) != JSON.stringify(before)) {
          this.$emit('update_item', after);
        }
      },
      deep: true
    },
    parentIsArray: function (newVal) {
      this.config_item_local.parentIsArray = newVal;
    }
  },
  mounted: function() {
    this.$watch('this.config_item', function() {
      const copy = JSON.parse(JSON.stringify(this.config_item));
      const copy2 = Object.assign({}, { items: [] }, copy);
      this.config_item_local = copy2;
      //not clear if needed
      //this.config_item_local.parentIsArray = this.parentIsArray;
      // jQuery(this.$refs.field_selectors).chosen().val('addr1');
      //jQuery(this.$refs.field_selectors).chosen().val(this.config_item_local.field_choice);
    }, {
      deep: true
    });
    const copy = JSON.parse(JSON.stringify(this.config_item));
    const copy2 = Object.assign({}, { items: [] }, copy);
    this.config_item_local = copy2;
    this.config_item_local.parentIsArray = this.parentIsArray;
    this.attach_chosen();
    jQuery(this.$refs.field_selectors).chosen().val(this.config_item_local.field_choice).trigger('chosen:updated');
  },
  methods: {
    add_item: function() {
       
      const items_copy = this.config_item_local.items;
      items_copy.push({
        uuid: uuidv4(),
        field_choice: '',
        name: '',
        literal: '',
        variable: '',
        parentIsArray: this.config_item_local.is_array, // Here, if it's part of an array, set the flag
        items: []
      });
       
      this.config_item_local.items = items_copy;
    },
    remove_child: function(uuid) {
      this.config_item_local.items = this.config_item_local.items.filter(function(item) {
        return item.uuid !== uuid;
      });
    },
    attach_chosen: function() {
      jQuery(this.$refs.field_selectors).chosen().trigger('chosen:updated').on('change', this.make_select_change_handler());
    },
    make_select_change_handler: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      return function(ev) {
        that.config_item_local.field_choice = jQuery(ev.target).val();
      };
    },
    update_child_item: function(newVal, uuid) {
      this.config_item_local.items = this.config_item_local.items.map(
        function(item) {
          if (item.uuid === uuid) {
            return newVal;
          } else {
            return item;
          }
        }
      );

      this.$emit('update_item', this.config_item_local);
    }
  }
};
</script>

<style scoped>
.parameter-root:hover {
    background: #e9e9e9;
}

.child_structure {
    margin-left: 2em;
    padding-left: 1em;
    border-left: #ccc 2px solid;

}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.item > div {
    flex: 1;
    padding: 3px;
}
.item > div:first-child {
    flex: 1.8;
}
.item > div:last-child {
    flex: 0.2;
    display: flex;
    justify-content: center;
}
.item > div:last-child  button i {
    color: #dc3545;
}
.item >>> .chosen-container {
    /*
    These normally start off in a display:none container, so forcing this with important because the width gets set to
    0px by the javascript library if it isn't visible at init time.
    */
    width: 100% !important;
}
.item button {
    border: none;
    background: transparent;
    padding: 0;
}
.item input[type=text] {
    width: 100%;
}
 button {
    margin-left: inherit;
}
.path-parameter {
  padding: 6px;
}
.api-parameter-is-array {
  padding: 6px;
}

</style>
