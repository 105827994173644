/* jshint esversion: 6 */
/* globals  LSUtil */

const operatorLabels = {
  equal: "=",
  "not equal": "<>",
  less: "<",
   
  less_or_equal: "<=",
  greater: ">",
   
  greater_or_equal: ">=",
   
  not_between: "not between",
   
  is_not_null: "is not null",
   
  is_null: "is null",
   
  not_in: "not in"
};

const buildLogicText = (ruleItem, fieldList) => {
  var logic = ruleItem.not ? "NOT (" : "";
  if (ruleItem.rules) {
    if (ruleItem.rules.length > 1 && logic === "") {
      logic = "(";
    }
    ruleItem.rules.forEach((r, rIdx) => {
      if (rIdx > 0) {
        logic += "&nbsp;" + ruleItem.condition + "&nbsp;";
      }
      if (r.rules && r.rules.length > 0) {
        logic += buildLogicText(r, fieldList);
      } else {
        var fieldObj = fieldList.find((item) => item.field_name === r.field);
        logic += '&nbsp;<span class="field">' + fieldObj.short_name + '</span>&nbsp;<span class="operator">';
        logic += r.operator in operatorLabels ? operatorLabels[r.operator] : r.operator;
        logic += '</span>&nbsp;<span class="value">';
        var val;
        if (r.operator === "in" || r.operator === "not_in") {
          var itemLabels = r.value;

          if (typeof itemLabels === "string") {
            if (itemLabels.includes("|")) {
              itemLabels = itemLabels.split("|");
            } else {
              itemLabels = itemLabels.split(",").map(function(item) {
                return item.trim();
              });
            }
          }

          if (fieldObj.option_items && Array.isArray(fieldObj.option_items)) {
            itemLabels = r.value.map((toMap) => {
               
              var item = fieldObj.option_items.find((opt) => opt.id == toMap);
              if (item) {
                return item.name;
              } else {
                 
                if (fieldObj.type==='text') { return toMap; }
                return toMap + " [missing]";
              }
            });
          }
          val = "(" + itemLabels.join(", ") + ")";
        } else if (r.operator === "between" || r.operator === "not_between") {
          val = r.value[0] + " and " + r.value[1];
        } else if (r.value === null) {
          val = "";
        } else if (fieldObj.type === "bool") {
          var toTest = r.value;
          if (Array.isArray(toTest)) {
            toTest = toTest[0];
          }
          val = toTest === "t" ? "Yes" : "No";
        } else {
          val = r.value;
        }
        logic += LSUtil.escapeHtml(val) + "</span>";
      }
    });
    if (logic.substring(0, 1) === "(") {
      logic += "&nbsp;)";
    }
  } else {
    logic = '<div style="color:red">Invalid logical expression</div>';
  }
  return logic;
};

const validateLogic = (rules) => {
  const validateRules = (r) => {
    let retval = true;
    if (r.rules && r.rules.length > 0) {
      r.rules.forEach((rChild) => {
        retval = retval && validateRules(rChild);
      });
    } else {
      if (r.operator === "in" || r.operator === "not_in") {
        retval = Array.isArray(r.value) && r.value.length > 0;
      }
    }
    return retval;
  };

  let rulesAreValid = true;
  rules.forEach((r) => {
    if (rulesAreValid) {
      rulesAreValid = rulesAreValid && validateRules(r);
    }
  });
  return rulesAreValid;
};

const findMissingOptions = (rules, fieldList) => {
  const retval = new Set();
  rules.forEach((r) => {
    if (r.rules && r.rules.length > 0) {
      for (const item of findMissingOptions(r.rules, fieldList)) {
        retval.add(item);
      }
    } else {
      if (r.operator === "in" || r.operator === "not_in") {
        const fieldObj = fieldList.find((item) => item.field_name === r.field);
        if (fieldObj && !Array.isArray(fieldObj.option_items)) {
          retval.add(fieldObj.field_name);
        }
      }
    }
  });
  return retval;
};

const findObjectInGroupedData = (data, id, key = 'id') => {
  // Flatten the structure to include both parents and their children in a single array
  const flattened = data.flatMap(item =>
    item.children ? [item, ...item.children] : [item]
  );

  // Use find to locate the item by ID
  return flattened.find(item => item[key] === id);
};

export default {
  buildLogicText,
  validateLogic,
  findMissingOptions
};

export {
  findObjectInGroupedData
};
