<template>
  <div>
    <div class="form-row top-align">
      <div class="component">
        <label>Dialogue:</label>
        <Multiselect
          v-model="selectedDestinationDialogue"
          :options="dialoguesForDestination"
          track-by="'id'"
          label="name"
          @select="destinationChange"
          @remove="destinationChange"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Multiselect from 'vue-multiselect';
export default {
  components: { Multiselect },
  props: {
    appState: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  data: function() {
    return {
      destinationDialogueId : null,
      selectedDestinationDialogue: null
    };
  },
  computed: {
    dialoguesForDestination: function () {
      const retval = this.appState.allDialogues.map(item => item);
      retval.forEach((item) => {
        item.orderByName = item.name.toLowerCase();
      });
       
      retval.sort((a, b) => a.orderByName > b.orderByName ? 1 : (a.orderByName < b.orderByName ? -1 : 0));
      retval.forEach((item) => {
        delete item.orderByName;
      });
      if (this.appState.mode === 'interactive') {
        retval.unshift({
          id: this.appState.dialogueId,
          name: 'This dialogue'
        });
      }
      return retval;
    }
  },
  methods: {
    destinationChange: function() {
      this.destinationDialogueId = this.selectedDestinationDialogue ? this.selectedDestinationDialogue.id : null;
      this.$emit('input', Object.assign({}, this.$data));
    }
  }
};
</script>
