<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label(title="Language to set") Language to set:
            span.form-required *
        div(:class="{ 'form-error-border': errors['field'] }")
            Multiselect(:options="appState.languageOptions",
                :placeholder="'Choose language...'",
                track-by="id",
                label="name",
                v-model="selectedLanguage",
                @select="changeLanguage",
                @remove="changeLanguage")
    div.form-row(v-show="!expanded && collapseSummary !== ''")
        div(class="fieldSetValueCollapseSummary" v-html="collapseSummary")
</template>

<script lang="ts">
import Multiselect from "vue-multiselect";
import InternalsBase from './internals_base.vue';
export default {
  components: { Multiselect },
  extends: InternalsBase,
  data: function() {
    return {
      selectedLanguage: this.appState.languageOptions.find((l) => l.id === this.details.language) || null
    };
  },
  detailsTemplate: {
    language: null
  },
  computed: {
    collapseSummary() {
      let retval = 'Not configured';
      if (this.details.language && this.details.language.length > 0) {
        const languageId = parseInt(this.details.language);
        const selection = this.appState.languageOptions.find(opt => opt.id === languageId);
        if (selection) {
          retval = 'Set NLU intake language to ' + selection.name;
        }
      }
      return retval;
    }
  },
  methods: {
    changeLanguage() {
      this.details.language = this.selectedLanguage ? this.selectedLanguage.id : null;
      this.change();
    },
    detailsWatchHandler() {
      if (this.details.field !== this.previousDetails.field) {
        this.details.value = null;
      }
    }
  }
};
</script>
