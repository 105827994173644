<!-- eslint-disable max-len -->
<template>
  <!-- bold -->
  <svg
    v-if="pathname === 'bold'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z" /></svg>

  <!-- italic -->
  <svg
    v-else-if="pathname === 'italic'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" /></svg>

  <!-- underline -->
  <svg
    v-else-if="pathname === 'underline'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z" /></svg>

  <!-- strike -->
  <svg
    v-else-if="pathname === 'strike'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z" /></svg>

  <!-- undo -->
  <svg
    v-else-if="pathname === 'undo'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z" /></svg>

  <!-- redo -->
  <svg
    v-else-if="pathname === 'redo'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M18.172 7H11a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h7.172l-2.536-2.536L17.05 1.05 22 6l-4.95 4.95-1.414-1.414L18.172 7z" /></svg>

  <!-- heading_1 -->
  <svg
    v-else-if="pathname === 'heading_1'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0H24V24H0z"
  /><path d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z" /></svg>

  <!-- heading_2 -->
  <svg
    v-else-if="pathname === 'heading_2'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0H24V24H0z"
  /><path d="M4 4v7h7V4h2v16h-2v-7H4v7H2V4h2zm14.5 4c2.071 0 3.75 1.679 3.75 3.75 0 .857-.288 1.648-.772 2.28l-.148.18L18.034 18H22v2h-7v-1.556l4.82-5.546c.268-.307.43-.709.43-1.148 0-.966-.784-1.75-1.75-1.75-.918 0-1.671.707-1.744 1.606l-.006.144h-2C14.75 9.679 16.429 8 18.5 8z" /></svg>

  <!-- paragraph -->
  <svg
    v-else-if="pathname === 'paragraph'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z" /></svg>

  <!-- bullet_list -->
  <svg
    v-else-if="pathname === 'bullet_list'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" /></svg>

  <!-- ordered_list -->
  <svg
    v-else-if="pathname === 'ordered_list'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" /></svg>

  <!-- blockquote -->
  <svg
    v-else-if="pathname === 'blockquote'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" /></svg>

  <!-- hard_break -->
  <svg
    v-else-if="pathname === 'hard_break'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M15 18h1.5a2.5 2.5 0 1 0 0-5H3v-2h13.5a4.5 4.5 0 1 1 0 9H15v2l-4-3 4-3v2zM3 4h18v2H3V4zm6 14v2H3v-2h6z" /></svg>

  <!-- clear_format -->
  <svg
    v-else-if="pathname === 'clear_format'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill="none"
    d="M0 0h24v24H0z"
  /><path d="M12.651 14.065L11.605 20H9.574l1.35-7.661-7.41-7.41L4.93 3.515 20.485 19.07l-1.414 1.414-6.42-6.42zm-.878-6.535l.27-1.53h-1.8l-2-2H20v2h-5.927L13.5 9.257 11.773 7.53z" /></svg>

  <!-- link -->
  <svg
    v-else-if="pathname === 'link'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M18.3643 15.5353L16.95 14.1211L18.3643 12.7069C20.3169 10.7543 20.3169 7.58847 18.3643 5.63585C16.4116 3.68323 13.2458 3.68323 11.2932 5.63585L9.87898 7.05007L8.46477 5.63585L9.87898 4.22164C12.6127 1.48797 17.0448 1.48797 19.7785 4.22164C22.5121 6.95531 22.5121 11.3875 19.7785 14.1211L18.3643 15.5353ZM15.5358 18.3638L14.1216 19.778C11.388 22.5117 6.9558 22.5117 4.22213 19.778C1.48846 17.0443 1.48846 12.6122 4.22213 9.87849L5.63634 8.46428L7.05055 9.87849L5.63634 11.2927C3.68372 13.2453 3.68372 16.4112 5.63634 18.3638C7.58896 20.3164 10.7548 20.3164 12.7074 18.3638L14.1216 16.9496L15.5358 18.3638ZM14.8287 7.75717L16.2429 9.17139L9.17187 16.2425L7.75766 14.8282L14.8287 7.75717Z"
    fill="currentColor"
  /></svg>

  <!-- remove_link -->
  <svg
    v-else-if="pathname === 'remove_link'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M17 17H22V19H19V22H17V17ZM7 7H2V5H5V2H7V7ZM18.364 15.5355L16.9497 14.1213L18.364 12.7071C20.3166 10.7545 20.3166 7.58866 18.364 5.63604C16.4113 3.68342 13.2455 3.68342 11.2929 5.63604L9.87868 7.05025L8.46447 5.63604L9.87868 4.22183C12.6123 1.48816 17.0445 1.48816 19.7782 4.22183C22.5118 6.9555 22.5118 11.3877 19.7782 14.1213L18.364 15.5355ZM15.5355 18.364L14.1213 19.7782C11.3877 22.5118 6.9555 22.5118 4.22183 19.7782C1.48816 17.0445 1.48816 12.6123 4.22183 9.87868L5.63604 8.46447L7.05025 9.87868L5.63604 11.2929C3.68342 13.2455 3.68342 16.4113 5.63604 18.364C7.58866 20.3166 10.7545 20.3166 12.7071 18.364L14.1213 16.9497L15.5355 18.364ZM14.8284 7.75736L16.2426 9.17157L9.17157 16.2426L7.75736 14.8284L14.8284 7.75736Z"
    fill="currentColor"
  /></svg>

  <!-- anchor -->
  <svg
    v-else-if="pathname === 'anchor'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M12.382 3C12.7607 3 13.107 3.214 13.2764 3.55279L14 5H20C20.5523 5 21 5.44772 21 6V17C21 17.5523 20.5523 18 20 18H13.618C13.2393 18 12.893 17.786 12.7236 17.4472L12 16H5V22H3V3H12.382ZM11.7639 5H5V14H13.2361L14.2361 16H19V7H12.7639L11.7639 5Z"
  /></svg>

  <!-- image -->
  <svg
    v-else-if="pathname === 'image'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"
    fill="currentColor"
  /></svg>

  <!-- magic_note -->
  <svg
    v-else-if="pathname === 'magic_note'"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke-width="1"
    stroke="currentColor"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    fill-rule="evenodd"
    d="M3.75 2A1.75 1.75 0 0 0 2 3.75v3.5C2 8.216 2.784 9 3.75 9h3.5A1.75 1.75 0 0 0 9 7.25v-3.5A1.75 1.75 0 0 0 7.25 2h-3.5ZM3.5 3.75a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25h-3.5a.25.25 0 0 1-.25-.25v-3.5ZM3.75 11A1.75 1.75 0 0 0 2 12.75v3.5c0 .966.784 1.75 1.75 1.75h3.5A1.75 1.75 0 0 0 9 16.25v-3.5A1.75 1.75 0 0 0 7.25 11h-3.5Zm-.25 1.75a.25.25 0 0 1 .25-.25h3.5a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25h-3.5a.25.25 0 0 1-.25-.25v-3.5Zm7.5-9c0-.966.784-1.75 1.75-1.75h3.5c.966 0 1.75.784 1.75 1.75v3.5A1.75 1.75 0 0 1 16.25 9h-3.5A1.75 1.75 0 0 1 11 7.25v-3.5Zm1.75-.25a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25v-3.5a.25.25 0 0 0-.25-.25h-3.5Zm-7.26 1a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1h.01a1 1 0 0 0 1-1V5.5a1 1 0 0 0-1-1h-.01Zm9 0a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1h.01a1 1 0 0 0 1-1V5.5a1 1 0 0 0-1-1h-.01Zm-9 9a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1h.01a1 1 0 0 0 1-1v-.01a1 1 0 0 0-1-1h-.01Zm9 0a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1h.01a1 1 0 0 0 1-1v-.01a1 1 0 0 0-1-1h-.01Zm-3.5-1.5a1 1 0 0 1 1-1H12a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1V12Zm6-1a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1H17a1 1 0 0 0 1-1V12a1 1 0 0 0-1-1h-.01Zm-1 6a1 1 0 0 1 1-1H17a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1V17Zm-4-1a1 1 0 0 0-1 1v.01a1 1 0 0 0 1 1H12a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1h-.01Z"
    clip-rule="evenodd"
  /></svg>

  <!-- microphone -->
  <svg
    v-else-if="pathname === 'microphone'"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke-width="1.5"
    stroke="currentColor"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
  /></svg>


  <!-- source -->
  <svg
    v-else-if="pathname === 'source'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M23 11.9998L15.9289 19.0708L14.5147 17.6566L20.1716 11.9998L14.5147 6.34292L15.9289 4.92871L23 11.9998ZM3.82843 11.9998L9.48528 17.6566L8.07107 19.0708L1 11.9998L8.07107 4.92871L9.48528 6.34292L3.82843 11.9998Z"
    fill="currentColor"
  /></svg>

  <!-- upload_cloud -->
  <svg
    v-else-if="pathname === 'upload_cloud'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"
    fill="currentColor"
  /></svg>
  <!-- table -->
  <svg
    v-else-if="pathname === 'table'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M4 8H20V5H4V8ZM14 19V10H10V19H14ZM16 19H20V10H16V19ZM8 19V10H4V19H8ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3Z" />
  </svg>
  <!-- insert_row_top -->
  <svg
    v-else-if="pathname === 'table_insert_row_top'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M20 13C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V14C3 13.4477 3.44772 13 4 13H20ZM19 15H5V19H19V15ZM12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6C7 3.23858 9.23858 1 12 1ZM13 3H11V4.999L9 5V7L11 6.999V9H13V6.999L15 7V5L13 4.999V3Z" />
  </svg>
  <!-- insert_row_bottom -->
  <svg
    v-else-if="pathname === 'table_insert_row_bottom'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M12 13C14.7614 13 17 15.2386 17 18C17 20.7614 14.7614 23 12 23C9.23858 23 7 20.7614 7 18C7 15.2386 9.23858 13 12 13ZM13 15H11V16.999L9 17V19L11 18.999V21H13V18.999L15 19V17L13 16.999V15ZM20 3C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V4C3 3.44772 3.44772 3 4 3H20ZM5 5V9H19V5H5Z" />
  </svg>
  <!-- remove row -->
  <svg
    v-else-if="pathname === 'table_remove_row'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M20 5C20.5523 5 21 5.44772 21 6V12C21 12.5523 20.5523 13 20 13C20.628 13.8355 21 14.8743 21 16C21 18.7614 18.7614 21 16 21C13.2386 21 11 18.7614 11 16C11 14.8743 11.372 13.8355 11.9998 12.9998L4 13C3.44772 13 3 12.5523 3 12V6C3 5.44772 3.44772 5 4 5H20ZM13 15V17H19V15H13ZM19 7H5V11H19V7Z" />
  </svg>
  <!-- insert column right -->
  <svg
    v-else-if="pathname === 'table_insert_column_right'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M10 3C10.5523 3 11 3.44772 11 4V20C11 20.5523 10.5523 21 10 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H10ZM9 5H5V19H9V5ZM18 7C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17C15.2386 17 13 14.7614 13 12C13 9.23858 15.2386 7 18 7ZM19 9H17V10.999L15 11V13L17 12.999V15H19V12.999L21 13V11L19 10.999V9Z" />
  </svg>
  <!-- insert column left -->
  <svg
    v-else-if="pathname === 'table_insert_column_left'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H14C13.4477 21 13 20.5523 13 20V4C13 3.44772 13.4477 3 14 3H20ZM19 5H15V19H19V5ZM6 7C8.76142 7 11 9.23858 11 12C11 14.7614 8.76142 17 6 17C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7ZM7 9H5V10.999L3 11V13L5 12.999V15H7V12.999L9 13V11L7 10.999V9Z" />
  </svg>
  <!-- delete column -->
  <svg
    v-else-if="pathname === 'table_delete_column'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
    fill="currentColor"
  >
    <path d="M12 3C12.5523 3 13 3.44772 13 4L12.9998 11.9998C13.8355 11.372 14.8743 11 16 11C18.7614 11 21 13.2386 21 16C21 18.7614 18.7614 21 16 21C14.9681 21 14.0092 20.6874 13.2129 20.1518L13 20C13 20.5523 12.5523 21 12 21H6C5.44772 21 5 20.5523 5 20V4C5 3.44772 5.44772 3 6 3H12ZM11 5H7V19H11V5ZM19 15H13V17H19V15Z" />
  </svg>
  <!-- highlight -->
  <svg
    v-else-if="pathname === 'highlight'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  >
    <path d="M10 10.4967L11.0385 6.86202C11.1902 6.33099 11.7437 6.0235 12.2747 6.17522C12.6069 6.27014 12.8666 6.52981 12.9615 6.86202L14 10.4967V11.9967H14.7192C15.1781 11.9967 15.5781 12.309 15.6894 12.7542L17.051 18.2008C18.8507 16.7339 20 14.4995 20 11.9967C20 7.57841 16.4183 3.99669 12 3.99669C7.58172 3.99669 4 7.57841 4 11.9967C4 14.4995 5.14932 16.7339 6.94897 18.2008L8.31063 12.7542C8.42193 12.309 8.82191 11.9967 9.28078 11.9967H10V10.4967ZM12 19.9967C12.2415 19.9967 12.4813 19.986 12.7189 19.9649C13.6187 19.8847 14.4756 19.6556 15.2649 19.3023L13.9384 13.9967H10.0616L8.73514 19.3023C9.52438 19.6556 10.3813 19.8847 11.2811 19.9648C11.5187 19.986 11.7585 19.9967 12 19.9967ZM12 21.9967C6.47715 21.9967 2 17.5195 2 11.9967C2 6.47384 6.47715 1.99669 12 1.99669C17.5228 1.99669 22 6.47384 22 11.9967C22 17.5195 17.5228 21.9967 12 21.9967Z" />
  </svg>
  <!-- triangle -->
  <svg
    v-else-if="pathname === 'triangle'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M 18,6 6,18 h 12 z"
    fill="currentColor"
  /></svg>

  <!-- save -->
  <svg
    v-else-if="pathname === 'save'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
    fill="rgb(122,135,151)"
  /></svg>

  <!-- cancel -->
  <svg
    v-else-if="pathname === 'cancel'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"
    fill="rgb(122,135,151)"
  /></svg>

  <!-- success_checkmark -->
  <svg
    v-else-if="pathname === 'success_checkmark'"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path
    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
    fill="#4CAF50"
  /></svg>

  <!-- warning (missing) -->
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewbox"
    :width="width"
    :height="height"
  ><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" /></svg>
</template>
<script lang="ts">
export default {
  props: {
    pathname: {
      type   : String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    viewbox: {
      type   : String,
      default: '0 0 24 24'
    }
  }
};
</script>