/* jshint esversion: 6 */

/* globals _paq */
/**
 * debug at https://vlpboston.test.legalserver.org/guided_navigation/dialogue/edit/34/segment/0559bda5-372a-444d-b6e9-b05c9ff0b48d
 *
 * used in modules/guided_navigation/templates/dialogue.php
 */
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { LSBase } from "../../../static/js/lsutil.es.js";
import jQuery from "jquery";
import 'vue-multiselect/dist/vue-multiselect.min.css';

import makeAppState from "./app_state.js";
import App from "./app.vue";

import SegmentList from "./segment_list.vue";
import SegmentEditor from "./navigation_segment_editor.vue";
import Visualizer from "./visualizer.vue";

window.guided_navigation_element_dialogue_editor = LSBase.subclass({
  init: function (config) {
    this.parentcall(LSBase, "init");

    config.$outerForm = jQuery(this).closest("form");
    config.$configHidden = config.$outerForm.find("input[name=config]");

    config.appState = makeAppState(this);
    let initialConfig = {
      dialogue: {
        mode: "interactive"
      }
    };
    if (config.$configHidden.val() && config.$configHidden.val().length > 0) {
      initialConfig = JSON.parse(config.$configHidden.val());
      config.appState.dialogueId = initialConfig.dialogue.id;
      config.appState.dialogueName = initialConfig.dialogueName;
      if (config.appState.dialogueId === null) {
        config.appState.dialogueId = -1;
      }
    }

    config.appState.mode = initialConfig.dialogue.mode ? initialConfig.dialogue.mode : "interactive";

    if (initialConfig.dialogue.segments) {
      config.appState.segments = initialConfig.dialogue.segments;
      config.appState.segments.forEach((segment) => {
        if (typeof segment.defaultDestination === "string") {
          segment.defaultDestination = {
            dialogue: config.appState.dialogueId,
            segment: segment.defaultDestination
          };
        }
        if (segment.expressions) {
          segment.expressions.forEach((expr) => {
            if (typeof expr.destination === "string") {
              expr.destination = {
                dialogue: config.appState.dialogueId,
                segment: expr.destination
              };
            }
          });
        }
      });
    }
    config.entryPoint = initialConfig.dialogue.entryPoint;

    if (initialConfig.blocks) {
      const blockGroups = {};
      initialConfig.blocks.forEach((block) => {
        if (!(block.group in blockGroups)) {
          blockGroups[block.group] = {
            text: block.group,
            children: []
          };
        }
        blockGroups[block.group].children.push({
          id: block.class_name,
          text: block.label
        });
      });
      config.appState.blocks = Object.values(blockGroups);

      config.appState.blocks.sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));
    }

    if (initialConfig.defaultableBlocks) {
      config.appState.defaultableBlocks = initialConfig.defaultableBlocks;
    }

    if (initialConfig.noteTypes) {
      config.appState.noteTypes = initialConfig.noteTypes;
    }

    if (initialConfig.serviceTypes) {
      config.appState.serviceTypes = initialConfig.serviceTypes;
    }

    if (initialConfig.listviews) {
      const listviewGroups = {};
      initialConfig.listviews.forEach((listview) => {
        if (!(listview.module in listviewGroups)) {
          listviewGroups[listview.module] = {
            text: listview.module,
            children: []
          };
        }
        listviewGroups[listview.module].children.push({
          id: listview.id,
          text: listview.text
        });
      });
      config.appState.listviews = Object.values(listviewGroups);

      config.appState.listviews.sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));
    }

    if (initialConfig.documentTemplates) {
      config.appState.documentTemplates = initialConfig.documentTemplates;
    }

    if (initialConfig.fieldChoices) {
      config.appState.fieldChoices = initialConfig.fieldChoices;
    }

    config.appState.allDialogues = initialConfig.allDialogues;
    config.appState.enableFieldset = initialConfig.enableFieldset;
    config.appState.enableLegalIssueSegment = initialConfig.enableLegalIssueSegment;
    config.appState.enableSearch = initialConfig.enableSearch;
    config.appState.enableMultilingual = initialConfig.enableMultilingual;
    config.appState.languageOptions = initialConfig.languageOptions;
    config.appState.systemLanguages = initialConfig.systemLanguages;
    config.appState.enableNLU = initialConfig.enableNLU;
    config.appState.enableEnhancedInstructions = initialConfig.enableEnhancedInstructions;
    config.appState.enableNewIntakeDestinations = initialConfig.enableNewIntakeDestinations;
    config.appState.rte_engine = 'TIPTAP'; // ckeditor is no longer Vue 3 compatible

    let basePath = "/guided_navigation/dialogue/edit/";
    if (initialConfig.dialogue && initialConfig.dialogue.id) {
      basePath += initialConfig.dialogue.id.toString();
    }
    const routes = [
      { name: "root", path: "/", component: SegmentList },
      { name: "segment-new", path: "/segment/create", component: SegmentEditor },
      { name: "segment-edit", path: "/segment/:id", component: SegmentEditor },
      { name: "segment-edit-element", path: "/segment/:id/elements/:element", component: SegmentEditor },
      { name: "visualize", path: "/visualize", component: Visualizer }
    ];
    const router = createRouter({
      history: createWebHistory(basePath),
      routes
    });

    createApp(App,
      { config: config })
      .use(router)
      .mount(jQuery(this).find(".app_container")[0]);
  }
});


window.simplejustice_legal_issue_gn_segment = LSBase.subclass({
  init: function () {

    const appState = makeAppState.bind(this)();
    appState.mode = "standalone";

    createApp(SegmentEditor,
      {
        appState,
        standaloneHidden: jQuery(this).find("input[type=hidden]")[0] }
    ).mount(jQuery(this).find(".simplejustice-legal-issue-gn-segment .app > div")[0]);
  }
});

import DialogueRunnerEngine from "./dialogue_runner_engine";
window.guided_navigation_element_dialogue_runner = LSBase.subclass({
  init: function (config) {
    // track guided nav dialogue
    if (_paq !== null && _paq !== undefined) {
      _paq.push(['trackEvent', 'Guided Navigation Dialogue', 'Load Segment', 'Load', config.segment.name]);
    }
    config.$el = jQuery(this);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const engine = new DialogueRunnerEngine(config);
  }
});
window.matter_element_legal_issue_questions = LSBase.subclass({
  init: function (config) {
    config.$el = jQuery(this);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const engine = new DialogueRunnerEngine(config);
  }
});
