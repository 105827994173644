<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label(title="Block to set default on") Block:
        div
            Multiselect(:options="appState.defaultableBlocks",
                    :placeholder="'Choose block...'",
                    v-model="selectedBlock",
                    track-by="id",
                    label="text",
                    @select="changeData",
                    @remove="changeData")
    div.form-row(v-if="details && details.block", v-show="expanded")
        div: label(title="Field to set default value of") Field:
        div
            Multiselect(:options="defaultableFields",
                    :placeholder="'Choose field...'",
                    v-model="selectedField",
                    track-by="id",
                    label="text",
                    @select="changeData",
                    @remove="changeData")
    div.form-row(v-if="details && details.block && details.field", v-show="expanded")
        div: label(title="Default value to set") Value:
        div
            Multiselect(v-if="Array.isArray(defaultValueOptions) && defaultValueOptions.length > 0",
                    :options="defaultValueOptions",
                    :placeholder="'Choose value...'",
                    v-model="selectedValue",
                    track-by="id",
                    label="text",
                    @search-change="searchUsers",
                    @select="changeData",
                    @remove="changeData")
</template>
<script lang="ts">
import InternalsBase from "./internals_base.vue";
import { ajaxGetFormMarker, ajaxRequest } from "@networkninja/common";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  extends: InternalsBase,
  detailsTemplate: {
    block: '',
    field: '',
    value: ''
  },
  data: function() {
    return {
      selectedBlock: null,
      selectedField: null,
      selectedValue: null,
      defaultableFields: [],
      defaultValueOptions: [],
      ajaxFormName: null,
      ajaxHidden: null
    };
  },
  created() {
    const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
    this.ajaxFormName = formName;
    this.ajaxHidden = hidden;
  },
  async mounted() {
    if (this.details.block) {
      this.selectedBlock = this.appState.defaultableBlocks.find(b => b.id === this.details.block);
      if (this.selectedBlock) {
        this.updateDefaultableFields(this.selectedBlock);
        this.selectedField = this.defaultableFields.find(f => f.id === this.details.field);
        if (this.selectedField) {
          const value = !isNaN(this.details.value) && isFinite(this.details.value) ? parseInt(this.details.value) : this.details.value;
          await this.updateDefaultableValues(this.selectedBlock, this.details.field);
          this.selectedValue = this.defaultValueOptions.find(v => v.id === value);
        }
      }
    }
  },
  methods: {
    changeData() {
      this.previousDetails.block = this.details.block;
      this.previousDetails.field = this.details.field;
      this.previousDetails.value = this.details.value;
      this.details.block = this.selectedBlock ? this.selectedBlock.id : null;
      this.details.field = this.selectedField ? this.selectedField.id : null;
      this.details.value = this.selectedValue ? this.selectedValue.id : null;
      this.detailsWatchHandler();
      this.change();
    },
    updateDefaultableFields: function(blockItem) {
      this.defaultableFields = blockItem.fields.map(field => {
        return {
          id: field.field,
          text: field.field_label
        };
      });
    },
    async searchUsers(query) {
      this.defaultValueOptions = await ajaxRequest(
        [
          query || '',
          1
        ],
        this.ajaxFormName,
        this.ajaxHidden,
        'get_user_list',
        this.appState.root
      );
    },
    async updateDefaultableValues(blockItem) {
      const fieldItem = blockItem.fields.find(field => field.field === this.details.field);
      if (fieldItem.field_type === 'list') {
        this.defaultValueOptions = fieldItem.options;
      } else if (fieldItem.field_type === 'user') {
        if (this.details.value) {
          const results = await ajaxRequest(
            [
              parseInt(this.details.value)
            ],
            this.ajaxFormName,
            this.ajaxHidden,
            'get_user_by_id',
            this.appState.root
          );
          this.defaultValueOptions = results.map(u => {
            return { id: u.id, text: u.name };
          });
        } else {
          await this.searchUsers();
        }
      }
    },
    detailsWatchHandler: function() {
      if (this.details.block) {
        const blockItem = this.appState.defaultableBlocks.find(b => b.id === this.details.block);
        if (this.details.block !== this.previousDetails.block) {
          this.details.field = null;
          this.details.value = null;
          this.selectedField = null;
          this.selectedValue = null;
          this.defaultValueOptions = [];
          this.updateDefaultableFields(blockItem);
        }
        if (this.details.field && this.details.field !== this.previousDetails.field) {
          this.details.value = null;
          this.selectedValue = null;
          this.defaultValueOptions = [];
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.updateDefaultableValues(blockItem, this.details.field);
        }
      }
    }
  }
};
</script>
