<template lang="pug">
include ./internals_base.pug
</template>

<script lang="ts">
export default {
  detailsTemplate: {
    // should be overridden
  },
   
  props: [ 'expanded', 'errors', 'warnings', 'details', 'previousDetails', 'change', 'appState', 'elementId' ],
  methods: {
    detailsWatchHandler: function() {
      // override if needed
    }
  }
};
</script>
