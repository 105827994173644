<template>
  <div style="position:relative">
    <div class="app">
      <div
        v-if="route.name !== 'visualize'"
        class="upper-controls"
      >
        <div>
          <div>
            <div class="form-row">
              <div><label>Dialogue name:<span class="required">*</span></label></div>
              <div>
                <input
                  v-model="appState.dialogueName"
                  :class="{ 'form-error': errors['name'] }"
                  placeholder="Please enter a name for this dialogue"
                  type="text"
                >
              </div>
            </div>
            <div class="form-row mode-controls">
              <div class="bold">
                Dialogue type:
              </div>
              <div>
                <label>
                  <input
                    v-model="appState.mode"
                    type="radio"
                    name="dialogueMode"
                    value="interactive"
                  >
                  Interactive
                </label>
                <label>
                  <input
                    v-model="appState.mode"
                    type="radio"
                    name="dialogueMode"
                    value="navigation"
                  >
                  Navigation
                </label>
              </div>
            </div>
          </div>
          <div
            v-show="appState.dialogueId >= 1"
            class="btns"
          >
            <a
              :href="'/guided_navigation/dialogue/questionnaire/' + appState.dialogueId"
              class="mb-2 display-block"
            >
              <button type="button">View as questionnaire</button>
            </a>
            <router-link
              v-if="!segmentBeingEdited"
              title="Visualize"
              :to="{ name: 'visualize' }"
              class="display-block"
            >
              <button type="button">
                Visualize
              </button>
            </router-link>
            <router-link
              v-if="segmentBeingEdited"
              title="Visualize"
              :to="{ name: 'visualize', query: { segmentId: segmentBeingEdited.id } }"
              class="display-block"
            >
              <button type="button">
                Visualize this segment
              </button>
            </router-link>
          </div>
        </div>
        <div class="search">
          <div v-if="appState.enableSearch">
            <button
              type="button"
              @click="search()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="lower-controls">
        <div v-show="!isReady">
          Initializing...
          <i class="fa fa-refresh fa-spin" />
        </div>
        <template v-if="isReady">
          <template v-if="appState.mode === 'interactive' && route.name === 'root'">
            <h2>Manage dialogue segments</h2>
            <div style="display:flex;justify-content:space-between;align-items:center;padding-right:6px;">
              <div class="pt-1 pb-2">
                <label class="h4 mr-2">Dialogue entry point:<span
                  v-if="appState.segments.length > 0"
                  class="required"
                >*</span></label>
                <span v-if="appState.segments.length === 0">No segments created yet</span>
                <Multiselect
                  v-if="appState.segments.length > 0"
                  v-model="selectedEntryPoint"
                  placeholder="Choose entry point..."
                  :options="appState.segments"
                  track-by="id"
                  label="name"
                  @select="updateEntryPoint"
                  @remove="updateEntryPoint"
                />
              </div>
            </div>
          </template>
          <router-view v-slot="{ Component }">
            <component
              :is="Component"
              ref="currentView"
              :entry-point="entryPoint"
              :app-state="appState"
              :segment-being-edited="segmentBeingEdited"
              @save-segment="saveSegment"
            />
          </router-view>
        </template>
      </div>
    </div>
    <div
      v-if="route.name === 'root' || appState.mode === 'navigation'"
      class="footer-controls"
    >
      <button
        v-if="showDialogueSaveButton"
        :class="{ disabled: appState.isSaving }"
        type="button"
        @click="saveDialogue({redirect:'/guided_navigation'})"
      >
        <div style="position:relative;">
          <div :style="{ visibility: appState.isSaving ? 'hidden' : 'visible' }">
            Save dialogue
          </div>
          <div
            v-if="appState.isSaving"
            style="position: absolute;top:0;width:100%;height:100%;text-align:center;"
          >
            <i class="fa fa-refresh fa-spin" />
          </div>
        </div>
      </button>
    </div>
    <Modal
      v-if="isSearching"
      :is-full-width="true"
      :show-footer-buttons="false"
      :no-vertical-center="true"
      :offset-top="'48px'"
      @cancel="hideSearch()"
    >
      <template #header />
      <template #body>
        <div>
          <dialogue-search :app-state="appState" />
        </div>
      </template>
      <template #footer />
    </Modal>
  </div>
</template>

<script lang="ts">
import Modal from '../../common/src/components/Modal.vue';
import { ajaxGetFormMarker, ajaxRequest} from '@networkninja/common';
import { useRoute, useRouter } from 'vue-router';
import Multiselect from 'vue-multiselect';
import DialogueSearch from './dialogue_search.vue';
import { refreshFieldList } from './app_state.js';

export default {
  name: 'DialogueEditor',
  components: {
    Modal,
    'dialogue-search': DialogueSearch,
    Multiselect
  },
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      vm.updateEditingSegment(to, from);
    });
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      route: useRoute(),
      router: useRouter(),
      segmentFilter: '',
      segmentBeingEdited: null,
      isReady: false,
      fieldListRetrievedCount: 0,
      entryPoint: this.config.entryPoint || '',
      selectedEntryPoint: this.config.appState.segments.find((item) => item.id === this.config.entryPoint),
      appState: this.config.appState,
      previousModeDetails: {},
      errors: {},
      isValidated: false,
      createFieldForm: {},
      copySegmentForm: {},
      searchBox: '',
      isSearching: false
    };
  },
  computed: {
    showDialogueSaveButton: function() {
      if ((this.route.name === 'segment-edit' || this.route.name === 'segment-new') && this.appState.mode === 'interactive') {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    showCreateField: function (newValue) {
      if (!newValue) {
        window.removeEventListener('keyup', this.modalCloseOnWindowKeyup);
      } else {
        window.addEventListener('keyup', this.modalCloseOnWindowKeyup);
      }
    },
    '$route'(to, from) {
      this.updateEditingSegment(to, from);
    }
  },
  created: function() {
    if (this.route.name === 'segment-edit' || this.route.name === 'segment-edit-element') {
      this.editSegmentById(this.route.params.id);
    } else if (this.appState.mode === 'navigation') {
      if (this.appState.segments.length === 0) {
        this.router.replace({ name: 'segment-new' });
      } else {
        this.editSegmentById(this.appState.segments[0].id);
        this.router.replace({ name: 'segment-edit', params: { id: this.appState.segments[0].id } });
      }
    } else if (this.route.name === 'segment-new' && this.segmentBeingEdited === null) {
      this.addNewSegment();
    }

    this.previousModeDetails = {
      mode: this.appState.mode,
      segments: [].concat(this.appState.segments)
    };
    this.$watch('this.appState.mode', () => {
      if (this.appState.mode === 'interactive') {
        this.saveSegment(this.$refs.currentView.$data, true);
        this.entryPoint = this.appState.segments[0].id;
      }
      const restoredSegments = [].concat(this.previousModeDetails.segments);
      const restoredEntryPoint = this.previousModeDetails.entryPoint;
      this.previousModeDetails = {
        mode: this.appState.mode,
        entryPoint: this.entryPoint,
        segments: [].concat(this.appState.segments)
      };
      this.appState.segments = restoredSegments;
      this.entryPoint = restoredEntryPoint;
      if (this.appState.mode === 'navigation') {
        if (this.appState.segments.length > 0) {
          this.editSegment(this.appState.segments[0]);
        } else {
          this.addNewSegment();
          this.entryPoint = this.segmentBeingEdited.id;
        }
      }
    });
    this.$watch('this.appState.dialogueName', () => {
      if (this.isValidated) {
        this.validate();
      }
    });
    this.$watch('this.appState.segments', () => {
      if (this.appState.segments.find((item) => item.id === this.entryPoint) === undefined) {
        this.entryPoint = '';
      }
    });
  },
  beforeMount: function () {
    refreshFieldList(this.appState, () => {
      this.fieldListRetrieved();
    });
  },
  methods: {
    fieldListRetrieved: function () {
      this.fieldListRetrievedCount++;
      // LS-84404
      // This function is required to tell the system to only be ready once all the field lists have been retrieved.  If
      // isReady is set to true before all field data is in place, various errors occur.
      // The count of 2 is based on the count of items being retrieved in guided_navigation/main.js in the makeAppState function.
      // If additional field lists are added, this would need to be changed or this code could be refactored.
      if(this.fieldListRetrievedCount>=2) {
        this.isReady = true;
      }
    },
    addNewSegment: function () {
      this.segmentBeingEdited = {
        isNew: true,
        field: '',
        elements: []
      };
      if (this.appState.mode === 'navigation') {
        this.segmentBeingEdited.name = 'navigation';
      }
    },
    editSegment: function (item) {
      this.segmentBeingEdited = {
        id: item.id,
        name: item.name,
        elements: item.elements ? item.elements.map((el) => { return JSON.parse(JSON.stringify(el)); }) : [],
        expressions: item.expressions.map((exp) => { return JSON.parse(JSON.stringify(exp)); }),
        field: item.field,
        defaultDestination: Object.assign({}, item.defaultDestination),
        segmentType: item.segmentType,
        includeLegalIssueEvalution: item.includeLegalIssueEvalution,
        legalIssueEvaluationConfig: item.legalIssueEvaluationConfig
      };
    },
    saveSegment: function (data, localOnly) {
      const saveDialogueCallback = () => {
        if (this.appState.mode === 'interactive') {
          this.router.push({ name: 'root' });
        }
      };

      if (this.validate()) {
        if (data.isNew) {
          delete data.isNew;
          this.appState.segments.push(data);
        } else {
          const segmentToUpdate = this.appState.segments.find((item) => {
            return item.id === data.id;
          });
          Object.keys(data).forEach(function (k) {
            segmentToUpdate[k] = data[k];
          });
        }

        if (this.appState.segments.length > 0 && (this.entryPoint === undefined || this.entryPoint === '')) {
          if (this.segmentBeingEdited) {
            this.entryPoint = data.id;
          } else {
            this.entryPoint = this.appState.segments[0].id;
          }
        }

        if (!localOnly) {
          this.saveDialogue({
            callback: saveDialogueCallback
          });
        } else {
          saveDialogueCallback();
        }
      }
    },
    cancelEditSegment: function () {
      this.segmentBeingEdited = null;
      this.segmentFilter = '';
    },
    toJSON: function () {
      if (this.appState.mode === 'navigation') {
        this.saveSegment(this.$refs.currentView.$data, true);
        this.entryPoint = this.appState.segments[0].id;
      }
      const retval = {
        dialogueId: this.appState.dialogueId,
        dialogueName: this.appState.dialogueName,
        mode: this.appState.mode,
        entryPoint: this.entryPoint,
        segments: this.appState.segments.map(function (item) {
          return {
            id: item.id,
            name: item.name,
            instructions: item.instructions,
            field: item.field,
            defaultDestination: item.defaultDestination,
            segmentType: item.segmentType,
            includeLegalIssueEvalution: item.includeLegalIssueEvalution,
            legalIssueEvaluationConfig: item.legalIssueEvaluationConfig,
            elements: item.elements ? item.elements.map((item) => {
              return {
                id: item.id,
                elementType: item.elementType,
                displayWhen: item.displayWhen,
                displayWhenRules: item.displayWhenRules,
                hasEarlyNextRules: item.hasEarlyNextRules,
                earlyNextRules: item.earlyNextRules,
                earlyNextDestination: item.earlyNextDestination,
                includeAllChannels: item.includeAllChannels,
                channelsToIncludeIn: item.channelsToIncludeIn,
                details: item.details
              };
            })
              : [],
            expressions: item.expressions.map((item) => {
              return {
                rules: item.rules,
                destination: item.destination,
                startNewIntake: item.startNewIntake
              };
            })
          };
        })
      };
      return JSON.stringify(retval);
    },
    validate: function() {
      let isValid = true;
      if (this.segmentBeingEdited) {
        isValid = isValid && this.$refs.currentView.validate();
      }

      if (this.appState.dialogueName === undefined || this.appState.dialogueName.length === 0) {
        this.errors.name = [ 'required' ];
        isValid = false;
      } else {
        delete this.errors.name;
      }
      this.errors = Object.assign({}, this.errors);
      this.isValidated = true;
      return isValid;
    },
    saveDialogue: function(args) {
      if (this.validate()) {
        const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
        this.appState.isSaving = true;
        ajaxRequest(
          [
            {
              id: this.appState.dialogueId,
              config: this.toJSON()
            }
          ],
          formName,
          hidden,
          'save_dialogue',
          this.appState.root)
          .then(responseData => {
            if (responseData.status === 'OK') {
              if (this.appState.dialogueId === -1) {
                this.appState.dialogueId = responseData.guided_nav_id;
                this.appState.segments.forEach((segment) => {
                  if (segment.defaultDestination.dialogue === -1) {
                    segment.defaultDestination.dialogue = this.appState.dialogueId;
                  }
                  segment.expressions.forEach((expr) => {
                    if (expr.destination.dialogue === -1) {
                      expr.destination.dialogue = this.appState.dialogueId;
                    }
                  });
                });
              }
              // collect returned block configs
              const blockConfigs = {};
              responseData.updated_dialogue.segments.forEach((seg) => {
                seg.elements.forEach((elem) => {
                  if (elem.elementType === 'block') {
                    blockConfigs[seg.id + '|' + elem.id] = elem.details.blockConfig;
                  }
                });
              });
              // update any matching block configs in local data and clean up submitted form
              this.appState.segments.forEach((seg) => {
                seg.elements.forEach((elem) => {
                  if (elem.elementType === 'block') {
                    if ((seg.id + '|' + elem.id) in blockConfigs) {
                      elem.details.blockConfig = blockConfigs[seg.id + '|' + elem.id];
                    }
                    delete elem.details.blockConfigFormValues;
                    delete elem.details.blockConfigFormHtml;
                  }
                });
              });
              if (args && args.redirect) {
                window.location = args.redirect;
              } else {
                this.appState.isSaving = false;
                jQuery.gritter.add({
                  text: 'Saved dialogue successfully',
                  sticky: false,

                  class_name: 'success'
                });
                if (args.callback) {
                  args.callback();
                }
              }
            } else {
              this.appState.isSaving = false;
              jQuery.gritter.add({
                text: 'Error saving dialogue',
                sticky: false,

                class_name: 'error'
              });
            }
          });
      }
    },
    editSegmentById(segmentId) {
      const toEdit = this.appState.segments.find((item) => item.id === segmentId);
      if (toEdit) {
        this.editSegment(toEdit);
      } else {
        this.cancelEditSegment();
      }
    },
    updateEditingSegment: function(to, /* from */) {
      this.hideSearch();
      if (to.name === 'root') {
        this.cancelEditSegment();
      } else if (to.name === 'segment-edit' || to.name === 'segment-edit-element') {
        this.editSegmentById(to.params.id);
        if (this.segmentBeingEdited === null) {
          this.router.replace({ name: 'root' });
        }
      } else if (to.name === 'segment-new') {
        this.addNewSegment();
      }
    },
    search: function() {
      this.isSearching = true;
      document.addEventListener("keydown", this.escapeHide.bind(this), false);
    },
    hideSearch: function() {
      this.isSearching = false;
    },
    escapeHide: function(ev) {
      if (ev.keyCode === 27) {
        this.hideSearch();
      }
    },
    updateEntryPoint() {
      this.entryPoint = this.selectedEntryPoint ? this.selectedEntryPoint.id : null;
    }
  }
};
</script>

<style lang="scss">
    .guided_navigation {
        * {
            box-sizing: border-box;
        }
        .app {
            border: 1px solid #ccc;
            .segment_list {
                border: 1px solid #d9d9d9;
                padding: 12px;
                margin-bottom: 24px;
                position: relative;
                input.search {
                    width: 40%;
                    margin-bottom: 6px;
                    border-color: #d9d9d9;
                    &+i.fa-times {
                        color: #c9c9c9;
                        position: relative;
                        left: -20px;
                    }
                }
                .row.hdr {
                    font-weight: 700;
                }
                .row {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: 120%;
                    &:not(:last-child) {
                        border-bottom: 1px solid #e9e9e9;
                    }
                    &:not(.hdr):hover {
                        background: #f9f9f9;
                        i.fa-list {
                            display: block;
                        }
                    }
                    > div {
                        flex: 1;
                        padding: 12px;
                        &.actions {
                            width: 10%;
                            flex: none;
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                    .details {
                        position: absolute;
                        background: #fff;
                        z-index: 1001;
                        border: 1px solid #c9c9c9;
                        max-width: 90%;
                        .label {
                            font-weight: bold;
                        }
                    }
                    .control {
                        cursor: pointer;
                        text-align: center;
                        padding: 0 6px;
                        i {
                            font-size: 20px;
                        }
                        .label {
                            font-size: 10px;
                            padding: 3px 0;
                        }
                        a {
                            color: inherit;
                            text-decoration: inherit;
                        }
                        &.danger {
                            color: #dc3545;
                            &:hover {
                                color: #c82333;
                            }
                        }
                    }
                    .arrow_box {
                        position: absolute;
                        background: #fff;
                        border: 1px solid #ccc;
                    }
                    .arrow_box:after, .arrow_box:before {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    .arrow_box:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-left-color: #fff;
                        border-width: 4px;
                        margin-top: -4px;
                    }
                    .arrow_box:before {
                        border-color: rgba(201, 201, 201, 0);
                        border-left-color: #ccc;
                        border-width: 5px;
                        margin-top: -5px;
                    }
                }
            }
            h2, .h2 {
                font-size: 140%;
                padding: 12px 0;
                font-weight: 700;
            }
            h3, .h3 {
                font-size: 130%;
                padding: 10px 0;
                font-weight: 700;
            }
            h4, .h4 {
                font-size: 120%;
                padding: 8px 0;
                font-weight: 700;
            }
            button {
                margin: 0;
                &.btn_bare {
                    border: none;
                    background: transparent;
                    font-size: 160%;
                    margin-left: inherit;
                    padding: 0;
                }
                &.btn_danger {
                    color: #dc3545;
                    &:hover {
                        color: #c82333;
                    }
                }
            }
            a {
                &.router_button {
                    text-decoration: none;
                    color: inherit;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.btn_bare {
                    color: #000;
                    font-size: 160%;
                    padding-top: 3px;
                }
            }
            .w-100 {
                width: 100% !important;
            }
            .pt-1 {
                padding-top: 6px !important;
            }
            .pt-2 {
                padding-top: 12px !important;
            }
            .pb-1 {
                padding-bottom: 6px !important;
            }
            .pb-2 {
                padding-bottom: 12px !important;
            }
            .pl-1 {
                padding-left: 6px !important;
            }
            .pl-2 {
                padding-left: 12px !important;
            }
            .pr-1 {
                padding-right: 6px !important;
            }
            .pr-2 {
                padding-right: 12px !important;
            }
            .mr-1 {
                margin-right: 6px !important;
            }
            .mr-2 {
                margin-right: 12px !important;
            }
            .ml-1 {
                margin-left: 6px !important;
            }
            .ml-2 {
                margin-left: 12px !important;
            }
            .mt-1 {
                margin-top: 6px !important;
            }
            .mt-2 {
                margin-top: 12px !important;
            }
            .mb-1 {
                margin-bottom: 6px !important;
            }
            .mb-2 {
                margin-bottom: 12px !important;
            }
            .display-flex {
                display: flex !important;
            }
            .display-block {
                display: block !important;
            }
            .block {
                display: block !important;
            }
            .inline-block {
                display: inline-block !important;
            }
            .align-items-center {
                align-items: center !important;
            }
            .justify-flex-end {
                justify-content: flex-end !important;
            }
            .justify-space-between {
                justify-content: space-between !important;
            }
            .bold {
                font-weight: 700;
            }
            .fw-normal {
                font-weight: normal;
            }
            .color-red {
                color: red;
            }
            .form-row {
                display: flex;
                align-items: center;
                width: 50%;
                margin: 12px 0;
                &.top-align {
                    align-items: flex-start;
                }
                >div:first-child {
                    flex: 2;
                    padding-right: 12px;
                }
                >div:last-child {
                    flex: 8;
                }
                label {
                    font-size: 120%;
                    line-height: 120%;
                    font-weight: 700;
                }
                input[type=text] {
                    font-size: 120%;
                    padding: 6px;
                    width: 400px;
                }
                textarea {
                    font-size: 120%;
                    height: 100px;
                    padding: 6px;
                    width: 400px;
                }
            }
            .upper-controls {
                background: #fafafa;
                border-bottom: 1px solid #d9d9d9;
                padding: 6px 18px 6px 18px;
                >div {
                    display: flex;
                }
                >div:first-child >div:first-child {
                    width: 85%;
                    .mode-controls {
                        > div:first-child {
                            font-size: 120%;
                        }
                        > div:last-child {
                            label {
                                font-size: 11px;
                                line-height: 18px;
                            }
                            > :not(:last-child) {
                                margin-right: 6px;
                            }
                        }
                    }
                }
                >div:first-child >div:last-child {
                    width: 15%;
                    text-align: right;
                    padding-top: 14px;
                    a {
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                div.search {
                    justify-content: flex-end;
                }
            }
            .lower-controls {
                padding: 18px;
            }
        }
        .form-error {
            background: #f79483;
        }
        .form-error-border {
            border: 2px solid #f79483;
        }
        .form-required {
            color: #f00;
            font-size: 130%;
        }
        .footer-controls {
            display: flex;
            justify-content: flex-end;
            padding-top: 18px;
        }
        button.disabled {
            pointer-events: none;
        }
    }
    .guided-nav-modal-form {
        .form-row {
            display: flex;
            align-items: center;
            padding: 6px 0;
            label {
                display: block;
                width: 100px;
            }
            input[type=text] {
                width: 200px;
            }
        }
    }
    .gritter-item-wrapper {

        .gritter-item {
            color: white;
            padding: 16px;
            border-radius: 10px;
        }
        &.success {
          .gritter-item {
              background: #28a745;
          }
        }
        &.error {
            .gritter-item {
                background: #dc3545;
            }
        }
        .gritter-top {
            display: none;
        }
        .gritter-bottom {
            display: none;
        }
    }
</style>

