<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Enable NLU:
        div
            input(type="checkbox", v-model="details.enableNLU")
    div.form-row(v-show="expanded && details.enableNLU")
        div: label.mr-2(title="Text to show in dialogue") NLU agent:
        div
            input(v-model="details.nluAgentName")
    div.form-row.wider(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Fields:
            span.form-required *
        div.field-collection-wrapper
            div.field-collection
                div.fieldset-field.hdr(v-if="currentFields.length > 0")
                    div.field-select Field
                    div.nluEntity(v-if="details.enableNLU") NLU entity
                    div.nluDescription(v-if="details.enableNLU") NLU description
                    div.control Remove
                div.fieldset-field(v-for="field in currentFields", :key="field.item.uuid")
                    div.field-select
                        field-selector(v-model="field.item.field", :appState="appState", @change="change")
                    div.nluEntity(v-if="details.enableNLU")
                        input(v-model="field.item.nluEntity")
                    div.nluDescription(v-if="details.enableNLU")
                        textarea(v-model="field.item.nluDescription", rows="3")
                    div.control
                        button.btn_danger.btn_bare(type="button", @click="remove(field.item.uuid)"): i.fa.fa-times
            div
                button(type="button", @click="addField") Add field
</template>
<script lang="ts">
import InternalsBase from './internals_base.vue';
import FieldSelector from './field_selector.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    'field-selector': FieldSelector
  },
  extends: InternalsBase,
  detailsTemplate: {
    fields: [],
    enableNLU: false
  },
  computed: {
    currentFields: function() {
      return this.details.fields.map(field => ({ item: field }));
    }
  },
  methods: {
    addField: function() {
      this.details.fields.push({
        field: '',
        uuid: uuidv4()
      });
    },
    remove: function(uuid) {
      this.details.fields = this.details.fields.filter(field => field.uuid !== uuid);
    }
  }
};
</script>
<style scoped lang="scss">
    .field-collection-wrapper {
        width: 100%;
        flex: inherit;
        .field-collection {
            width: 100%;
            border: 1px solid #c9c9c9;
            padding: 6px;
            margin-bottom: 12px;
            .fieldset-field {
                width: 100%;
                display: flex;
                .field-select {
                    flex: 9;
                }
                .nluEntity {
                    flex: 4;
                    display: flex;
                    input {
                        margin-top: 0;
                        height: 28px;
                    }
                }
                .nluDescription {
                    flex: 7;
                    display: flex;
                    textarea {
                        height: inherit;
                        width: inherit;
                    }
                }
                .control {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    min-width: 40px;
                }
                >div {
                    padding: 6px 6px 6px 0;
                }
                &.hdr {
                    text-decoration: underline;
                    >div {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    .form-row.wider {
        width: 75%;
    }
</style>
