<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Instructions text:
            span.form-required *
        div(:class="{ 'form-error-border': errors['instructionsText'] }")
            tiptap(v-model="details.instructionsText",
               :links_enabled="true"
               :edit_source_enabled="true"
               @update:model-value="triggerRichTextEditorChange")
    translations(@input="change", :key="elementId", v-if="appState.systemLanguages && appState.systemLanguages.length > 0", v-show="expanded", :appState="appState" v-model="details.translations", elementType="instructions")
    div.form-row(v-show="expanded", v-if="appState.enableEnhancedInstructions")
        div: label.mr-2(title="Style as enhanced instructions element") Style as enhanced instructions element:
        div
            input(@change="change", type="checkbox", v-model="details.isEnhancedInstructions")
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Show/Hide") Show Show/Hide:
        div
          input(@change="change", type="checkbox", v-model="details.showShowHide")
    div(v-if="details.showShowHide")
        div.form-row(v-show="expanded")
            div: label.mr-2(title="Text to show in dialogue") Show Button Label:
            div(:class="{ 'form-error-border': errors['showButtonLabel'] }")
                input(@input="change" v-model="details.showButtonLabel")
        div(:class="{ 'form-error-border': errors['instructionsText'] }")
          translations(@input="change", :key="elementId", v-if="appState.systemLanguages && appState.systemLanguages.length > 0", v-show="expanded", :appState="appState" v-model="details.showButtonTranslations", elementType="field", label="Show Button Translations")
        div.form-row(v-show="expanded")
            div: label.mr-2(title="Text to show in dialogue")  Hide Button Label:
            div(:class="{ 'form-error-border': errors['hideButtonLabel'] }")
              input(@input="change" v-model="details.hideButtonLabel")
        div(:class="{ 'form-error-border': errors['instructionsText'] }")
          translations(@input="change", :key="elementId", v-if="appState.systemLanguages && appState.systemLanguages.length > 0", v-show="expanded", :appState="appState" v-model="details.hideButtonTranslations", elementType="field", label="Hide Button Translations")
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Initially Shown") Initially Shown:
        div
            input(@change="change", type="checkbox", v-model="details.showHideInitiallyShown")
</template>
<script lang="ts">
import TipTap from '../../../../tiptap/src/app.vue';
import InternalsBase from './internals_base.vue';
import Translations from './translations.vue';

export default {
  components: {
    'tiptap': TipTap,
    translations: Translations
  },
  extends: InternalsBase,
  detailsTemplate: {
    editorMode: 'wysiwyg',
    instructionsText: '',
    translations: null,
    isEnhancedInstructions: false
  },
  data: function() {
    return {
      showInstructionsEditor: true,
      rich_text_engine: this.appState.rte_engine
    };
  },
  mounted: function() {
    if (this.details.isEnhancedInstructions === undefined) {
      this.details.isEnhancedInstructions = false;
    }
    if (this.details.showButtonLabel === undefined) {
      this.details.showButtonLabel = 'Show Instruction';
    }
    if (this.details.hideButtonLabel === undefined) {
      this.details.hideButtonLabel = 'Hide Instruction';
    }
  },
  methods: {
    triggerRichTextEditorChange: function() {
      this.change();
    }
  }
};
</script>
