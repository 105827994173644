<template lang="pug">
div
    div(v-if="controlType === 'yesno'")
        select(v-model="valueToSet", @change="change")
            option(value="") Choose default value...
            option(value="t") Yes
            option(value="f") No
    div(v-else-if="controlType === 'select'")
        template(v-if="noOptionsReturned")
            | No options exist for this lookup
        template(v-else-if="lookupOptions.length === 0")
            | Loading options...
        Multiselect(:placeholder="'Choose default value...'",
                :options="lookupOptions",
                track-by="id",
                label="text",
                v-model="valueSelected",
                @select="changeSelect",
                @remove="changeSelect",
                v-else
        )
    div(v-else-if="controlType === 'textarea'")
        textarea(v-model="valueToSet", @change="change", rows="3")
    div(v-else-if="controlType === 'date'")
      label
        input(type="radio" value="now" v-model="dateChoice")
        | Set Date to Current Date
      label
        input(type="radio" value="manual" v-model="dateChoice")
        | Pick a date manually
      input(v-if="dateChoice === 'manual'", type="date", v-model="valueToSet", @change="change")
      input(v-if="dateChoice === 'now'", type="hidden", v-model="valueToSet")
    input(v-else, :type="controlType", v-model="valueToSet", @change="change")
</template>

<script lang="ts">
import axios from 'axios';
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: {
    field: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    }
  },
  emits: ['input', 'update:modelValue', 'change', 'mounted'],
  data() {
    return {
      valueSelected: null,
      valueToSet: this.modelValue,
      lookupOptions: [],
      noOptionsReturned: false,
      dateChoice: 'manual'
    };
  },
  computed: {
    controlType() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lookupOptions = [];
      if (this.field.type === 'bool') {
        return 'yesno';
      } else if (['user', 'office', 'lookup', 'lookup_by_number', 'lookup_custom', 'organization'].includes(this.field.type)) {
        this.updateLookupOptions();
        return 'select';
      } else if (this.field.type === 'date') {
        return 'date';
      } else if (this.field.type === 'textarea') {
        return 'textarea';
      } else {
        return 'text';
      }
    }
  },
  watch: {
    lookupOptions() {
      if (this.modelValue) {
        this.valueSelected = this.lookupOptions.find((o) => o.id === this.modelValue);
      }
    },
    modelValue(newValue) {
      this.valueToSet = newValue;
    },
    dateChoice(newValue) {
      if (newValue === 'now') {
        this.valueToSet = 'now'; //use this to represent now which will be handled down the line
        this.change();
      }
    }
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    change: function() {
      this.$emit('update:modelValue', this.valueToSet);
      this.$emit('input', this.valueToSet);
      this.$emit('change');
    },
    changeSelect() {
      this.valueToSet = this.valueSelected.id;
      this.change();
    },
    updateLookupOptions() {
      axios.get('/system/api/options_for_field/', { params: { module: 'matter', field: this.field.field_name } })
        .then(reply => {
          if (reply.data.results && reply.data.results.length === 0) {
            this.noOptionsReturned = true;
          } else {
            this.lookupOptions = this.getLookupObject(reply.data.results);
            this.noOptionsReturned = false;
          }
        });
    },
    getLookupObject(results) {
      const ret = [];
      for (const option of results) {
        if (option.cat) {
          //category is specified create or find existing
          const category = ret.find(group => group.text === option.cat);
          if (! category) {
            //create the category entry
            ret.push({text: option.cat, children: [{id: option.id, text: option.name}]});
          } else {
            //append to category children
            category.children.push({id: option.id, text: option.name});
          }
        } else {
          //no category specified, just add the object
          ret.push({id: option.id, text: option.name});
        }
      }
      return ret.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text ? -1: 0)));
    }
  }
};
</script>
