<template>
  <node-view-wrapper as="span">
    <img
      v-bind="node.attrs"
      ref="resizableImg"
      :draggable="isDraggable"
      :data-drag-handle="isDraggable"
      :class="{'resizing': !isDraggable, 'selected': selected}"
      @mousedown="init_resize_image"
    >
    <div
      v-if="selected && !isAnchor"
      class="mode_selection"
      @click.prevent="editor.chain().focus().toggleResizable().run()"
    >
      {{ isDraggable ? 'Move' : 'Resize' }}
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';

export default {
  components: {
    NodeViewWrapper
  },

  props: nodeViewProps,

  data() {
    return {
      isResizing: false,
      lastMovement: 0,
      aspectRatio: 0
    };
  },

  computed: {
    isDraggable() {
      return this.node?.attrs?.isDraggable;
    },
    isAnchor() {
      return (this.$refs.resizableImg.src.match(/tiptap_anchor/) !== null);
    }
  },

  mounted() {
    this.$refs.resizableImg.onload = () => {
      this.aspectRatio = this.$refs.resizableImg.naturalWidth / this.$refs.resizableImg.naturalHeight;
    };
  },

  methods: {
    init() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.$refs.resizableImg.addEventListener('mousedown'), function(evt) {
        this.init_resize_image(evt);
      };
    },
    init_resize_image(e) {
      if (!e) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      if (this.isDraggable || this.isAnchor) {
        // allow default tiptap functionality to move this image
        return;
      }

      this.isResizing = true;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const end_resize = (e) => {
        self.isResizing = false;
        self.lastMovement = 0;
      };
      document.documentElement.addEventListener('mouseup', (e) => {
        end_resize(e);
        document.documentElement.removeEventListener("mouseup", end_resize, false);
      });

      this.$refs.resizableImg.addEventListener('mousemove', (e) => {
        if (!this.isResizing) {
          return;
        }

        const movement = Math.sqrt(Math.pow(e.offsetY, 2) + Math.pow(e.offsetX, 2));

        if (this.lastMovement > 0) {
          if (movement > this.lastMovement) {
            this.resizeAspectRatio(true);
          } else if (movement < this.lastMovement) {
            this.resizeAspectRatio(false);
          }
        }

        this.lastMovement = movement;
      });

    },
    resizeAspectRatio(grow) {
      let calcH;

      if (grow) {
        calcH = this.$refs.resizableImg.height + 5;
      } else {
        calcH = this.$refs.resizableImg.height - 5;
      }

      const calcW = calcH * this.aspectRatio;

      this.updateAttributes({ width: calcW, height: calcH });
    }
  }
};
</script>

<style scoped>
img {
  cursor: move;
  max-width: 100%;
  height: auto;
}

.resizing {
  cursor: nwse-resize;
}

.selected {
  border: 2px dashed #2B8AC0;
}

.mode_selection {
  display: inline-block;
  background-color: lightslategray;
  font-size: .8rem;
  font-weight: bold;
  border-radius: .8rem;
  padding: .25rem;
  color: #ffffff;
}
</style>
