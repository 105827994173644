<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Headline text:
            span.form-required *
        div: input(:class="{ 'form-error': errors['headlineText'] }", type="text", v-model="details.headlineText", @change="change")
    translations(@input="change", :key="elementId", v-if="appState.systemLanguages && appState.systemLanguages.length > 0", v-show="expanded", :appState="appState" v-model="details.translations", elementType="headline")
</template>
<script lang="ts">
import InternalsBase from './internals_base.vue';
import Translations from './translations.vue';
export default {
  components: {
    translations: Translations
  },
  extends: InternalsBase,
  detailsTemplate: {
    headlineText: '',
    translations: null
  }
};
</script>
