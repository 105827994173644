<template>
  <div class="modal-wrapper">
    <div class="modal-content">
      <i
        class="close-button"
        title="Close/Cancel"
        @click="stopRecordingAndCleanup(); emit('close')"
      >
        <SvgImage pathname="cancel" />
      </i>
      <h2>Speech to Text</h2>
      <div class="recording-area">
        <div
          class="record-button"
          :class="{ 'recording': isRecording }"
          :title="recording_button_title"
          @click="toggleRecording"
        >
          <svg
            v-if="!isRecording"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="icon"
          >
            <path
              fill-rule="evenodd"
              d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div
          v-if="isRecording"
          class="timer"
        >
          <span>Time Remaining: {{ formattedTime }}</span>
        </div>
      </div>
      <textarea
        v-model="transcript"
        placeholder="Your transcribed text will appear here..."
        rows="6"
        :disabled="isRecording || isProcessing"
      />
      <div class="actions">
        <button
          :disabled="!transcript || isRecording || isProcessing"
          @click.prevent="addToNote"
        >
          Add to Note
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted, onMounted } from 'vue';
import axios from 'axios';
import SvgImage from "./SvgImage.vue";

const emit = defineEmits(['close', 'addToNote']);

const isRecording = ref(false);
const isProcessing = ref(false);
const transcript = ref('');
const defaultTimeRemaining = 180; // 3 minutes in seconds
const timeRemaining = ref(defaultTimeRemaining);
const isSafariBrowser = ref(false);
let recognition = null;
let mediaRecorder = null;
let audioChunks = [];
let countdownInterval = null;

const recording_button_title = computed(() => isRecording.value ? 'Stop Recording' : 'Start Recording');

const formattedTime = computed(() => {
  const minutes = Math.floor(timeRemaining.value / 60);
  const seconds = timeRemaining.value % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
});

function toggleRecording() {
  if (isRecording.value) {
    stopRecordingAndCleanup();
  } else {
    // eslint-disable @typescript-eslint/no-floating-promises
    void startRecording();
  }
}

async function startRecording() {
  try {
    if (isSafariBrowser.value) {
      startSpeechRecognitionRecording();
      return;
    }
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = sendAudioToServer;

    mediaRecorder.start();
    isRecording.value = true;
    timeRemaining.value = defaultTimeRemaining;
    startCountdown();
  } catch (error) {
    console.error('Error accessing microphone:', error);
  }
}

function startSpeechRecognitionRecording() {
  if (recognition) {
    recognition.start();
    isRecording.value = true;
    timeRemaining.value = defaultTimeRemaining;
    startCountdown();
  } else {
    console.error('Speech recognition not available');
  }
}

function handleSpeechRecognitionResult(event) {
  const result = event.results[event.results.length - 1];
  if (result.isFinal) {
    if (transcript.value) {
      transcript.value += ' ' + result[0].transcript;
    } else {
      transcript.value = result[0].transcript;
    }
  }
}

function stopRecording() {
  if (isSafariBrowser.value) {
    if (recognition) {
      recognition.stop();
    }
  } else if (mediaRecorder && mediaRecorder.state !== 'inactive') {
    mediaRecorder.stop();

    // Stop all tracks in the stream
    if (mediaRecorder.stream) {
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
  }
  isRecording.value = false;
  stopCountdown();
}

function startCountdown() {
  countdownInterval = setInterval(() => {
    if (timeRemaining.value > 0) {
      timeRemaining.value--;
    } else {
      stopRecording();
    }
  }, 1000);
}

function stopCountdown() {
  clearInterval(countdownInterval);
}

async function sendAudioToServer() {
  isProcessing.value = true;
  const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
  const formData = new FormData();
  formData.append('audio', audioBlob, 'recording.wav');

  try {
    const response = await axios.post('/api/v1/app/tiptap/transcribe', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.data && response.data.transcription) {
      if (transcript.value) {
        // If there's existing text, add a new line before appending
        transcript.value += '\n' + response.data.transcription;
      } else {
        // If it's empty, just set the new transcription
        transcript.value = response.data.transcription;
      }
    } else {
      console.error('Transcription failed:', response.data);
    }
  } catch (error) {
    console.error('Error sending audio to server:', error);
  } finally {
    isProcessing.value = false;
    stopRecordingAndCleanup();
  }
}

function stopRecordingAndCleanup() {
  stopRecording();
  if (!isSafariBrowser.value) {
    mediaRecorder = null;
    audioChunks = [];
  }
}

function addToNote() {
  emit('addToNote', transcript.value);
  transcript.value = '';
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

onMounted(() => {
  isSafariBrowser.value = isSafari();
  if (isSafariBrowser.value) {
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.onresult = handleSpeechRecognitionResult;
  }
});

onUnmounted(() => {
  stopRecordingAndCleanup();
});
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 400px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.recording-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.record-button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.record-button:hover {
  background-color: #e0e0e0;
}

.record-button.recording {
  background-color: #ff4444;
}

.record-button.recording svg {
  color: white;
}

.icon {
  width: 40px;
  height: 40px;
  color: #333;
}

.timer {
  font-size: 1.2em;
  margin-top: 8px;
  color: #333;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 8px 16px;
  background: none;
  background-color: #4CAF50 !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>