import axios from 'axios';
import { reactive } from 'vue';

export const refreshFieldList = (appState, callback) => {
  const ajax_matter_url = "/system/api/module_field_list/?module=matter";
  axios.get(ajax_matter_url).then((res) => {
    appState.matterFieldList = res.data.results;
    if (typeof appState.matterFieldList === "undefined") {
      throw "Invalid response from " + ajax_matter_url;
    } else {
      appState.matterFieldList.forEach((item) => {
        if (item.active !== true) {
          item.short_name = "(INACTIVE) " + item.short_name;
        }
        if (typeof item.short_name !== "string") {
          item.short_name = typeof item.description !== "string" ? "" : item.description;
        }
        item.lookup_name = (item.table_name ? item.table_name : "undefined_table_name") + ":" + item.field_name;
      });
    }
    if (callback) {
      callback(res);
    }
  });
  const ajax_service_url = "/system/api/module_field_list/?module=phase&custom_fields_only=0";
  axios.get(ajax_service_url).then((res) => {
    appState.customServiceFields = res.data.results;
    if (typeof appState.customServiceFields === "undefined") {
      throw "Invalid response from " + ajax_service_url;
    } else {
      appState.customServiceFields.forEach((item) => {
        if (item.active !== true) {
          item.short_name = "(INACTIVE) " + item.short_name;
        }
        if (typeof item.short_name !== "string") {
          item.short_name = typeof item.description !== "string" ? "" : item.description;
        }
        item.lookup_name = (item.table_name ? item.table_name : "undefined_table_name") + ":" + item.field_name;
      });
    }
    if (callback) {
      callback(res);
    }
  });
};

export default (element) => {
  return reactive({
    dialogueId: null,
    dialogueName: "",
    mode: "interactive",
    previousModeDetails: {},
    matterFieldList: [],
    customServiceFields: [], // service (phase) custom field options
    segments: [],
    blocks: [],
    defaultableBlocks: [],
    noteTypes: [],
    serviceTypes: [],
    listviews: [],
    allDialogues: [],
    dialogueNameElement: null,
    isSaving: false,
    documentTemplates: [],
    enableFieldset: false,
    enableLegalIssueSegment: false,
    enableMultilingual: false,
    enableNLU: false,
    enableEnhancedInstructions: false,
    fieldChoices: [],
    enableNewIntakeDestinations: false,
    systemLanguages: [],
    root: element.id
  });
};
