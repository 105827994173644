<template>
  <div>
    <div class="form-row">
      <div>
        <label title="Field to render in dialogue form">Field to populate:<span
          class="form-required"
        >*</span></label>
      </div>
      <div :class="{ 'form-error-border': errors['field'] }">
        <field-selector
          v-model="details.field"
          :app-state="appState"
          :remove-display-only="false"
          @change="change"
        />
      </div>
    </div>
    <div
      v-if="warnings.field.length &gt; 0"
      class="form-required"
    >
      {{ warnings.field.join(" ") }}
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label title="Label">Label:</label></div>
      <div>
        <input
          v-model="details.label"
          type="text"
          placeholder="Leave empty for default"
          @change="change"
        >
      </div>
    </div>
    <translations
      v-if="appState.systemLanguages &amp;&amp; appState.systemLanguages.length &gt; 0"
      v-show="expanded"
      :key="elementId"
      v-model="details.translations"
      :app-state="appState"
      element-type="field"
      @input="change"
    />
    <div
      v-show="expanded"
      v-if="selectedFieldShouldShowToggler"
      class="form-row"
    >
      <div><label title="Use this toggler style instead of default">Override Toggler Style:</label></div>
      <div>
        <div class="radio">
          <label><input
            v-model="details.togglerStyle"
            type="radio"
            value="showAsDropdown"
            :name="'togglerStyle_' + elementId"
            @change="change"
          >Show as dropdown
            menu</label><label><input
            v-model="details.togglerStyle"
            type="radio"
            value="showAsRadio"
            :name="'togglerStyle_' + elementId"
            @change="change"
          >Show as radio buttons
            {{ forceSingleOverrideWarning }}</label><label><input
            v-model="details.togglerStyle"
            type="radio"
            value="showAsRadioWithRollup"
            :name="'togglerStyle_' + elementId"
            @change="change"
          >Show
            as radio buttons with rollup {{ forceSingleOverrideWarning }}</label>
        </div>
      </div>
      <div
        v-show="expanded"
        v-if="selectedFieldIsMulti"
        class="form-row"
      >
        <div><label title="Force Single-selection">Force Single-selection:</label></div>
        <div>
          <input
            v-model="details.forceSingle"
            type="checkbox"
            @change="change"
          >
        </div>
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label title="Set a default value for this field">Set a default value for the field:</label></div>
      <div>
        <input
          v-model="details.hasDefaultValue"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      v-if="details.hasDefaultValue"
      class="form-row"
    >
      <div><label title="Set a default value for this field">Default value:</label></div>
      <div v-if="!selectedField">
        Please select a field to populate
      </div>
      <template v-if="selectedField">
        <template v-if="selectedField.type === 'date' || selectedField.type === 'timestamp'">
          <div>
            <div class="radio small-vert-space">
              <label><input
                v-model="details.defaultValueType"
                type="radio"
                value="specified"
                :name="elementId + '_default_type'"
              >Specified
                {{ selectedField.type }}</label>
            </div>
            <field-value-selector
              v-if="details.defaultValueType === 'specified'"
              v-model="details.defaultValue"
              :field="selectedField"
              @change="change"
            />
            <div class="radio small-vert-space">
              <label><input
                v-model="details.defaultValueType"
                type="radio"
                value="now"
                :name="elementId + '_default_type'"
              >Current
                {{ selectedField.type }}</label>
            </div>
          </div>
        </template>
        <template
          v-else-if="selectedFieldIsTypeUser"
        >
          <div>
            <div class="radio" />
            <label><input
              v-model="details.useWhichUser"
              type="radio"
              value="currentUser"
              :name="'useWhichUser_' + elementId"
              @change="change"
            >Current User</label><label><input
              v-model="details.useWhichUser"
              type="radio"
              value="selectedUser"
              :name="'useWhichUser_' + elementId"
              @change="change"
            >Selected User</label>
            <template
              v-if="details.useWhichUser === 'selectedUser' &amp;&amp; details.hasDefaultValue"
            />
          </div>
          <div class="small-vert-space">
            <field-value-selector
              v-show="expanded"
              v-model="details.defaultValue"
              :field="selectedField"
              @change="change"
            />
          </div>
        </template>
        <template v-else-if="selectedFieldIsTypeOffice">
          <div>
            <div class="radio" />
            <label><input
              v-model="details.useWhichOffice"
              type="radio"
              value="currentUsersOffice"
              :name="'useWhichOffice_' + elementId"
              @change="change"
            >User's Office</label><label><input
              v-model="details.useWhichOffice"
              type="radio"
              value="selectedOffice"
              :name="'useWhichOffice_' + elementId"
              @change="change"
            >Selected Office</label>
            <template v-if="details.useWhichOffice === 'selectedOffice' &amp;&amp; details.hasDefaultValue">
              <div class="small-vert-space">
                <field-value-selector
                  v-show="expanded"
                  v-model="details.defaultValue"
                  :field="selectedField"
                  @change="change"
                />
              </div>
            </template>
          </div>
        </template>
        <template v-else-if="selectedFieldIsIntakeProgram">
          <div>
            <div class="radio" />
            <label><input
              v-model="details.useWhichProgram"
              type="radio"
              value="currentUsersProgram"
              name="'useWhichProgram_' + elementId"
              @change="change"
            >User's Program</label><label><input
              v-model="details.useWhichProgram"
              type="radio"
              value="selectedProgram"
              :name="'useWhichProgram_' + elementId"
              @change="change"
            >Selected Program</label>
            <template v-if="details.useWhichProgram === 'selectedProgram' &amp;&amp; details.hasDefaultValue">
              <div class="small-vert-space">
                <field-value-selector
                  v-show="expanded"
                  v-model="details.defaultValue"
                  :field="selectedField"
                  @change="change"
                />
              </div>
            </template>
          </div>
        </template>
        <template v-else-if="selectedFieldIsIntakeType">
          <div>
            <div class="radio" />
            <label><input
              v-model="details.useWhichIntakeType"
              type="radio"
              value="currentUsersIntakeType"
              name="'useWhichIntakeType_' + elementId"
              @change="change"
            >User's
              Preference</label><label><input
              v-model="details.useWhichIntakeType"
              type="radio"
              value="selectedIntakeType"
              :name="'useWhichIntakeType_' + elementId"
              @change="change"
            >Selected Intake Type</label>
            <template v-if="details.useWhichIntakeType === 'selectedIntakeType' &amp;&amp; details.hasDefaultValue">
              <div class="small-vert-space">
                <field-value-selector
                  v-show="expanded"
                  v-model="details.defaultValue"
                  :field="selectedField"
                  @change="change"
                />
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div>
            <field-value-selector
              v-model="details.defaultValue"
              :field="selectedField"
              @change="change"
            />
          </div>
        </template>
      </template>
    </div>
    <div
      v-show="expanded"
      v-if="details.hasDefaultValue"
      class="form-row"
    >
      <div><label title="Override existing value with default">Override existing value with default</label></div>
      <div>
        <input
          v-model="details.overrideExisting"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label title="Field should be rendered as a hidden element">Hidden:</label></div>
      <div>
        <input
          v-model="details.isHidden"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label title="Add required validation to this field">Field should be required:</label></div>
      <div>
        <input
          v-model="details.isRequired"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label title="Instead of an input, just display its value">Field should be read-only:</label></div>
      <div>
        <input
          v-model="details.isReadOnly"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label title="Reset the field when hidden for use in later logic">Field should be reset when hidden by
          logic:</label>
      </div>
      <div>
        <input
          v-model="details.resetWhenHidden"
          type="checkbox"
          @change="change"
        >
      </div>
      <template v-if="selectedField">
        <div
          v-show="expanded"
          v-if="selectedField.type === 'text' || selectedField.type === 'textarea'"
          class="form-row"
        >
          <div>
            <label title="Text Width">Text Width (Columns)
              <select
                v-model="details.textWidth"
                @change="change"
              >
                <option
                  v-for="(value, key) in textWidthOpts"
                  :key="value"
                  :value="value"
                >{{ key }}</option>
              </select>
            </label>
          </div>
        </div>
        <div
          v-show="expanded"
          v-if="selectedField.type === 'text' || selectedField.type === 'textarea'"
          class="form-row"
        >
          <div>
            <label title="Text Height">Text Height (Rows)
              <select
                v-model="details.textHeight"
                @change="change"
              >
                <option
                  v-for="(value, key) in textHeightOpts"
                  :key="value"
                  :value="value"
                >{{ key }}</option>
              </select>
            </label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import InternalsBase from './internals_base.vue';
import FieldSelector from './field_selector.vue';
import FieldValueSelector from './field_value_selector.vue';
import Translations from './translations.vue';
export default {
  components: {
    'field-value-selector': FieldValueSelector,
    'field-selector': FieldSelector,
    translations: Translations
  },
  extends: InternalsBase,
  detailsTemplate: {
    field: '',
    label: '',
    isReadOnly: false,
    isRequired: false,
    hasDefaultValue: false,
    useWhichUser: 'selectedUser',
    useWhichOffice: 'selectedOffice',
    useWhichProgram: 'selectedProgram',
    useWhichIntakeType: 'selectedIntakeType',
    defaultValue: null,
    defaultValueType: 'specified',
    overrideExisting: false,
    translations: null,
    togglerStyle: null,
    forceSingle: false,
    isHidden: false,
    textWidth: null,
    textHeight: null
  },
  data: function() {
    return {
      textWidthOpts: {},
      textHeightOpts: {}
    };
  },
  computed: {
    selectedField() {
      return this.appState.matterFieldList.find((item) => { return item.lookup_name === this.details.field; });
    },
    selectedFieldShouldShowToggler() {
      if (this.selectedField) {
        return ['lookup_custom', 'lookup'].indexOf(this.selectedField.type) >= 0;
      } else {
        return false;
      }
    },
    selectedFieldIsMulti() {
      if (this.selectedField) {
        return this.selectedField.multiple === true;
      } else {
        return false;
      }
    },
    forceSingleOverrideWarning() {
      return this.selectedFieldIsMulti ? '(forces single-selection)' : '';
    },
    selectedFieldIsTypeUser() {
      return this.selectedField.type === 'user';
    },
    selectedFieldIsTypeOffice() {
      return this.selectedField.type === 'office';
    },
    selectedFieldIsIntakeProgram() {
      return this.selectedField.lookup_name === 'matter:program';
    },
    selectedFieldIsIntakeType() {
      return this.selectedField.lookup_name === 'matter:intake_type';
    }
  },
  created: function () {
    this.textWidthOpts = {
      Auto: null,
      40: 40,
      50: 50,
      60: 60,
      70: 70
    };
    this.textHeightOpts = {
      Auto: null,
      1: 1,
      3: 3,
      7: 7,
      15: 15
    };
    if (this.details.isRequired === undefined) {
      this.details.isRequired = false;
    }
    if (this.details.isReadOnly === undefined) {
      this.details.isRequired = false;
    }
    if (this.details.defaultValueType === undefined) {
      this.details.defaultValueType = 'specified';
    }
    if (this.details.isHidden === undefined) {
      this.details.isHidden = false;
    }
    if (this.details.textWidth === undefined) {
      this.details.textWidth = null;
    }
    if (this.details.textHeight === undefined) {
      this.details.textHeight = null;
    }
    if (this.details.useWhichUser === undefined) {
      this.details.useWhichUser = 'selectedUser';
    }
    if (this.details.useWhichOffice === undefined) {
      this.details.useWhichOffice = 'selectedOffice';
    }
    if (this.details.useWhichProgram === undefined) {
      this.details.useWhichProgram = 'selectedProgram';
    }
    if (this.details.useWhichIntakeType === undefined) {
      this.details.useWhichIntakeType = 'selectedIntakeType';
    }
    if (this.details.display_only === undefined) {
      this.details.display_only = false;
    }
    if (this.details.resetWhenHidden === undefined) {
      this.details.resetWhenHidden = false;
    }
  },
  methods: {
    detailsWatchHandler: function() {
      if (this.details.isReadOnly && this.previousDetails.isRequired) {
        this.details.isRequired = false;
      } else if (this.details.isRequired && this.previousDetails.isReadOnly) {
        this.details.isReadOnly = false;
      }
      if (this.details.field !== this.previousDetails.field || this.details.hasDefaultValue !== this.previousDetails.hasDefaultValue) {
        this.details.defaultValue = '';
      }
      if (this.details.hasDefaultValue && this.details.field && this.selectedField.display_only) {
        this.details.field = '';
      }
    }
  }
};
</script>
