import Image                  from '@tiptap/extension-image';
import {VueNodeViewRenderer}  from '@tiptap/vue-3';
import ResizableImageTemplate from './ResizableImageTemplate.vue';

export default Image.extend({
    name: 'ResizableImage',

    addAttributes() {
        return {
            ...this.parent?.(),

            width: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },

            height: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },

            isDraggable: {
                default: true,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                renderHTML: (attributes) => {
                    return {};
                }
            }
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),

            toggleResizable:
                () =>
                    ({ tr }) => {
                        const node = tr?.selection?.node;

                        if (node?.type?.name === 'ResizableImage') {
                            node.attrs.isDraggable = !node.attrs.isDraggable;
                        }
                    }
        };
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableImageTemplate);
    }
});
