<template>
  <div
    class="picker-container"
    :class="{ compact: isCompact }"
  >
    <div class="destination-type">
      <div>
        <label>
          <input
            v-model="destination.is_complete"
            type="radio"
            :name="radioName"
            :value="false"
            @change="destinationChange"
          >
          Go to segment
        </label>
      </div>
      <div>
        <label>
          <input
            v-model="destination.is_complete"
            type="radio"
            :name="radioName"
            :value="true"
            @change="destinationChange"
          >
          Exit this dialogue
        </label>
      </div>
    </div>
    <div class="go-to-segment">
      <div
        :class="{ dim: destination.is_complete }"
        class="choose-segment"
      >
        <div class="header">
          Choose segment:
        </div>
        <div class="component">
          <label>Dialogue:</label>
          <template v-if="appState.mode === 'interactive' || appState.allDialogues.length > 0">
            <div>
              <Multiselect
                v-model="dialogue"
                :options="dialoguesForDestination"
                track-by="id"
                label="name"
                @select="destinationChange"
                @remove="destinationChange"
              />
            </div>
          </template>
          <template v-else>
            <span class="color-red">No dialogues to navigate to</span>
          </template>
        </div>
        <div class="component">
          <label>Segment:<span
            v-if="!destination.is_complete"
            class="required"
          >*</span></label>
          <div :class="{ 'form-error-border': errors['segment'] }">
            <Multiselect
              ref="segmentSelect"
              v-model="segment"
              :options="segmentsForDestination"
              :placeholder="'Please select destination segment...'"
              track-by="id"
              label="name"
              :disabled="appState.mode === 'navigation' && appState.allDialogues.length === 0 ? 'disabled' : undefined"
              @select="destinationChange"
              @remove="destinationChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
 
import Multiselect from 'vue-multiselect';
import { v4 as uuidv4 } from 'uuid';
export default {
  components: { Multiselect },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    appState: {
      type: Object,
      required: true
    },
    otherSegments: {
      type: Array,
      required: true
    },
    isCompact: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'validation', 'create-segment'],
  data: function() {
    return {
      destination: {
        is_complete: this.modelValue.is_complete,
        dialogue: this.modelValue.dialogue,
        segment: this.modelValue.segment
      },
      previousDestination: {},
      radioName: uuidv4(),
      isValid: true,
      isValidated: false,
      errors: {},
      dialogue: null,
      segment: null
    };
  },
  computed: {
    dialoguesForDestination: function() {
      const retval = this.appState.allDialogues
        .slice() // Creates a shallow copy of the array
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      if (this.appState.mode === 'interactive') {
        retval.unshift({
          id: this.appState.dialogueId,
          name: 'This dialogue'
        });
      }
      return retval;
    },
    segmentsForDestination: function() {
      let retval = [];
      if (this.destination.dialogue) {
        const destinationDialogueId = parseInt(this.destination.dialogue);
        if (destinationDialogueId === this.appState.dialogueId) {
          retval = [].concat(this.otherSegments);
        } else {
          const destinationDialogue = this.appState.allDialogues.find(d => d.id === destinationDialogueId);
          if (destinationDialogue) {
            retval = [].concat(this.appState.allDialogues.find(d => d.id === destinationDialogueId).segments);
          }
        }
      }
      if (this.appState.mode === 'interactive') {
        retval.push({
          id: 'destination_createNew',
          name: 'Create new segment..',
          disabled: parseInt(this.destination.dialogue) !== this.appState.dialogueId
        });
      }
      return retval.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  },
  watch: {
    modelValue: function(newValue) {
      Object.assign(this.destination, newValue);
    },
    destination: {
      deep: true,
      handler() {
        if (this.isValidated) {
          this.validate();
        }
      }
    }
  },
  mounted: function() {
    this.dialogue = this.dialoguesForDestination.find(d => d.id === this.modelValue.dialogue) || null;
    this.segment = this.segmentsForDestination.find(s => s.id === this.modelValue.segment) || null;
    this.previousDestination = Object.assign({}, this.destination);
  },
  methods: {
    emitUpdate: function() {
      this.$emit('update:modelValue', {
        is_complete: this.destination.is_complete,
        dialogue: parseInt(this.destination.dialogue),
        segment: this.destination.segment
      });
    },
    destinationChange: function() {
      this.destination.dialogue = this.dialogue ? this.dialogue.id : null;
      this.destination.segment = this.segment ? this.segment.id : null;
      if (this.previousDestination.dialogue !== this.destination.dialogue) {
        this.destination.segment = '';
        this.segment = null;
      }
      if (this.destination.is_complete) {
        this.destination.segment = '';
        this.destination.dialogue = this.appState.dialogueId;
        this.dialogue = this.dialoguesForDestination.find(d => d.id === this.appState.dialogueId);
      }
      this.emitUpdate();
      if (this.destination.segment === 'destination_createNew') {
        this.destination.segment = '';
        this.$emit(
          'create-segment',
          (destinationSegment) => {
            this.destination.segment = destinationSegment.id;
            this.emitUpdate();
          }
        );
      }
      this.previousDestination = Object.assign({}, this.destination);
    },
    validate: function() {
      this.errors = {};
      if (!this.destination.is_complete && (this.destination.segment === undefined || this.destination.segment === '')) {
        this.errors.segment = 'required';
      }
      this.emitValidation();
      this.isValidated = true;
      this.isValid = Object.keys(this.errors).length === 0;
    },
    emitValidation: function() {
      this.$emit('validation', this.errors);
    }

  }
};
</script>

<style lang="scss" scoped>
    .picker-container {
        display: flex;
        align-items: flex-start;
        .destination-type {
            flex: 1;
            padding-right: 6px;
        }
        .go-to-segment {
            height: 32px;
            flex: 9;
            display: flex;
            align-items: center;
            min-width: 75%;
            .choose-segment {
                width: 100%;
                display: flex;
                align-items: center;
                &.dim {
                    opacity: 0.2;
                    pointer-events: none;
                }
                .header {
                    width: 100px;
                    margin-left: 6px;
                    margin-right: 6px;
                    font-weight: 700;
                    line-height: 20px;
                }
                .component {
                    flex: 4;
                    display: flex;
                    align-items: center;
                    label {
                        padding: 0 16px;
                        cursor: default;
                        width: 75px;
                        line-height: 20px;
                    }
                    >div {
                        flex: auto;
                    }
                }
            }
        }
        &.compact {
            padding: 0 12px;
            .destination-type {
                padding-right: 12px;
                >div:not(:last-child) {
                    margin-bottom: 6px;
                }
                label {
                    line-height: 20px;
                }
            }
            label {
                white-space: nowrap;
            }
            .go-to-segment {
                align-items: flex-start;
                height: inherit;
                .choose-segment {
                    padding: 0;
                    flex-direction: column;
                    align-items: flex-start;
                    label {
                        padding: 6px 0;
                        margin: 0;
                    }
                    .component {
                        width: 100%;
                    }
                    >div {
                        display: flex;
                        align-items: center;
                        label {
                            width: 60px;
                        }
                        &:not(:last-child) {
                            margin: 0 0 6px 0;
                        }
                    }
                }
            }
        }
    }
</style>
