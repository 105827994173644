<template>
  <div>
    <textarea
      v-show="false"
      :id="id"
      v-model="hidden_value"
      :name="name"
      rows="15"
      cols="80"
      @blur="hidden_value = post_render(editor.getHTML())"
    />
    <div
      class="tiptap_wrapper"
      @dragenter.prevent
      @dragleave.prevent
      @dragover.prevent
      @drop.prevent="handle_image_drop"
      @paste="handle_image_paste"
    >
      <div
        class="tiptap_menu"
        role="toolbar"
        :aria-controls="id"
        @keyup.tab="toolbar_reset"
      >
        <div
          v-if="active_dialog === SOURCE_DIALOG"
          style="display: block; position: absolute; margin-left: 1rem;"
        >
          <em>Menu buttons are disabled during source editing</em>
        </div>

        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('bold'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Bold"
          :tabindex="toolbar_show ? 0 : -1"
          @click.prevent="editor.chain().focus().toggleBold().run()"
          @keyup="announce_skip_toolbar"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="bold" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('italic'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Italic"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleItalic().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="italic" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('underline'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Underline"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleUnderline().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="underline" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('strike'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Strike"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleStrike().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="strike" />
        </button>
        <template v-if="highlighter_enabled">
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': editor && editor.isActive('highlight'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
            title="Highlight"
            tabindex="-1"
            @click.prevent="editor.chain().focus().toggleHighlight().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="highlight" />
          </button>
        </template>
        <div
          class="tiptap_divider"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
        />

        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Undo"
          tabindex="-1"
          @click.prevent="editor.chain().focus().undo().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="undo" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Redo"
          tabindex="-1"
          @click.prevent="editor.chain().focus().redo().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="redo" />
        </button>

        <div
          class="tiptap_divider"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
        />

        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('heading', { level: 1 }), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Heading 1"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="heading_1" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('heading', { level: 2 }), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Heading 2"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="heading_2" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('paragraph'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Paragraph"
          tabindex="-1"
          @click.prevent="editor.chain().focus().setParagraph().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="paragraph" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('bulletList'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Bullet List"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleBulletList().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="bullet_list" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('orderedList'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Ordered List"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleOrderedList().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="ordered_list" />
        </button>

        <div
          class="tiptap_divider"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
        />

        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'is-active': editor && editor.isActive('blockquote'), 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Blockquote"
          tabindex="-1"
          @click.prevent="editor.chain().focus().toggleBlockquote().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="blockquote" />
        </button>

        <div
          class="tiptap_divider"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
        />

        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Hard Break"
          tabindex="-1"
          @click.prevent="editor.chain().focus().setHardBreak().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="hard_break" />
        </button>
        <button
          :ref="el => insert_toolbar_button(el)"
          class="menu-item"
          :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          title="Clear Format"
          tabindex="-1"
          @click.prevent="editor.chain().focus().clearNodes().unsetAllMarks().run()"
          @keyup.left="toolbar_previous"
          @keyup.right="toolbar_next"
        >
          <SvgImage pathname="clear_format" />
        </button>

        <template v-if="links_enabled || upload_url || edit_source_enabled">
          <div
            class="tiptap_divider"
            :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          />
        </template>

        <template v-if="links_enabled">
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': editor?.getAttributes('link')?.href, 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
            title="Add/Edit Link"
            tabindex="-1"
            @click.prevent="toggle_dialog(LINK_DIALOG)"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="link" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
            title="Remove Link"
            tabindex="-1"
            @click.prevent="unlink()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="remove_link" />
          </button>
          <button v-if="anchors_enabled"
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
            title="Add Anchor"
            tabindex="-1"
            @click.prevent="toggle_dialog(ANCHOR_DIALOG)"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="anchor" />
          </button>
        </template>

        <template v-if="upload_url">
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === IMAGE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
            title="Add Image"
            tabindex="-1"
            @click.prevent="toggle_dialog(IMAGE_DIALOG)"
            @keydown.esc="toggle_dialog(IMAGE_DIALOG, true)"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="image" />
          </button>
        </template>

        <template v-if="tables_enabled">
          <div
            class="tiptap_divider"
            :class="{ 'menu-item-hidden': active_dialog === SOURCE_DIALOG }"
          />
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG}"
            title="Insert Table"
            tabindex="-1"
            @click.prevent="toggle_dialog(TABLE_DIALOG)"
            @keydown.esc="toggle_dialog(TABLE_DIALOG, true)"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table" />
          </button>
        </template>
        <template v-if="tables_enabled && editor && editor.isActive('table')">
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Insert Column Before"
            tabindex="-1"
            @click.prevent="editor.chain().focus().addColumnBefore().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_insert_column_left" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Insert Column After"
            tabindex="-1"
            @click.prevent="editor.chain().focus().addColumnAfter().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_insert_column_right" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Delete Column"
            tabindex="-1"
            @click.prevent="editor.chain().focus().deleteColumn().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_delete_column" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Insert Row Above"
            tabindex="-1"
            @click.prevent="editor.chain().focus().addRowBefore().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_insert_row_top" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Insert Row Below"
            tabindex="-1"
            @click.prevent="editor.chain().focus().addRowAfter().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_insert_row_bottom" />
          </button>
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === TABLE_DIALOG, 'menu-item-hidden': active_dialog === SOURCE_DIALOG || ! (editor && editor.isActive('table'))}"
            title="Delete Row"
            tabindex="-1"
            @click.prevent="editor.chain().focus().deleteRow().run()"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="table_delete_column" />
          </button>
        </template>

        <template v-if="edit_source_enabled">
          <button
            :ref="el => insert_toolbar_button(el)"
            class="menu-item"
            :class="{ 'is-active': active_dialog === SOURCE_DIALOG }"
            title="Source"
            tabindex="-1"
            @click.prevent="toggle_dialog(SOURCE_DIALOG)"
            @keyup.left="toolbar_previous"
            @keyup.right="toolbar_next"
          >
            <SvgImage pathname="source" />
          </button>
        </template>
      </div>
      <div
        v-show="active_dialog === ANCHOR_DIALOG"
        class="menu_dialog"
      >
        <h2>Add Anchor</h2>
        <div class="anchor_dialog_content">
          <label style="align-self: center;">Name:</label>
          <div>
            <input
              ref="anchor_name"
              type="text"
              name="anchor_name"
              size="40"
              @keydown.enter.prevent="add_anchor()"
              @keydown.esc.prevent="toggle_dialog(ANCHOR_DIALOG, true)"
            >
            <br>
            <br>
            <em>e.g. #anchor</em>
          </div>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="add_anchor()"
            @keydown.esc="toggle_dialog(ANCHOR_DIALOG, true)"
          >
            <SvgImage pathname="save" />
          </i>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="toggle_dialog(ANCHOR_DIALOG, true)"
            @keydown.esc="toggle_dialog(ANCHOR_DIALOG, true)"
          >
            <SvgImage pathname="cancel" />
          </i>
        </div>
      </div>

      <div
        v-show="active_dialog === TABLE_DIALOG"
        class="menu_dialog"
      >
        <h2>Add Table</h2>
        <div class="table_dialog_content">
          <div>
            <label style="align-self: center;">Rows:</label>
            <input
              ref="table_rows"
              type="number"
              name="table_rows"
              size="5"
              min="1"
              max="99"
              @keydown.esc.prevent="toggle_dialog(TABLE_DIALOG, true)"
            >
            <br>
            <label style="align-self: center;">Columns:</label>
            <input
              ref="table_columns"
              type="number"
              name="table_columns"
              size="5"
              min="1"
              max="99"
              @keydown.esc.prevent="toggle_dialog(TABLE_DIALOG, true)"
            >
            <br>
            <label style="align-self: center;">Add Header Row:</label>
            <input
              ref="table_header"
              type="checkbox"
              name="table_header"
              @keydown.esc.prevent="toggle_dialog(TABLE_DIALOG, true)"
            >
            <br>
          </div>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="add_table()"
            @keydown.esc="toggle_dialog(TABLE_DIALOG, true)"
          >
            <SvgImage pathname="save" />
          </i>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="toggle_dialog(TABLE_DIALOG, true)"
            @keydown.esc="toggle_dialog(TABLE_DIALOG, true)"
          >
            <SvgImage pathname="cancel" />
          </i>
        </div>
      </div>
      <div
        v-show="active_dialog === LINK_DIALOG"
        class="menu_dialog"
      >
        <h2>Add/Edit Link</h2>
        <div class="link_dialog_content">
          <label style="align-self: center;">URL:</label>
          <div>
            <input
              ref="link_url"
              type="text"
              name="link_url"
              size="40"
              @keydown.enter.prevent="add_edit_link()"
              @keydown.esc.prevent="toggle_dialog(LINK_DIALOG, true)"
            >
            <br>
            <br>
            <em>e.g. https://, http://, mailto:, tel:, #anchor</em>
          </div>
          <label style="align-self: center;">Target:</label>
          <div>
            <select
              ref="link_target"
              name="link_target"
              @keydown.prevent.enter="add_edit_link()"
              @keydown.esc="toggle_dialog(LINK_DIALOG, true)"
            >
              <option value="">
                not set (Default)
              </option>
              <option value="_blank">
                _blank (New Window/Tab)
              </option>
              <option value="_self">
                _self (Same Window/Tab)
              </option>
            </select>
          </div>

          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="add_edit_link()"
            @keydown.esc="toggle_dialog(LINK_DIALOG, true)"
          >
            <SvgImage pathname="save" />
          </i>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="toggle_dialog(LINK_DIALOG, true)"
            @keydown.esc="toggle_dialog(LINK_DIALOG, true)"
          >
            <SvgImage pathname="cancel" />
          </i>
        </div>
      </div>

      <div
        v-show="active_dialog === IMAGE_DIALOG"
        class="menu_dialog"
      >
        <div class="image_dialog_content">
          <div style="display: grid; grid-template-columns: 1fr auto; align-items: center; width: 100%;">
            <h2>Upload Image</h2>
            <i
              style="width: 24px; height: 24px; cursor: pointer;"
              @click.prevent="toggle_dialog(IMAGE_DIALOG, true)"
            >
              <SvgImage pathname="cancel" />
            </i>
          </div>
          <div
            class="dropzone"
            :class="{'dropzone_active': dropzone_active}"
            @dragenter.prevent="dropzone_active = true"
            @dragleave.prevent="dropzone_active = false"
            @dragover.prevent="dropzone_active = true"
            @drop.prevent="dropzone_active = false"
          >
            <i style="width: 36px; height: 36px;">
              <SvgImage
                pathname="upload_cloud"
                :width="36"
                :height="36"
              />
            </i>
            <div>
              Drop an image here, directly in the editor, or
              <label>browse
                <input
                  ref="img_file"
                  type="file"
                  name="img_file"
                  style="display: none;"
                  @change="dropzone_file = $event.target.files[0]"
                >
              </label>
            </div>
            <div><em>Supports gif, jpg, png formats</em></div>
          </div>

          <div v-show="dropzone_file">
            <strong>Selected filename:</strong> {{ dropzone_file?.name }}
          </div>
          <div>
            <button
              v-show="dropzone_file"
              class="btn_submit"
              @click.prevent="add_image"
            >
              Send it to the Server
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="active_dialog === SOURCE_DIALOG"
        class="menu_dialog"
      >
        <div class="source_dialog_header">
          <h2>Edit Source</h2>
          <div style="flex-grow: 1;" />
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="update_editor_from_source()"
            @keydown.esc="toggle_dialog(SOURCE_DIALOG, true)"
          >
            <SvgImage pathname="save" />
          </i>
          <i
            style="width: 24px; height: 24px; cursor: pointer;"
            @click.prevent="toggle_dialog(SOURCE_DIALOG, true)"
            @keydown.esc="toggle_dialog(SOURCE_DIALOG, true)"
          >
            <SvgImage pathname="cancel" />
          </i>
        </div>
        <textarea
          ref="hidden_source_textarea"
          class="form_element_text_html"
          style="width: 100%;"
          rows="15"
          cols="80"
          @keydown.esc="toggle_dialog(SOURCE_DIALOG, true)"
        />
      </div>

      <div
        ref="editor_content"
        class="tiptap_content_wrapper richtext_content"
        @wheel="handle_editor_scroll"
        @click="editor_focused()"
        @keyup="toolbar_set_show"
      >
        <div
          ref="announce_skip_toolbar"
          class="sr-only"
        >
          Press Shift + Tab to navigate to the toolbar. Then, use the left and right arrow keys to navigate the toolbar buttons.
        </div>
        <editor-content
          class="tiptap_editor"
          :editor="editor"
        />
      </div>

      <div class="tiptap_footer">
        <template v-if="machinelearning_enabled">
          <div
            title="Add Magic Note"
            @click.prevent="init_qr()"
          >
            <SvgImage
              pathname="magic_note"
              width="20"
              height="20"
              style="margin-right: 5px; margin-bottom: -5px; cursor: pointer; color: #444;"
            />
          </div>
          <div
            v-if="allow_transcription"
            title="Speech to Text"
            @click.prevent="show_speech_modal=true"
          >
            <SvgImage
              pathname="microphone"
              width="16"
              height="16"
              style="margin-right: 5px; margin-bottom: -3px; cursor: pointer; color: #444;"
            />
          </div>
        </template>
        <!-- eslint-disable vue/no-v-html -->
        <div
          style="flex-grow: 0; cursor: default;"
          v-html="breadcrumbs"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div style="flex-grow: 1; text-align: right;">
          LegalServer Rich Text Editor<span style="display: none;"> from Vue3</span>
        </div>
        <div
          ref="editor_resize_handle"
          style="flex-grow: 0; cursor: ns-resize;"
        >
          <SvgImage pathname="triangle" />
        </div>
      </div>
    </div>
    <qr-modal
      v-if="show_qr_modal"
      :url="qr_url"
      :expires="qr_expires"
      :base64_data="qr_base64_data"
      :message="qr_message"
      :complete="qr_complete"
      @close="qr_modal_closed"
    />
    <speech-modal
      v-if="show_speech_modal"
      @close="show_speech_modal=false"
      @add-to-note="add_speech_to_note"
    />
  </div>
</template>

<script lang="ts">

import { Editor, EditorContent } from '@tiptap/vue-3';
import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import {Underline} from "@tiptap/extension-underline";
import ResizableImage from './components/resizable-image';
import { DOMSerializer } from 'prosemirror-model';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import SvgImage from './components/SvgImage.vue';
import QrModal from './components/QrModal.vue';
import SpeechModal from './components/SpeechModal.vue';
import axios from'axios';

export default {
  components: {
    EditorContent,
    SvgImage,
    QrModal,
    SpeechModal
  },

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    links_enabled: {
      type: Boolean,
      default: false
    },
    anchors_enabled: {
      type: Boolean,
      default: false
    },
    highlighter_enabled: {
      type: Boolean,
      default: false
    },
    machinelearning_enabled: {
      type: Boolean,
      default: false
    },
    tables_enabled: {
      type: Boolean,
      default: false
    },
    edit_source_enabled: {
      type: Boolean,
      default: false
    },
    upload_url: {
      type: String,
      default: ''
    }
  },

  emits: ['input', 'update:modelValue'],

  data() {
    return {
      editor: null,
      active_dialog: null,
      dropzone_active: false,
      dropzone_file: null,
      startX: null,
      startY: null,
      startWidth: null,
      startHeight: null,
      breadcrumbs: '',
      // hidden_value is used for non-vue forms, such as LS4 form posts using a hidden textarea
      hidden_value: null,
      show_qr_modal: false,
      show_speech_modal: false,
      qr_token: null,
      qr_url: null,
      qr_expires: null,
      qr_base64_data: null,
      qr_message: null,
      qr_timer: null,
      qr_complete: false,
      toolbar_announced: false,
      toolbar_buttons: [],
      toolbar_index: 0,
      toolbar_show: false
    };
  },

  computed: {
    isDraggable() {
      return this.editor?.state?.selection?.node?.attrs?.isDraggable;
    },
    allow_transcription() {
      return this.machinelearning_enabled;
    }
  },

  created: function() {
    this.ANCHOR_DIALOG                    = 'anchor';
    this.IMAGE_DIALOG                     = 'image';
    this.LINK_DIALOG                      = 'link';
    this.SOURCE_DIALOG                    = 'source';
    this.TABLE_DIALOG                     = 'table';
  },

  mounted: function () {
    const options = {
      extensions: [
        StarterKit,
        Underline
      ],
      onUpdate: () => {
        this.hidden_value = this.post_render(this.editor.getHTML());
        this.$emit('input', this.hidden_value);
        this.$emit('update:modelValue', this.hidden_value);
        this.update_breadcrumbs();
      },
      onSelectionUpdate: () => {
        this.update_breadcrumbs();
      }
    };
    const MsWordPasteHandler = Extension.create({
      name: 'msWordPasteHandler',
      addProseMirrorPlugins() {
        return [
          new Plugin({
            props: {
              transformPastedHTML(html) {
                const parser = new DOMParser()
                const doc = parser.parseFromString(html, 'text/html')

                const msoListParagraphs = doc.querySelectorAll('.MsoListParagraph, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpMiddle, .MsoListParagraphCxSpLast')

                if (msoListParagraphs.length) {
                  let listStack = []
                  let listType = 'ul'

                  msoListParagraphs.forEach(p => {
                    if (p.classList.contains('MsoListParagraphCxSpFirst')) {
                      listStack = []

                      const listMarkerText = p.querySelector('span > span').textContent

                      listType = (/^\d+\.?/.test(listMarkerText)) ? 'ol' : 'ul'
                    }

                    const styleString = p.getAttribute('style')
                    const matches = styleString.match(/mso-list:[^;]*level(\d+)/)
                    const level = parseInt(matches[1], 10)

                    while (level > listStack.length) {
                      const newList = doc.createElement(listType)

                      if (listStack.length > 0) {
                        listStack[listStack.length - 1].appendChild(newList)
                      }
                      listStack.push(newList);
                    }

                    while (level < listStack.length) {
                      listStack.pop()
                    }

                    p.childNodes.forEach(node => {
                      if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
                        const li = doc.createElement('li')

                        li.innerHTML = node.textContent.trim()
                        listStack[listStack.length - 1].appendChild(li)
                        p.parentNode.replaceChild(listStack[0], p)
                      }
                    })
                  })
                }

                return doc.body.innerHTML
              },
            },
          }),
        ]
      },
    });
    // add the MsWordPasteHandler to the extensions
    options.extensions.push(MsWordPasteHandler);

    // we need ResizableImage for anchor images also
    if (this.upload_url || this.links_enabled) {
      options.extensions.push(
        ResizableImage.configure({
          inline: true
        })
      );
    }
    if (this.links_enabled) {
      options.extensions.push(
        Link.configure({
          autolink   : false,
          openOnClick: false,
          protocols  : ['http', 'https', 'sftp', 'ftp', 'mailto', 'tel'],
          HTMLAttributes: {
            rel: null,
            target: null
          }
        })
      );
    }
    if (this.highlighter_enabled) {
      options.extensions.push(
        Highlight.configure({
          multicolor: false,
          HTMLAttributes: {
            class: 'tiptap_mark'
          }
        })
      );
    }
    if (this.tables_enabled) {
      options.extensions.push(
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: 'tiptap-table'
          }
        })
      );
      options.extensions.push(TableHeader);
      options.extensions.push(TableCell);
      options.extensions.push(TableRow);
    }
    this.editor = new Editor(options);
    this.init();
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    init() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.$refs.editor_resize_handle.addEventListener('mousedown', function(evt) {
        self.init_resize_editor(evt);
      }, true);
      this.editor.commands.setContent(this.pre_render(this.modelValue), false);
      this.hidden_value = this.post_render(this.editor.getHTML());
    },
    pre_render(content) {
      if (this.links_enabled && content) {
        // targeting <a id="foo" name="foo"></a>
        content = content.replace(/<a id="([^"]+)"( name="[^"]+")?><\/a>/g, '<img title="#$1" src="/template/ls4/image/anchor.png?tiptap_anchor=$1" width="16" height="16" />');

        // targeting <a name="foo"></a>
        content = content.replace(/<a name="([^"]+)"><\/a>/g, '<img title="#$1" src="/template/ls4/image/anchor.png?tiptap_anchor=$1" width="16" height="16" />');
      }

      return content;
    },
    post_render(content) {
      if (this.links_enabled && content) {
        // note that name="" is deprecated for anchors in favor of only id="" now
        content = content.replace(/<img src="\/template\/ls4\/image\/anchor.png\?tiptap_anchor=(.*?)".*?>/g, "<a id=\"$1\" name=\"$1\"></a>");
      }

      // roughly add formatting - i.e. line breaks
      if (content) {
        // break after element start and end
        content = content.replace(/(<\/?(ul|ol|blockquote|hr)>)/g, "$1\n");

        // break after element end only
        content = content.replace(/(<\/(h[0-9]|li)>)/g, "$1\n");

        // indent before element start only
        content = content.replace(/(<li>)/g, "\t$1");

        // break before element start only
        content = content.replace(/(<(ul|ol)>)/g, "\n$1");

        // break before element end only
        content = content.replace(/(<(blockquote)>)/g, "\n$1");

        // break when together
        content = content.replace(/<\/p>(<p>)/g, "</p>\n$1");
      }

      return content;
    },
    init_resize_editor(e) {
      if (!e) {
        return;
      }
      this.startX = e.clientX;
      this.startY = e.clientY;
      this.startWidth = parseInt(
        document.defaultView.getComputedStyle(this.$refs.editor_content).width,
        10
      );
      this.startHeight = parseInt(
        document.defaultView.getComputedStyle(this.$refs.editor_content).height,
        10
      );

      document.documentElement.addEventListener("mousemove", this.resize_editor, false);

      document.documentElement.addEventListener("mouseup", this.stop_resizing_editor, false);
    },
    resize_editor(e) {
      this.$refs.editor_content.style.height = this.startHeight + e.clientY - this.startY + "px";
      this.$refs.editor_content.style.minHeight = '0px';
      this.$refs.editor_content.style.maxHeight = '100vh';
    },
    stop_resizing_editor() {

      document.documentElement.removeEventListener("mousemove", this.resize_editor, false);

      document.documentElement.removeEventListener("mouseup", this.stop_resizing_editor, false);
    },

    toggleResize() {
      this.editor.chain().focus().toggleResizable().run();
    },
    add_image() {
      if (!this.dropzone_file) {
        return;
      }

      if (!this.dropzone_file?.name.toLowerCase().match(/\.(je?pg|gif|png)$/)) {
        alert("Invalid file: only image formats can be inserted here");
        return;
      }

      const formData = new FormData();
      formData.append("upload", this.dropzone_file);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      self.$refs.editor_content.style.cursor = 'wait';
      axios.post('/modules/document/upload_rte_image.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          try {
            if (response.data.uploaded > 0) {
              self.editor.commands.insertContent(`<img src="${response.data.url}" />`);
              self.dropzone_file = null;
              self.$refs.editor_content.style.removeProperty('cursor');
              self.toggle_dialog(self.IMAGE_DIALOG, true);
            } else {

              console.log(response.data.error);
              alert("Image upload failed");
              self.$refs.editor_content.style.removeProperty('cursor');
            }
          } catch (e) {

            console.log(e);
            self.$refs.editor_content.style.removeProperty('cursor');
          }
        })
        .catch(function (error) {

          console.log(error);
          self.$refs.editor_content.style.removeProperty('cursor');
        });
    },
    update_breadcrumbs() {
      try {
        const serializer = DOMSerializer.fromSchema(this.editor.schema);
        const { $from } = this.editor.state.selection;
        let depth = $from.depth;
        const link = this.editor?.getAttributes('link')?.href;
        const breadcrumbs = [];

        let anchor_name = null;
        if (this.editor?.state?.selection?.node?.type?.name === 'ResizableImage') {
          if (!this.links_enabled) {
            breadcrumbs.push('img');
          } else {
            const { selection } = this.editor.state;
            const { node } = selection;
            if (node) {
              const { attrs } = node;
              if (attrs && attrs['src']) {
                const matches = attrs.src.match(/tiptap_anchor=(.*)/);
                if (matches) {
                  anchor_name = matches[1];
                }
              }
            }

            if (anchor_name) {
              breadcrumbs.push(`anchor #${anchor_name}`);
            } else {
              breadcrumbs.push('img');
            }
          }
        }

        // show the actual link, unless it is too long, then have them hover to see the full url
        if (this.links_enabled && link) {
          let target_display = '';
          const target = this.editor?.getAttributes('link')?.target;
          if (target) {
            target_display = ` (${target}) `;
          }
          const url_display = link.substring(0, 60);
          target_display += `${url_display}`;
          if (link !== url_display) {
            target_display += "...";
          }

          breadcrumbs.push(`<span title='href: ${link}'>a - ${target_display}</span>`);
        }

        while (depth > 0) {
          const node = $from.node(depth);
          breadcrumbs.push(serializer.serializeNode(node).nodeName.toLowerCase());
          depth--;
        }
        breadcrumbs.push('body');
        this.breadcrumbs = breadcrumbs.reverse().join(' &raquo; ');
      } catch (e) {

        console.log(e);
      }
    },
    toggle_dialog(new_dialog, force_close = false) {
      if (force_close || this.active_dialog === new_dialog) {
        this.active_dialog = null;
        this.editor.chain().focus().run();
        this.update_breadcrumbs();

      } else {
        if (!this.links_enabled
          && [this.LINK_DIALOG, this.ANCHOR_DIALOG].indexOf(new_dialog) !== -1
        ){
          return;
        }

        if (!this.edit_source_enabled
          && this.SOURCE_DIALOG === new_dialog
        ){
          return;
        }
        this.active_dialog = new_dialog;
      }

      if (new_dialog === this.IMAGE_DIALOG) {
        this.dropzone_file = null;

      } else if (this.active_dialog === this.LINK_DIALOG) {
        const link = this.editor?.getAttributes('link')?.href;
        const target = this.editor?.getAttributes('link')?.target;
        if (link) {
          this.editor.commands.selectParentNode();
        }
        this.$refs.link_url.value = link || '';
        this.$refs.link_target.value = target || '';
        setTimeout(()=>{
          this.$refs.link_url.focus();
          this.$refs.link_url.select();
        }, 50);

      } else if (this.active_dialog === this.ANCHOR_DIALOG) {
        this.$refs.anchor_name.value = '';
        setTimeout(()=>{
          this.$refs.anchor_name.focus();
          this.$refs.anchor_name.select();
        }, 50);

      } else if (this.active_dialog === this.SOURCE_DIALOG) {
        setTimeout(()=>{
          this.$refs.hidden_source_textarea.value = this.post_render(this.editor.getHTML());
          this.$refs.hidden_source_textarea.focus();
          try {
            this.$refs.hidden_source_textarea.scrollTop = 0;
            this.$refs.hidden_source_textarea.selectionEnd = 0;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch(e){
            // intentional no-op
          }
        }, 50);
      }
    },
    add_edit_link() {
      if (!this.links_enabled) {
        return;
      }
      let link = '';
      try {

        link = this.$refs.link_url.value.trim().replace(/[^a-zA-Z0-9\/&=?:.#]/g, '');
      } catch (e) {

        console.log(e);
        link = '';
      }
      if (link !== '') {
        if (!link.match(/^(https?|s?ftp|mailto|tel|#)/i)) {
          link = link.replace(/.*:\/\//, '');
          link = `https://${link}`;
        }
        this.editor.commands.setLink({ href: link, target: this.$refs.link_target.value });
      }
      this.toggle_dialog(this.LINK_DIALOG, true);
    },
    unlink() {
      if (this.editor?.getAttributes('link')?.href) {
        this.editor.commands.unsetLink();
      }
    },
    add_table() {
      if (!this.tables_enabled) {
        return;
      }

      const rows = this.$refs.table_rows.value;
      const columns = this.$refs.table_columns.value;
      const header = this.$refs.table_header.checked;

      if (rows && columns) {
        this.editor.commands.insertTable({rows: rows, cols: columns, withHeaderRow: header});
      }

      this.toggle_dialog(this.TABLE_DIALOG, true);
      this.editor.chain().focus().run();
      this.update_breadcrumbs();
    },
    add_anchor() {
      if (!this.anchors_enabled) {
        return;
      }

      let name = this.$refs.anchor_name.value.trim();
      if (name && name !== "") {
        name = name.replace(/[^a-zA-Z0-9_-]/g, '');
        this.editor.commands.insertContent(`<img title="#${name}" src="/template/ls4/image/anchor.png?tiptap_anchor=${name}" width="16" height="16" />`);
      }

      this.toggle_dialog(this.ANCHOR_DIALOG, true);
      this.editor.chain().focus().run();
      this.update_breadcrumbs();
    },
    update_editor_from_source() {
      if (this.active_dialog === this.SOURCE_DIALOG) {
        this.editor.commands.setContent(this.pre_render(this.$refs.hidden_source_textarea.value), false);
        this.hidden_value = this.post_render(this.editor.getHTML());
        this.$emit('input', this.hidden_value);
        this.$emit('update:modelValue', this.hidden_value);
      }
      this.active_dialog = null;
    },
    editor_focused() {
      this.active_dialog = null;
    },
    handle_editor_scroll(evt) {
      // prevent window scrolling if the user is using mouse wheel on the editor and has reached top or bottom
      if (evt.deltaY > 0 // scrolling down
        && this.$refs.editor_content.offsetHeight + this.$refs.editor_content.scrollTop >= this.$refs.editor_content.scrollHeight
      ) {
        evt.preventDefault();
      } else if (evt.deltaY < 0 // scrolling up
        && this.$refs.editor_content.scrollTop <= 0
      ) {
        evt.preventDefault();
      }
    },
    handle_image_drop(evt) {
      if (this.upload_url) {
        if (evt && evt['dataTransfer'] && evt.dataTransfer['files']) {
          this.dropzone_file = evt.dataTransfer.files[0];
          this.add_image();
        }
      }
    },
    handle_image_paste(evt) {
      try {
        if (this.upload_url) {
          if (evt['clipboardData'] && evt.clipboardData['files']) {
            this.dropzone_file = evt.clipboardData.files[0];
            this.add_image();
          }
        }
      } catch(e) {

        console.log(e);
      }
    },
    qr_modal_closed() {
      if (this.qr_timer) {
        window.clearTimeout(this.qr_timer);
        this.qr_timer = null;
      }
      this.show_qr_modal = false;
      this.qr_token = '';
      this.qr_url = '';
      this.qr_expires = '';
      this.qr_base64_data = '';
      this.qr_message = '';
      this.qr_complete = false;
    },
    add_speech_to_note(text) {
      if (text) {
        this.editor.commands.insertContent(text);
      }
      this.show_speech_modal = false;
    },
    init_qr() {
      try {
        this.qr_token = '';
        this.qr_url = '';
        this.qr_expires = '';
        this.qr_base64_data = '';
        this.qr_message = '';
        this.qr_complete = false;
        this.show_qr_modal = false;

        this.qr_timer = window.setInterval(this.check_magic_note, 5000);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        axios.get('/api/v1/app/tiptap/qr_code', {
        })
          .then(function (response) {
            // confirm successful response then store new qr data
            if (response.data && response.data.base64_data) {
              self.qr_token = response.data.token;
              self.qr_url = response.data.url;
              self.qr_expires = response.data.expires;
              self.qr_base64_data = response.data.base64_data;
              self.show_qr_modal = true;
            } else {

              console.log(response.data);
            }
          })
          .catch(function (error) {

            console.log(error);
          });

      } catch(e) {

        console.log(e);
      }
    },
    check_magic_note() {
      // console.log('checking for magic note');
      try {
        if (!this.show_qr_modal) {
          window.clearTimeout(this.qr_timer);
          this.qr_timer = null;
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        axios.get('/api/v1/app/tiptap/magic_note', {
          params: {
            t: self.qr_token
          }
        })
          .then(function (response) {
            if (response.data && response.data.note_status) {

              // the token is still valid but the user has not uploaded a file
              if (response.data.note_status === 'ready') {
                return;

              // the job is done and the note needs added to the editor
              } else if (response.data.note_status === 'complete' && response.data.note_text) {
                self.editor.commands.insertContent(self.format_magic_note(response.data.note_text));
                self.qr_complete = true;
                self.qr_message = 'Success!  Updating editor...';

                window.setTimeout(self.qr_modal_closed, 2000);

              // the user has uploaded a document but it isn't finished processing; update the modal with info
              } else if (response.data.note_status === 'processing') {
                self.qr_message = 'Processing, please wait...';

              // the token has expired or is invalid, close the modal and reset the timer
              } else if (response.data.note_status === 'expired' || response.data.note_status === 'invalid') {
                self.qr_modal_closed();
              }
            }
          })
          .catch(function (error) {

            console.log(error);
          });

      } catch(e) {

        console.log(e);
      }
    },
    format_magic_note(text) {
      // First, replace double line breaks with paragraph tags
      let formatted = text.replace(/\n\n+/g, '</p><p>');

      // Then, replace single line breaks with <br> tags
      formatted = formatted.replace(/\n/g, '<br>');

      // Wrap the entire text in paragraph tags if it's not already
      if (!formatted.startsWith('<p>')) {
        formatted = '<p>' + formatted;
      }
      if (!formatted.endsWith('</p>')) {
        formatted += '</p>';
      }

      return formatted;
    },
    announce_skip_toolbar() {
      if (this.toolbar_announced === false) {
        this.toolbar_announced = true;
        window.srAnnounce(this.$refs.announce_skip_toolbar.textContent);
      }
    },
    insert_toolbar_button(button) {
      if (button && button.title && !this.toolbar_buttons.find(b => b.title === button.title)) {
        this.toolbar_buttons.push(button);
      }
    },
    toolbar_next() {
      if (this.toolbar_index < this.toolbar_buttons.length - 1) {
        this.toolbar_index++;
        this.toolbar_buttons[this.toolbar_index].focus();
      }
    },
    toolbar_previous() {
      if (this.toolbar_index > 0) {
        this.toolbar_index--;
        this.toolbar_buttons[this.toolbar_index].focus();
      }
    },
    toolbar_reset() {
      this.toolbar_index = 0;
    },
    toolbar_set_show() {
      this.toolbar_show = true;
      this.announce_skip_toolbar();
    }
  }
}
</script>

<!-- structure -->
<style scoped>
.tiptap_wrapper{
  border: 1px solid #adadad;
  border-radius: 7px;
  color: black;
  display: block;
}
.tiptap_menu{
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f5f5f5;
  padding: .1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #adadad;
}

.tiptap_menu button{
  width: 30px;
  height: 30px;
  color: rgba(0,0,0,0.54);
  border: none !important;
  background: none !important;
  padding: .5rem;
  margin: .25rem .1rem;
  display: inline-flex;
  justify-content: center;
}

.tiptap_menu button.is-active, .tiptap_menu button:hover {
  background-color: lightslategray !important;
  color: #FFFFFF !important;
}

.tiptap_menu svg{
  background-color: transparent;
  margin-top: -6px;
  fill: currentColor;
}

.tiptap_menu em {
  font-style: italic;
}

.tiptap_divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(0,0,0,.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  display: inline-block;
}

.tiptap_footer{
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #adadad;
  background-color: #f5f5f5;
  color: rgba(0,0,0,0.54);
  padding: .1rem;
  padding-left: .75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.menu-item-hidden {
  visibility: hidden;
}

.menu_dialog {
  background-color: #f5f5f5;
  padding: 1rem;
  border-bottom: 1px solid #adadad;
  margin-bottom: 1rem;
}

.menu_dialog h2 {
  margin-bottom: .5rem;
}

.link_dialog_content {
  display: flex;
  align-items: flex-start;
  gap: .75rem;
}

.anchor_dialog_content {
  display: flex;
  align-items: flex-start;
  gap: .75rem;
}

.tiptap_mark {
  background-color: #ffe066;
  padding: 0.125em 0;
  border-radius: 0.25em;
  box-decoration-break: clone;
}

.image_dialog_content {
  max-width: 200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: .5rem;
  justify-content: center;
}

.image_dialog_content label {
  font-weight: bold;
  text-decoration: underline;
  color: #2B8AC0;
}

.image_dialog_content em {
  font-style: italic !important;
  color: rgb(144, 146, 150);
}

.dropzone {
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 2px dashed rgb(182, 185, 191);
  border-radius: 10px;
  background-color: #ffffff;
}

.dropzone i {
  color: rgb(144, 146, 150);
}

.dropzone_active {
  background-color: rgb(214 233 255);
  border: 2px solid rgb(214 233 255);
}

.dropzone_active i {
  color: rgb(70, 115, 228);
}

.source_dialog_header {
  display: flex;
  max-width: 250px;
  gap: .75rem;
  margin-bottom: .5rem;
}
</style>

<!-- rich text content -->
<style>
.tiptap_content_wrapper {
  min-height: 150px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.tiptap_editor {
  margin: 0;
  padding: 0 .75rem;
  font-size: .8rem;
  line-height: 1.2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tiptap_editor .ProseMirror {
  flex: 1;
}

.tiptap_editor .ProseMirror:focus-visible {
  outline: none !important;
}

.richtext_content em{
  font-style: italic !important;
}
.richtext_content strong{
  font-weight: bold !important;
}
.richtext_content ul{
  list-style: disc;
  margin: .25rem 0;
  margin-left: 2rem;
}
.richtext_content ol{
  list-style: auto;
  margin: .25rem 0;
  margin-left: 2rem;
}
.richtext_content pre{
  margin: .25rem 0;
  background-color: lightgray;
}
.richtext_content p{
  margin: .5rem 0;
}
.richtext_content li p{
  margin: 0;
}
.richtext_content h1{
  margin: 1rem 0 .75rem 0;
}
.richtext_content h2{
  margin: .75rem 0 .5rem 0;
}
.richtext_content blockquote{
  margin: 1rem 2rem;
}
.richtext_content code{
  font-family: monospace;
}
.richtext_content sub{
  vertical-align: sub;
  font-size: smaller;
}
.richtext_content sup{
  vertical-align: super;
  font-size: smaller;
}
</style>
<!-- Table Styling -->
<style lang="scss" scoped>
:deep(.tiptap) {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
</style>
