<template>
  <div>
    <div class="form-row">
      <label>Short name:</label>
      <input
        v-model="short_name"
        type="text"
        name="short_name"
        @change="change"
      >
    </div>
    <div class="form-row top-align">
      <label>Type:</label>
      <select
        v-model="type"
        name="type"
        @change="change"
      >
        <option value="">
          Choose type...
        </option>
        <option value="bool">
          bool
        </option>
        <option value="date">
          date
        </option>
        <option value="number">
          number
        </option>
        <option value="percent">
          percent
        </option>
        <option value="text">
          text
        </option>
        <option value="textarea">
          textarea
        </option>
        <option value="zip">
          zip
        </option>
        <option value="lookup">
          system lookup
        </option>
        <option value="lookup_custom">
          existing custom lookup
        </option>
        <option value="new_lookup_custom">
          new custom lookup
        </option>
      </select>
    </div>
    <div
      v-if="type === 'lookup' || type === 'lookup_custom'"
      class="form-row"
    >
      <label>Lookup:</label>
      <Multiselect
        v-if="lookupOptions.length > 0"
        v-model="selectedLookup"
        :placeholder="'Choose lookup...'"
        :options="lookupOptions"
        track-by="id"
        label="text"
        @select="change"
        @remove="change"
      />
      <i
        v-else
        class="fa fa-refresh fa-spin"
      />
    </div>
    <custom-lookup-editor
      v-if="type === 'new_lookup_custom'"
      v-model="newCustomLookup"
      @change="change"
    />
    <div
      v-if="type === 'lookup' || type === 'lookup_custom' || type === 'new_lookup_custom'"
      class="form-row"
    >
      <div>
        <label>Multiple Selections Allowed:</label>
      </div>
      <div>
        <input
          v-model="multi_select"
          type="checkbox"
          name="multi_select"
          @change="change"
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
 
import { ajaxGetFormMarker, ajaxRequest} from '@networkninja/common';
import Multiselect from "vue-multiselect";
import CustomLookupEditor from './custom_lookup_editor.vue';
export default {
  components: {
    'custom-lookup-editor': CustomLookupEditor,
    Multiselect
  },
  props: {
    appState: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['input', 'update:modelValue'],
  data: function() {
    return {
      short_name: null,
      type: '',
      lookup: null,
      lookupOptions: [],
      newCustomLookup: {},
      multi_select: false,
      selectedLookup: null
    };
  },
  watch: {
    type: function(newValue) {
      this.newCustomLookup = {};
      this.lookup = '';
      if (newValue === 'lookup' || newValue === 'lookup_custom') {
        this.lookupOptions = [];
        this.$nextTick(() => {
          const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
          ajaxRequest(
            [newValue === 'lookup_custom'],
            formName,
            hidden,
            'get_lookup_list',
            this.appState.root )
            .then(responseData => {
              this.lookupOptions = responseData;
            });
        });
      }
    }
  },
  methods: {
    change: function() {
      const toEmit = Object.assign({}, this.$data);
      this.lookup = this.selectedLookup ? this.selectedLookup.id : null;
      this.$emit('input', toEmit);
      this.$emit('update:modelValue', toEmit);
    }
  }
};
</script>
