<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label(title="Field to render in dialogue form") Field to populate:
            span.form-required *
        div(:class="{ 'form-error-border': errors['field'] }")
            field-selector(v-model="details.field", :appState="appState", @change="change", :removeDisplayOnly="false")
    div.form-row(v-show="!expanded && collapseSummary !== ''")
        div(class="fieldSetValueCollapseSummary" v-html="collapseSummary")
    div.form-row(v-show="expanded")
        div: label(title="Set a default value for this field") Value:
            span.form-required *
        div(v-if="!selectedField") Please select a field to populate
        div(v-if="selectedField")
            field-value-selector(
                :field="selectedField",
                v-model="details.value",
                @change="change",
                @mounted="valueSelectorReady = true",
                ref="valueSelector",
                :class="{ 'form-error-border': errors['value'] }"
            )
</template>

<script lang="ts">
import InternalsBase from './internals_base.vue';
import FieldSelector from './field_selector.vue';
import FieldValueSelector from './field_value_selector.vue';
export default {
  components: {
    'field-value-selector': FieldValueSelector,
    'field-selector': FieldSelector
  },
  extends: InternalsBase,
  data: function() {
    return {
      valueSelectorReady: false
    };
  },
  computed: {
    selectedField: function() {
      return this.appState.matterFieldList.find((item) => { return item.lookup_name === this.details.field; });
    },
    collapseSummary: function() {
      if (this.details.field && this.details.value && this.valueSelectorReady) {
        const fieldName = this.appState.matterFieldList.find((item) => item.lookup_name === this.details.field).short_name;
        let retval = 'Set <span class="field">' + fieldName + '</span> to <span class="value">';
        if (this.$refs.valueSelector.controlType === 'select') {
          const selectedId = parseInt(this.details.value);
          const selectedOption = this.$refs.valueSelector.lookupOptions.find((item) => item.id === selectedId);
          if (selectedOption) {
            retval += selectedOption.text;
          }
        } else if (this.$refs.valueSelector.controlType === 'yesno') {
          retval += this.details.value ===  't' ? 'Yes' : 'No';
        } else {
          retval += this.details.value;
        }
        retval += '</span>';
        return retval;
      } else {
        return '';
      }
    }
  },
  created: function() {
    if (this.details.value === null) {
      this.details.value = '';
    }
    if (this.details.field === null) {
      this.details.field = '';
    }
  },
  detailsTemplate: {
    field: null,
    value: null
  },
  methods: {
    detailsWatchHandler: function() {
      if (this.details.field !== this.previousDetails.field) {
        this.details.value = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
    .fieldSetValueCollapseSummary {
        .field, .value {
            background:rgba(250, 240, 210, 0.5);
            border:1px solid black;
            padding:3px;
        }
    }
</style>
