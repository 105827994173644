<template>
  <div
    v-if="isVisible"
    class="modal-wrapper"
  >
    <div class="modal-content">
      <i
        class="close-button"
        title="Close/Cancel"
        @click="emit('close')"
      >
        <SvgImage pathname="cancel" />
      </i>
      <div
        style="margin-top: 20px;"
      >
        <p v-if="!message || message == ''">
          Use your mobile device to scan the code below, or click the link to open a new tab in the current browser. This dialog will automatically close when processing is complete.
        </p>
        <p v-else>
          {{ message }}
        </p>
      </div>
      <div>
        <div class="qr-container">
          <transition name="fade">
            <img
              v-if="!showCheckmark"
              :src="img_src"
              alt="QR Code"
            >
            <SvgImage
              v-else
              class="checkmark"
              pathname="success_checkmark"
            />
          </transition>
        </div>
        <div v-show="!showCheckmark">
          <a
            :href="url"
            target="_blank"
          >Open in new tab</a>
        </div>
        <div
          v-show="!showCheckmark"
          class="countdown"
        >
          Expires in {{ formattedTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
 
import { ref, watchEffect, computed, watch } from 'vue';
import SvgImage                       from "./SvgImage.vue";

// Props
const props = defineProps({
  url: {
    type: String,
    required: true,
    default: '',
  },
  expires: {
    type: Number,
    required: true,
    default: 0,
  },
  base64_data: {
    type: String,
    required: true,
    default: '',
  },
  message: {
    type: String,
    required: false,
    default: null,
  },
  complete: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['close']);

// State
const isVisible = ref(true);
const currentTime = ref(Date.now());
const countdown = ref(props.expires - Math.floor(currentTime.value / 1000));

// Timer and countdown calculations
watchEffect(() => {
  const interval = setInterval(() => {
    currentTime.value = Date.now();
    countdown.value = props.expires - Math.floor(currentTime.value / 1000);

    if (countdown.value <= 0) {
      clearInterval(interval);
      closeModal(); // Close modal automatically when countdown ends
    }
  }, 1000);

  // Clear interval on unmount
  return () => clearInterval(interval);
});

// Format the countdown time
const formattedTime = computed(() => {
  const minutes = Math.floor(countdown.value / 60);
  const seconds = countdown.value % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
});

const img_src = `data:image/png;base64,${props.base64_data}`;

const showCheckmark = ref(false);

watch(() => props.complete, (newValue) => {
  if (newValue) {
    showCheckmark.value = true;
  }
});
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 375px;
  position: relative;
  font-size: 16px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.countdown {
  margin-top: 15px;
  font-weight: bold;
}

.qr-container {
  width: 200px; /* Adjust based on your QR code size */
  height: 200px; /* Adjust based on your QR code size */
  margin: 0 auto;
  position: relative;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>