<template>
  <div class="form-row">
    <div>
      <label class="mr-2">
        {{ label }}:
      </label>
    </div>
    <div>
      <div
        v-for="(translation) in translations"
        :key="translation.id"
        class="display-flex align-items-center pb-2"
      >
        <select
          v-model="translation.system_language_id"
          class="mr-2"
          @change="serialize"
        >
          <option value="________NO_SELECTION__________">
            Please select a language...
          </option>
          <option
            v-for="opt in appState.systemLanguages"
            :key="opt.id"
            :value="opt.id"
          >
            {{ opt.name }}
          </option>
        </select>
        <input
          v-if="elementType === 'headline' || elementType === 'field'"
          v-model="translation.text"
          class="mr-1"
          type="text"
          @change="serialize"
        >
        <div class="mr-2">
          <tiptap
            v-if="elementType === 'instructions'"
            v-model="translation.text"
            :links_enabled="true"
            :edit_source_enabled="true"
            @update:model-value="serialize"
          />
        </div>
        <button
          type="button"
          class="btn_bare btn_danger"
          @click="remove(translation.id)"
        >
          <i class="fa fa-times" />
        </button>
      </div>
      <button
        type="button"
        @click="add_translation"
      >
        Add translation
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import TipTap from '../../../../tiptap/src/app.vue';

export default {
  components: {
    'tiptap': TipTap,
  },
  props: {
    appState: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    elementType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'Translations'
    }
  },
  emits: ['input', 'update:modelValue'],
  data() {
    return {
      translations: [],
      rich_text_engine: this.appState.rte_engine
    };
  },
  watch: {
    translations() {
      this.serialize();
    }
  },
  created() {
    if (this.modelValue) {
      this.translations = this.modelValue;
      this.translations.forEach((item) => {
        if (!item.editorMode) {
          item.editorMode = 'wysiwyg';
        }
      });
    }
  },
  methods: {
    add_translation() {
      this.translations.push({
        system_language_id: '________NO_SELECTION__________',
        text: null,
        editorMode: 'wysiwyg',
        id: uuidv4()
      });
    },
    remove(id) {
      this.translations = this.translations.filter(t => t.id !== id);
      this.$emit('update:modelValue', this.translations);
    },
    serialize() {
      this.$emit('input', this.translations);
      this.$emit('update:modelValue', this.translations);
    },
  }
};
</script>

<style scoped lang="scss">
    .form-row {
        width: 100% !important;
        >div:first-child {
            flex: inherit !important;
            width: 135px;
        }
    }
</style>

