<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Note text:
            span.form-required *
        div(:class="{ 'form-error-border': errors['summaryText'] }")
            tiptap(v-model="details.summaryText",
                         v-if="showEditor")
</template>
<script lang="ts">
import TipTap from '../../../../tiptap/src/app.vue';
import InternalsBase from './internals_base.vue';

export default {
  components: {
    'tiptap': TipTap,
  },
  extends: InternalsBase,
  detailsTemplate: {
    summaryText: ''
  },
  data: function() {
    return {
      showEditor: true,
      rich_text_engine: this.appState.rte_engine
    };
  }
};
</script>
