<template>
  <!--eslint-disable vue/no-v-html-->
  <div style="width:100%">
    <h2>Search for elements within this dialogue</h2>
    <input
      v-model="search"
      placeholder="Enter search text"
    >
    <table
      v-if="results && results.length > 0"
      class="hdr"
    >
      <td>
        <div :style="{ width: widths[0] }">
          Segment
        </div>
      </td>
      <td>
        <div :style="{ width: widths[1] }">
          Element type
        </div>
      </td>
      <td>
        <div :style="{ width: widths[2] }">
          Match
        </div>
      </td>
      <td>
        <div :style="{ width: widths[3] }" />
      </td>
    </table>
    <div
      ref="resultsWrapper"
      class="resultsWrapper"
    >
      <div
        v-if="(search && search.length > 0) && (!results || results.length === 0)"
        style="padding: 6px 0"
      >
        No results
      </div>
      <table
        v-if="results && results.length > 0"
        class="results"
      >
        <tr
          v-for="item in results"
          :key="item.element.id"
        >
          <td>
            <div :style="{ width: widths[0] }">
              {{ item.segment.name }}
            </div>
          </td>
          <td>
            <div :style="{ width: widths[1] }">
              {{ fieldTypeLabels[item.element.elementType] }}
            </div>
          </td>
          <td>
            <div
              :style="{ width: widths[2] }"
              v-html="item.label"
            />
          </td>
          <td>
            <div :style="{ width: widths[3] }">
              <router-link :to="{ name: 'segment-edit-element', params: { id: item.segment.id, element: item.element.id } }">
                Go
              </router-link>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    appState: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      search: '',
      results: [],
      widths: [
        '20px',
        '20px',
        '20px',
        '20px'
      ],
      fieldTypeLabels: {
        'instructions': 'Instructions',
        'headline': 'Headline',
        'field': 'Field',
        'block': 'Dynamic Form Block',
        'noteForSummary': 'Add summary note',
        'blockDefault': 'Set block default',
        'document': 'Generate document',
        'caseNote': 'Add case note',
        'apiCall': 'Call an API',
        'fieldSetValueAction': 'Set value of field'
      }
    };
  },
  watch: {
    search: function(newValue) {
      if (newValue && newValue.length > 0) {
        const regex = new RegExp('.*' + newValue + '.*', 'i');
        const results = [];
        this.appState.segments.forEach((seg) => {
          seg.elements.forEach((el) => {
            if (el.elementType === 'field' || el.elementType === 'fieldSetValueAction') {
              const field = this.appState.matterFieldList.find((f) => f.lookup_name === el.details.field);
              if (regex.test(field.short_name)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const elementTypeLabel = el.elementType === 'field' ? 'field' : 'set value for field';
                results.push({
                  label: field.short_name,
                  segment: seg,
                  element: el
                });
              }
            } else if (el.elementType === 'headline' || el.elementType === 'instructions') {
              const toTest = el.elementType === 'headline' ? el.details.headlineText : el.details.instructionsText;
              if (regex.test(toTest)) {
                results.push({
                  label: toTest,
                  segment: seg,
                  element: el
                });
              }
            }
          });
        });
        results.forEach((r) => {
          r.label = r.label.replace(new RegExp(newValue, 'g'), '<span style="color:red">' + newValue + '</span>');
        });
        this.results = results;
      } else {
        this.results = [];
      }
    }
  },
  mounted: function() {
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.$refs.resultsWrapper.style.maxHeight = (viewportHeight - 236) + 'px';

    const rowWidth = this.$refs.resultsWrapper.getBoundingClientRect().width - 56;
    this.widths[0] = (rowWidth * 0.1) + 'px';
    this.widths[1] = (rowWidth * 0.2) + 'px';
    this.widths[2] = (rowWidth * 0.6) + 'px';
    this.widths[3] = (rowWidth * 0.1 - 32) + 'px';
  }
};
</script>

<style scoped lang="scss">
    * {
        box-sizing: border-box;
    }
    h2 {
        margin-bottom: 6px;
    }
    input {
        width: 100%;
        font-size: 16px;
        padding: 3px;
    }
    .resultsWrapper {
        overflow-y: auto;
        padding-right: 20px;
        width: 100%;
    }
    table.hdr {
        font-weight: bold;
        text-decoration: underline;
    }
    td {
        >div {
            padding: 6px;
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
    table.results {
        tr {
            &:hover {
                background: #c9c9c9;
            }
        }
    }
</style>

