<template>
  <div class="guided_navigation_segment">
    <template v-if="isReady">
      <h3
        v-if="!isValid"
        class="error"
      >
        Please fix the issues below.
      </h3>
      <template v-if="hasWarnings">
        <h3 class="error">
          Warning
        </h3>
        <ul>
          <li
            v-for="(warnText, elementIndex) in warningTexts"
            :key="elementIndex"
            class="error"
          >
            {{ warnText }}
          </li>
        </ul>
      </template>
      <div class="hdr">
        <h2 v-if="appState.mode === 'interactive'">
          <template v-if="isNew">
            Add new guided navigation segment
          </template>
          <template v-else>
            Configure guided navigation segment
          </template>
        </h2>
        <h2 v-if="appState.mode === 'navigation'">
          Set navigation expressions and default
        </h2>
        <div v-if="appState.mode !== 'standalone'">
          <button
            type="button"
            @click="createField"
          >
            Create field
          </button>
        </div>
      </div>
      <div
        v-if="appState.mode === 'interactive'"
        class="form-row"
      >
        <div><label>Segment name:<span class="form-required">*</span></label></div>
        <div>
          <input
            v-model="name"
            :class="{ 'form-error': errors['name'] }"
            type="text"
            placeholder="Enter segment name"
          >
        </div>
      </div>
      <div
        v-if="appState.enableLegalIssueSegment"
        class="form-row"
      >
        <div><label>Segment type:<span class="form-required">*</span></label></div>
        <div>
          <select v-model="segmentType">
            <option value="standard">
              Standard
            </option>
            <option value="legal_issue">
              Legal Issues Evaluator
            </option>
          </select>
        </div>
      </div>
      <template v-if="segmentType === 'standard' && (appState.mode === 'interactive' || appState.mode === 'standalone')">
        <h3 title="Controls and text items to display">
          {{ elementsHeadline }}
        </h3>
        <div class="elements">
          <div
            v-if="elements && elements.length > 0"
            class="pb-2"
          >
            <button
              type="button"
              class="mr-1"
              @click="toggleElements('collapsed')"
            >
              Collapse all
            </button>
            <button
              type="button"
              @click="toggleElements('expanded')"
            >
              Expand all
            </button>
          </div>
          <Container
            drag-handle-selector=".handle"
            @drop="elementDraggableOnEnd"
          >
            <Draggable
              v-for="(element, elementIndex) in elements"
              :key="element.id"
            >
              <segment-element
                :ref="el => elementComponents.push(el)"
                v-model="elements[elementIndex]"
                :app-state="appState"
                :other-segments="otherSegments"
                @update:model-value="updateElement(elements[elementIndex], $event)"
                @validation="childCollectionItemValidated(elementIndex, $event, 'element')"
                @warnings-checked="childCollectionItemWarningsChecked(elementIndex, $event, 'element')"
                @create-segment="createSegment"
                @remove="removeElement($event)"
              />
            </Draggable>
          </Container>
          <button
            type="button"
            @click="addElement"
          >
            Add element
          </button>
        </div>
      </template>
      <template v-if="segmentType === 'legal_issue'">
        <div class="instructions">
          <div>
            Segments of type "legal issue" do not have elements defined.  The segment serves as a placeholder for the dialogue that will be embedded
            based on the legal issues to be evaluated.  Output expressions and default destination will control where the dialogue proceeds following the
            legal issue evaluation.
          </div>
          <div class="mt-2">
            This segment can show legal issues evaluation results at the end, or you can configure that evalulation to be done in a different segment
            using the "Legal Issues Evaluation" block.  You might do this in order to have different dialogue paths re-use the same display config.
          </div>
        </div>
        <div class="form-row">
          <div><label>Should this segment show evaluation results?</label></div>
          <div>
            <label>
              <input
                v-model="includeLegalIssueEvalution"
                type="radio"
                name="includeEvaluation"
                value="true"
              >
              Yes
            </label>
          </div>
          <div>
            <label>
              <input
                v-model="includeLegalIssueEvalution"
                type="radio"
                name="includeEvaluation"
                value="false"
              >
              No
            </label>
          </div>
        </div>
        <div v-if="includeLegalIssueEvalution">
          <div class="form-row">
            <label>Evaluation result messages</label>
          </div>
          <div style="display: flex">
            <div style="flex: 2;padding: 6px 0;text-decoration: underline">
              <div style="margin-left:12px;">
                Language
              </div>
            </div>
            <div style="flex: 4;padding: 6px 0 6px 6px;text-decoration: underline">
              Intro text
            </div>
            <div style="flex: 4;padding: 6px 0;text-decoration: underline">
              No issues message
            </div>
            <div style="flex: 1" />
          </div>
          <div
            v-for="(translation, i) in legalIssueEvaluationConfig.translations"
            :key="translation.locale"
            style="display: flex"
          >
            <div style="flex: 2;padding: 6px 0">
              <div
                v-if="i === 0"
                style="padding:12px;"
              >
                English
              </div>
              <select
                v-if="i > 0"
                v-model="translation.system_language_id"
                style="width: 50%"
              >
                <option
                  v-for="locale in appState.systemLanguages"
                  :key="locale.id"
                  :value="locale.id"
                >
                  {{ locale.name }}
                </option>
              </select>
            </div>
            <div style="flex: 4;padding: 6px">
              <textarea
                v-model="translation.introText"
                style="width: 100%;padding:6px;"
              />
            </div>
            <div style="flex: 4;padding: 6px">
              <textarea
                v-model="translation.noIssuesMessage"
                style="width: 100%;padding:6px"
              />
            </div>
            <div style="flex:1;padding:6px;">
              <button
                v-if="i > 0"
                type="button"
              >
                Remove
              </button>
            </div>
          </div>
          <div
            v-if="legalIssueEvaluationConfig.translations.length - 1 < appState.systemLanguages.length"
            style="padding:6px 0;"
          >
            <button
              type="button"
              @click="addlegalIssueEvaluationConfigTranslation"
            >
              Add translation
            </button>
          </div>
        </div>
      </template>
      <div
        v-if="appState.mode !== 'standalone'"
        class="expressions-container"
      >
        <h3 title="Logical expression to control destination of next button">
          Output expressions
        </h3>
        <div class="expressions">
          <Container
            drag-handle-selector=".handle"
            @drop="expressionDraggableOnEnd"
          >
            <Draggable
              v-for="(row, index) in expressions"
              :key="row.id"
            >
              <expression-row
                :ref="el => expressionComponents.push(el)"
                :app-state="appState"
                :initial-config="row"
                :other-segments="otherSegments"
                @expression-remove="removeExpression(index)"
                @destination-change="row.destination = $event"
                @rules-change="row.rules = $event.rules"
                @new-intake-change="row.startNewIntake = $event"
                @validation="childCollectionItemValidated(index, $event, 'expression')"
                @create-segment="createSegment"
              />
            </Draggable>
          </Container>
          <button
            type="button"
            @click="addExpression"
          >
            Add expression
          </button>
        </div>
      </div>
      <div
        v-if="appState.mode === 'standalone'"
        class="query-builder"
      >
        <h3>Legal Issue Logical Test</h3>
        <expression-builder
          v-if="appState.matterFieldList && appState.matterFieldList.length > 0"
          ref="expressionBuilder"
          :app-state="appState"
          :rules="standaloneRules"
          @rules-change="standaloneRulesChangeHandler"
        />
      </div>
      <div
        v-if="appState.mode !== 'standalone'"
        class="pb-2 mb-2 display-flex default-destination"
      >
        <h3 title="Destination to go to when no above logical expressions match">
          Default destination
        </h3>
        <destination-picker
          ref="defaultDestinationPicker"
          v-model="defaultDestination"
          :other-segments="otherSegments"
          :app-state="appState"
          @update:model-value="defaultDestinationChange"
          @validation="childItemValidated($event, 'defaultDestination')"
          @create-segment="createSegment"
        />
      </div>
      <template v-if="appState.mode === 'interactive'">
        <button
          type="button"
          class="save-button"
          :class=" { 'is-spinner': isSavingSegment }"
          :disabled="!isValid || hasWarnings"
          @click="saveSegment"
        >
          <template v-if="isSavingSegment">
            <i class="fa fa-spin fa-refresh" />
          </template>
          <template v-else>
            Save segment
          </template>
        </button>
        <button
          type="button"
          @click="$router.push({ name: 'root' })"
        >
          Cancel
        </button>
      </template>
      <Modal
        v-if="showCreateField"
        :is-wide="true"
        :is-saving="isSavingField"
        :show-cancel="true"
        confirm-label="Save"
        @confirm="saveNewField"
        @cancel="showCreateField = false"
      >
        <template #header>
          Add new field to the system
        </template>
        <template #body>
          <div class="guided-nav-modal-form">
            <create-field-form
              v-model="createFieldForm"
              :app-state="appState"
            />
          </div>
        </template>
        <template #footer />
      </modal>
      <Modal
        v-if="showCreateSegment"
        :is-saving="false"
        :is-wide="true"
        :show-cancel="true"
        confirm-label="Save"
        @confirm="saveNewSegmentModal"
        @cancel="cancelCreateSegmentModal"
      >
        <template #header>
          <div>
            Add new segment to the dialogue
          </div>
        </template>
        <template #body>
          <div

            class="guided-nav-modal-form"
          >
            <div class="form-row">
              <label>Segment name:</label>
              <input
                v-model="createSegmentForm.name"
                type="text"
              >
            </div>
          </div>
        </template>
        <template #footer>
          <div />
        </template>
      </Modal>
    </template>
    <template v-else>
      Initializing...
      <i class="fa fa-refresh fa-spin" />
    </template>
  </div>
</template>

<script lang="ts">
import Modal from '../../common/src/components/Modal.vue';
import { ajaxGetFormMarker, ajaxRequest, applyDrag } from '@networkninja/common';
import { Container, Draggable } from 'vue-dndrop';
import Element from './elements/element.vue';
import ExpressionRow from './expression_row.vue';
import ExpressionBuilder from './expression_builder.vue';
import DestinationPicker from './destination_picker.vue';
import CreateFieldForm from './create_field.vue';
import { v4 as uuidv4 } from 'uuid';
import Util from './util.js';
import { refreshFieldList } from './app_state.js';

const defaultLegalIssueEvaluationConfig = {
  translations: [
    {
      system_language_id: 1,
      introText: 'Based on the provided responses, we identified the following probable legal issues:',
      noIssuesMessage: 'Based on the provided responses, we did not identify probable legal issues at this time.'
    }
  ]
};

export default {
  components: {
    Container,
    Draggable,
    Modal,
    'expression-row': ExpressionRow,
    'segment-element': Element,
    'destination-picker': DestinationPicker,
    'create-field-form': CreateFieldForm,
    'expression-builder': ExpressionBuilder
  },
  props: {
    segmentBeingEdited: {
      type: Object,
      default: () => ({})
    },
    appState: {
      type: Object,
      default: () => ({})
    },
    standaloneHidden: {
      type: String,
      default: ''
    }
  },
  emits: ['save-segment', 'field-changed'],
  data() {
    return {
      id: null,
      isNew: false,
      name: null,
      elements: [],
      fields: this.appState.matterFieldList,
      expressions: [],
      defaultDestination: { is_complete: false, dialogue: this.appState.dialogueId, segment: ''},
      segmentsForDefaultDestination: [],
      isValidated: false,
      errors: {},
      warnings: {},
      isValid: true,
      hasWarnings: false,
      isSavingField: false,
      showCreateField: false,
      createSegmentForm: {},
      createFieldForm: {},
      showCreateSegment: false,
      isSavingSegment: false,
      standaloneRules: {},
      segmentType: 'standard',
      includeLegalIssueEvalution: true,
      legalIssueEvaluationConfig: defaultLegalIssueEvaluationConfig,
      isReady: false,
      elementComponents: [],
      expressionComponents: [],
      ajaxFormName: null,
      ajaxHidden: null
    };
  },
  computed: {
    otherSegments() {
      return this.appState.segments.filter((item) => {
        return item.id !== this.id;
      });
    },
    elementsHeadline() {
      return this.appState.mode === 'standalone' ? 'Questions and instructions' : 'Elements';
    },
    warningTexts: function()
    {
      let warningTextArr = [];
      Object.values(this.warnings).forEach((subWarnings) => {
        warningTextArr = warningTextArr.concat(subWarnings);
      });
      const uniqueArray = warningTextArr.filter(function(item, pos) {
        return warningTextArr.indexOf(item) === pos;
      });

      return uniqueArray;
    }
  },
  watch: {
    field(newValue) {
      this.$emit('field-changed', { source: this, newValue: newValue });
    },
    name() {
      if (this.isValidated) {
        this.validate();
      }
    },
    segmentBeingEdited: {
      immediate: true,
      handler() {
        this.fixMissingAttributes();
        if (this.segmentBeingEdited) {
          this.copyFromProp();
        }
      }
    },
    elements: {
      handler() {
        if (this.appState.mode === 'standalone') {
          this.standaloneSerialize();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.appState.mode === 'standalone') {
      refreshFieldList(this.appState, () => {
        const initialValue = jQuery(this.standaloneHidden).val();
        if (initialValue && initialValue.length > 0) {
          const toSet = JSON.parse(initialValue);
          this.standaloneRules = toSet.logic;
          this.elements = toSet.elements;
        }
      });
    }
  },
  created() {
    const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
    this.ajaxFormName = formName;
    this.ajaxHidden = hidden;
    this.fixMissingAttributes();
    if (this.segmentBeingEdited) {
      this.copyFromProp();
    }

    const fieldsNeedingOptions = new Set();
    for (const el of this.elements) {
      if (el.displayWhen === 'expression') {
        for (const item of Util.findMissingOptions(el.displayWhenRules.rules, this.appState.matterFieldList)) {
          fieldsNeedingOptions.add(item);
        }
      }
    }
    if (fieldsNeedingOptions.size > 0) {
      const param = [];
      fieldsNeedingOptions.forEach(item => { param.push(item); });
      ajaxRequest(
        [
          param
        ],
        this.ajaxFormName,
        this.ajaxHidden,
        'get_system_options_for_field',
        this.appState.root
      )
        .then(responseData => {
          for (const k of Object.keys(responseData)) {
            const field = this.appState.matterFieldList.find(item => item.field_name === k);
            field.option_items = responseData[k];
          }
          this.isReady = true;
        });
    } else {
      this.isReady = true;
    }
  },
  methods: {
    addExpression() {
      this.expressions.push({
        rules: {},
        destination: {
          is_complete: false,
          dialogue: this.appState.dialogueId,
          segment: ''
        },
        id: uuidv4(),
        isNew: true,
        startNewIntake: false
      });
    },
    removeExpression(index) {
      this.expressions.splice(index, 1);
    },
    addElement() {
      this.elements.push({
        id: uuidv4(),
        elementType: '',
        displayWhen: 'always',
        hasEarlyNextRules: false,
        includeAllChannels: true,
        channelsToIncludeIn: [],
        details: {}
      });
    },
    updateElement(element, data) {
      const commanderData = JSON.parse(JSON.stringify(data));
      element.elementType = commanderData.elementType;
      element.displayWhen = commanderData.displayWhen;
      element.includeAllChannels = commanderData.includeAllChannels;
      element.channelsToIncludeIn = commanderData.channelsToIncludeIn;
      if (commanderData.displayWhenRules) {
        element.displayWhenRules = commanderData.displayWhenRules;
      }
      element.hasEarlyNextRules = commanderData.hasEarlyNextRules;
      if (commanderData.earlyNextRules) {
        element.earlyNextRules = commanderData.earlyNextRules;
      }
      if (commanderData.earlyNextDestination) {
        element.earlyNextDestination = commanderData.earlyNextDestination;
      }
      element.details = {};
      Object.keys(commanderData.details).forEach((k) => {
        if (k !== 'blockConfigFormHtml') {
          element.details[k] = commanderData.details[k];
        }
      });
      delete element.details.blockConfigFormHtml;
      if (this.appState.mode === 'standalone') {
        this.standaloneSerialize();
      }
    },
    removeElement(data) {
      this.elements = this.elements.filter(item => item.id !== data.id);
      this.validate();
    },
    defaultDestinationChange(data) {
      Object.assign(this.defaultDestination, data);
    },
    saveSegment() {
      this.isSavingSegment = true;
      // Delay slightly to allow spinner in button to show
      window.setTimeout(() => {
        if (this.validate()) {
          this.$emit('save-segment', this.$data);
        } else {
          this.$nextTick(() => {
            this.isSavingSegment = false;
            window.scrollTo({
              top: 0
            });
          });
        }
      }, 100);
    },
    validate() {
      let isValid = true;
      this.errors = {};
      this.warnings = {};

      if (!this.appState.mode === 'standalone') {
        if (this.name === undefined || this.name.length === 0) {

          this.errors['name'] = ['required'];
          isValid = false;
        }
      }

      if (Array.isArray(this.elementComponents) && this.elementComponents.length > 0) {
        this.elementComponents.forEach((elem) => {
          if (elem !== null) {
            elem.validate();
            elem.checkWarnings();
            isValid = this.isValid && elem.isValid;
          }
        });
      }

      if (Array.isArray(this.expressionComponents) && this.expressionComponents.length > 0) {
        this.expressionComponents.forEach((expr) => {
          if (expr !== null) {
            expr.validate();
            isValid = this.isValid && expr.isValid;
          }
        });
      }

      if (!this.appState.mode === 'standalone') {
        this.$refs.defaultDestinationPicker.validate();
        isValid = isValid && this.$refs.defaultDestinationPicker.isValid;
      }

      this.isValidated = true;
      this.isValid = isValid;
      return isValid;
    },
    childCollectionItemValidated(childIndex, childErrors, errKey) {
      Object.keys(this.errors).forEach((k) => {
        if (k.startsWith(errKey + '::' + childIndex + '::')) {
          delete this.errors[k];
        }
      });
      if (Object.keys(childErrors).length > 0) {
        Object.keys(childErrors).forEach((k) => {
          this.errors[errKey + '::' + childIndex + '::' + k] = childErrors[k];
        });
      }
      this.isValid = Object.keys(this.errors).length === 0;
      this.isValidated = true;
    },
    childCollectionItemWarningsChecked(childIndex, childWarnings, errKey) {
      this.hasWarnings = false;
      Object.keys(this.warnings).forEach((k) => {
        if (k.startsWith(errKey + '::' + childIndex + '::')) {
          delete this.warnings[k];
        }
      });
      if (Object.keys(childWarnings).length > 0) {
        Object.keys(childWarnings).forEach((k) => {
          this.warnings[errKey + '::' + childIndex + '::' + k] = childWarnings[k];
          if (childWarnings[k].length > 0) {
            this.hasWarnings = true;
          }
        });
      }
    },
    childItemValidated(childErrors, errKey) {
      Object.keys(this.errors).forEach((k) => {
        if (k.startsWith(errKey + '::')) {
          delete this.errors[k];
        }
      });
      Object.keys(childErrors).forEach((k) => {
        this.errors[errKey + '::' + k] = childErrors[k];
      });
      this.isValid = Object.keys(this.errors).length === 0;
      this.isValidated = true;
    },
    toggleElements(state) {
      if (this.elementComponents && this.elementComponents.length > 0) {
        this.elementComponents.forEach((el) => { el.expanded = state === 'expanded'; });
      }
    },
    elementDraggableOnEnd(dropResult) {
      this.elements = applyDrag(this.elements, dropResult);
    },
    expressionDraggableOnEnd(dropResult) {
      this.expressions = applyDrag(this.expressions, dropResult);
    },
    createField () {
      this.showCreateField = true;
    },
    saveNewField () {
      this.isSavingField = true;
      ajaxRequest(
        [this.createFieldForm],
        this.ajaxFormName,
        this.ajaxHidden,
        'save_new_field',
        this.appState.root
      )
        .then(() => {
          refreshFieldList(this.appState, () => {
            this.$nextTick(() => {
              this.showCreateField = false;
              this.isSavingField = false;
            });
          });
        });
    },
    createSegment(callback) {
      this.createSegmentModal(callback);
    },
    createSegmentModal(callback) {
      this.createSegmentForm = {
        name: ''
      };
      this.createSegmentModalCallback = callback;
      this.showCreateSegment = true;
    },
    cancelCreateSegmentModal() {
      this.showCreateSegment = false;
      this.createSegmentModalCallback = undefined;
    },
    saveNewSegmentModal() {
      const newSegment = {
        id: uuidv4(),
        isNew: true,
        field: '',
        defaultDestination: { is_complete: true },
        expressions: [],
        elements: [],
        name: this.createSegmentForm.name
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.appState.segments.push(newSegment);
      this.showCreateSegment = false;
      if (this.createSegmentModalCallback) {
        this.createSegmentModalCallback(newSegment);
      }
      this.createSegmentModalCallback = undefined;
    },
    fixMissingAttributes() {
      [this.expressions, this.elements].forEach((collection) => {
        collection.forEach((item) => {
          if (item.id === undefined) {
            item.id = uuidv4();
          }
          if (item.displayWhen === undefined) {
            item.displayWhen = 'always';
          }
        });
      });
    },
    copyFromProp() {
      this.id = this.segmentBeingEdited.id ? this.segmentBeingEdited.id : uuidv4();
      this.isNew = this.segmentBeingEdited.isNew;
      this.name = this.segmentBeingEdited.name;
      this.segmentType = this.segmentBeingEdited.segmentType ?  this.segmentBeingEdited.segmentType : 'standard';
      this.includeLegalIssueEvalution = this.segmentBeingEdited.includeLegalIssueEvalution !== undefined ? this.segmentBeingEdited.includeLegalIssueEvalution : true;
      if (this.segmentBeingEdited.legalIssueEvaluationConfig && this.segmentBeingEdited.legalIssueEvaluationConfig.translations && this.segmentBeingEdited.legalIssueEvaluationConfig.translations.length > 0) {
        this.legalIssueEvaluationConfig = this.segmentBeingEdited.legalIssueEvaluationConfig;
      } else {
        this.legalIssueEvaluationConfig = defaultLegalIssueEvaluationConfig;
      }
      this.elements = this.segmentBeingEdited.elements;
      this.expressions = this.segmentBeingEdited.expressions || [];
      this.defaultDestination = this.segmentBeingEdited.defaultDestination || { is_complete: false, dialogue: this.appState.dialogueId, segment: ''};
    },
    standaloneRulesChangeHandler(rules) {
      this.standaloneRules = rules;
      this.standaloneSerialize();
    },
    standaloneSerialize() {
      const newValue = {
        elements: this.elements,
        logic: this.standaloneRules
      };
      jQuery(this.standaloneHidden).val(JSON.stringify(newValue));
    },
    addlegalIssueEvaluationConfigTranslation() {
      this.legalIssueEvaluationConfig.translations.push(
        {
          system_language_id: null,
          introText: null,
          noIssuesMessage: null
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
    h3.error, li.error {
        color: #f79483;
    }
    .hdr {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .expressions-container .expressions {
        border: 1px solid black;
        padding: 16px;
        margin-bottom: 16px;
    }
    .elements {
        border: 1px solid black;
        padding: 16px;
        margin-bottom: 16px;
        .element:last-of-type {
            margin-bottom: 16px;
        }
    }
    .default-destination {
        flex-direction: column;
        .h3 {
            margin-right: 8px;
        }
        >div {
            line-height: 32px;
        }
        .picker-container {
            border: 1px solid black;
            padding: 16px;
        }
    }
    .save-button.is-spinner {
        line-height: 1.9em;
        padding: 0 3.3em;
    }
    .instructions {
        margin: 24px 0;
        padding: 12px;
        border: 1px solid #000;
        background: lightyellow;
        font-size: 14px;
    }
    .dndrop-container.vertical > .dndrop-draggable-wrapper {
      overflow: visible;
    }
</style>
