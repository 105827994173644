<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div
      ref="segmentList"
      class="segment_list"
    >
      <input
        v-model="segmentFilter"
        type="text"
        class="search"
        placeholder="Filter by segment name"
      >
      <i
        class="fa fa-times"
        @click="segmentFilter = ''"
      />
      <div class="row hdr">
        <div class="name">
          Name
        </div>
        <div class="actions">
          Actions
        </div>
      </div>
      <h4
        v-show="filteredSegments.length === 0"
        class="pl-2 pt-2 fw-normal"
      >
        No segments
      </h4>
      <div class="rows">
        <div
          v-for="(segment, index) in filteredSegments"
          ref="segmentRows"
          :key="segment.id"
          class="row"
        >
          <div class="name">
            {{ segment.name }}
          </div>
          <div
            ref="actions"
            class="actions"
          >
            <div
              ref="details"
              class="details"
              :style="{ top: (hoveredSegment !== null && hoveredSegment.segment.id === segment.id) ? hoveredSegment.detailsTop : 0, left: (hoveredSegment !== null && hoveredSegment.segment.id === segment.id) ? hoveredSegment.detailsLeft : '-10000px' }"
            >
              <segment-details
                :app-state="appState"
                :segment="segment"
              />
            </div>
            <div
              class="control"
              @mouseover="showDetails(segment, index, $event)"
              @mouseout="hideDetails(segment)"
            >
              <i class="fa fa-eye" />
              <div class="label">
                Preview
              </div>
            </div>
            <div
              class="control danger"
              @click="deleteSegment(segment)"
            >
              <i
                class="fa fa-times"
                title=""
              />
              <div class="label">
                Delete
              </div>
            </div>
            <div class="control">
              <router-link
                title="Edit"
                :to="{ name: 'segment-edit', params: { id: segment.id } }"
              >
                <i class="fa fa-pencil" />
                <div class="label">
                  Edit
                </div>
              </router-link>
            </div>
            <div
              class="control"
              @click="copySegment(segment)"
            >
              <i
                class="fa fa-copy"
                title=""
              />
              <div class="label">
                Copy
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link
      v-if="appState.mode === 'interactive'"
      :to="{ name: 'segment-new' }"
      class="router_button"
    >
      <button type="button">
        Add new segment
      </button>
    </router-link>
    <Modal
      v-if="showModal"
      :show-cancel="modalConfig.showCancel"
      :confirm-label="modalConfig.confirmLabel"
      :is-saving="false"
      @cancel="showModal = false"
      @confirm="modalConfirmHandler"
    >
      <template #header>
        <div v-html="modalConfig.header" />
      </template>
      <template #body>
        <div v-html="modalConfig.body" />
      </template>
      <template #footer>
        <div v-html="modalConfig.footer" />
      </template>
    </Modal>
    <Modal
      v-if="showCopySegmentModal"
      :is-wide="true"
      :is-saving="isSavingSegmentCopy"
      :show-cancel="false"
      confirm-label="Save"
      @confirm="saveSegmentCopy"
      @cancel="showCopySegmentModal = false"
    >
      <template #header>
        <div>
          Copy segment
        </div>
      </template>
      <template #body>
        <div

          class="guided-nav-modal-form"
        >
          <copy-segment-form
            v-model="copySegmentForm"
            :app-state="appState"
          />
        </div>
      </template>
      <template #footer>
        <div />
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { ajaxGetFormMarker, ajaxRequest} from '@networkninja/common';
import Modal from '../../common/src/components/Modal.vue';
import SegmentDetails from './segment_details.vue';
import CopySegmentForm from './copy_segment.vue';
export default {
  components: {
    Modal,
    'segment-details': SegmentDetails,
    'copy-segment-form': CopySegmentForm
  },
  props: {
    appState: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      modalConfig: {},
      copySegmentForm: {},
      showModal: false,
      showCopySegmentModal: false,
      destinationDialogId: '',
      segmentToCopyId: null,
      isSavingSegmentCopy: false,
      segmentFilter: '',
      hoveredSegment: null
    };
  },
  computed: {
    filteredSegments: function () {
      let retval = [];
      if (this.segmentFilter && this.segmentFilter !== '') {
        retval = this.appState.segments.filter(segment => segment.name.toLowerCase().indexOf(this.segmentFilter.toLowerCase()) >= 0);
      } else {
        retval = [].concat(this.appState.segments);
      }
       
      retval.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
      return retval;
    }
  },
  watch: {
    showModal: function (newValue) {
      if (!newValue) {
        this.modalConfig = {};
        window.removeEventListener('keyup', this.modalCloseOnWindowKeyup);
        this.modalConfirmHandler = function () {
          this.showModal = false;
        };
      } else {
        window.addEventListener('keyup', this.modalCloseOnWindowKeyup);
      }
    },
    showCopySegmentModal: function (newValue) {
      if (!newValue) {
        window.removeEventListener('keyup', this.modalCloseOnWindowKeyup);
        this.modalConfirmHandler = function () {
          this.showCopySegmentModal = false;
        };
      } else {
        window.addEventListener('keyup', this.modalCloseOnWindowKeyup);
      }
    }
  },
  methods: {
    deleteSegment: function (segmentToRemove) {
      this.modalConfig = {
        body: 'Are you sure you want to remove this item?',
        showCancel: true
      };
      this.modalConfirmHandler = () => {
        const indexToRemove = this.appState.segments.findIndex(seg => seg.id === segmentToRemove.id);
        // eslint-disable-next-line vue/no-mutating-props
        this.appState.segments.splice(indexToRemove, 1);
        this.showModal = false;
      };
      this.showModal = true;
    },
    copySegment: function (segmentToCopy) {
      this.segmentToCopyId = segmentToCopy.id;
      this.showCopySegmentModal = true;
    },
    saveSegmentCopy: function () {
      const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
      this.isSavingSegmentCopy = true;
      ajaxRequest(
        [
          this.appState.dialogueId,
          this.segmentToCopyId,
          this.$data.copySegmentForm.destinationDialogueId
        ],
        formName,
        hidden,
        'copy_segment',
        this.appState.root
      )
        .then(responseData => {
          this.isSavingSegmentCopy = false;
          this.showCopySegmentModal = false;

          if (responseData.status === 'OK') {

            // eslint-disable-next-line eqeqeq
            if (this.appState.dialogueId == this.$data.copySegmentForm.destinationDialogueId) {
              // eslint-disable-next-line vue/no-mutating-props
              this.appState.segments.push(responseData.segment_copy);
            }

            jQuery.gritter.add({
              text: 'Segment copied successfully',
              sticky: false,
              class_name: 'success'
            });
          } else {

            jQuery.gritter.add({
              text: 'Error copying segment: ' + responseData.error,
              sticky: false,
              class_name: 'error'
            });
          }
        });
    },
    modalCloseOnWindowKeyup: function (ev) {
      if (ev.keyCode === 27) {
        this.showModal = false;
        this.showCreateField = false;
      }
    },
    showDetails: function(segment, index) {
      const row = this.$refs.segmentRows[index];
      const details = this.$refs.details[index];
      const actionsWidth = this.$refs.actions[index].getBoundingClientRect().width;
      const detailsLeft = row.getBoundingClientRect().width - details.getBoundingClientRect().width - actionsWidth - 16;
      const detailsTop = row.getBoundingClientRect().height / 2 - details.getBoundingClientRect().height / 2;
      this.hoveredSegment = {
        segment: segment,
        detailsTop: detailsTop + 'px',
        detailsLeft: detailsLeft + 'px'
      };
      this.$nextTick(() => {
        if (details.getBoundingClientRect().top < 0) {
          this.hoveredSegment = {
            segment: segment,
            detailsTop: (detailsTop - details.getBoundingClientRect().top + 32) + 'px',
            detailsLeft: detailsLeft + 'px'
          };
        } else if (details.getBoundingClientRect().bottom > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
          this.hoveredSegment = {
            segment: segment,
            detailsTop: (detailsTop - (details.getBoundingClientRect().bottom - Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) - 16) + 'px',
            detailsLeft: detailsLeft + 'px'
          };
        }
      });
    },
    hideDetails: function(segment) {
      if (this.hoveredSegment && this.hoveredSegment.segment.id === segment.id) {
        this.hoveredSegment = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>

