import { isEmpty, LogicEvaluator } from "@networkninja/common";
import jQuery from 'jquery';

export interface state {
  displayedItems: object[],
  valuesSet: object,
  preHidingValues: string[]|object|null,
  segmentId?: string|number
}

interface displayWhenRules {
  id: string,
  rules: object[]
};

export interface element {
  $el: JQuery<HTMLElement>,
  displayWhen: string,
  displayWhenRules: displayWhenRules,
  details: {
    resetWhenHidden: boolean,
    field: string
  },
  elementType: string,
  hasEarlyNextRules: boolean,
  earlyNextRules: object,
  id: number,
  name: string
};

export interface segment {
  id: number,
  elements: Array<element>,
  name: string
};

export interface config {
  segment: segment,
  $el: JQuery<HTMLElement>,
  hidden_id: string,
  initial_values: object,
  profile: object
}


export default class Engine {
  segment: segment;
  $el: JQuery<HTMLElement>;
  $stateHidden: JQuery<HTMLElement>;
  profile: object;
  state: state;
  fieldsToMonitor: object;

  constructor(config: config) {
    this.segment = config.segment;
    this.$el = config.$el;
    this.$stateHidden = this.$el.find("#" + config.hidden_id + "_input");
    this.profile = {};
    this.state = {
      displayedItems: [],
      valuesSet: {},
      preHidingValues:
        config.initial_values === null || Array.isArray(config.initial_values) ? {} : config.initial_values
    };
    // Prepend 'matter' to all of the fields that got passed in from the server side.
    // This is mainly because that's how the field names got initially set up in the expression builder,
    // so that it could support working with different modules (e.g. not just matter but also service).
    Object.keys(config.profile).forEach((k) => {
      let val = config.profile[k];
      if (val !== null && val !== undefined && typeof val === "object" && "id" in val && "name" in val) {
        // flatten lookup to id value
        val = val.id;
      } else if (val !== null && val !== undefined && Array.isArray(val)) {
        if (val.length > 0 && typeof val[0] === "object" && val[0] !== null) {
          // flatten funding code array
          if ("id" in val[0] && "source" in val[0]) {
            // if this branch not followed then cannot handle this type, leave as is
            val = val.map((item) => (Array.isArray(item.id) ? item.id[0] : item.id));
          }
        } else {
          // Do nothing, assume it is a multiselect lookup field
        }
      }
      this.profile["matter:" + k] = val;
    });

    if (this.segment) {
      this.state.segmentId = this.segment.id;
      // Update local copy of profile when any field inputs change
      this.$el.find(".elements > div").each((idx, container: HTMLElement) => {
        const el = this.segment.elements[idx];
        if (el) {
          el.$el = jQuery(container);
          if (el.elementType === "field" || el.elementType === "block") {
            // LS-83023 added block to catch fields that are within blocks
            el.$el.find("input,select,textarea").each((idx, el) => {
              const $thisEl = jQuery(el);
              $thisEl.on("change",() => {
                const strippedName = $thisEl.attr("name").replace(/\[\]$/, "");
                this.profile[strippedName] = $thisEl.val();
                this.setStateValue(strippedName, $thisEl.val());
                this.refresh();
              });
              // Set initial value to support showing/hiding after form submit validation failure
              if (($thisEl.attr("type") !== "radio" && $thisEl.attr("type") !== "checkbox") || $thisEl.attr("checked")) {
                this.profile[$thisEl.attr("name")] = $thisEl.val();
                this.setStateValue($thisEl.attr("name"), $thisEl.val());
              }
            });
          }
        }
      });

      // Collect all the fields involved in any logical expressions attached to elements
      this.fieldsToMonitor = {};
      const collectFields = (rule) => {
        let retval = [];
        if (rule.rules) {
          rule.rules.forEach((r) => {
            retval = retval.concat(collectFields(r));
          });
        } else {
          retval.push(rule.id);
        }
        return retval;
      };
      this.segment.elements.forEach((el) => {
        if (el.displayWhen === "expression") {
          collectFields(el.displayWhenRules).forEach((field) => {
            this.fieldsToMonitor[field] = true;
          });
        }
      });

      // Listen for changes from inputs embedded in other components, such as form_element_dob
      jQuery(window).on("legal_server_form_element_update", (ev, eventData) => {
        if (eventData.field in this.fieldsToMonitor) {
          this.profile[eventData.field] = eventData.value;
          this.refresh();
        }
      });

      // Initialize the display based on current state
      this.refresh();
    }
    globalThis.guided_nav_is_loaded = true;
  }

  setStateValue(key: string, value) {
    this.state.valuesSet[key] = value;
  }

  unsetStateValue(key: string) {
    delete this.state.valuesSet[key];
  }

  updateStateHidden() {
    this.$stateHidden.val(JSON.stringify(this.state));
  }

  refresh() {
    let somethingIsHidden = false,
      isVisibleEarlyNextButton = false;
    this.state.displayedItems = [];
    this.segment.elements.forEach((element) => {
      const elementInputIds = {};
      element.$el.find("input,select,textarea").each(function () {
        const elementId = jQuery(this).closest(".form_element").attr("id");
        if (elementId && elementId !== "") {
          elementInputIds[elementId] = true;
        }
      });
      if (element.displayWhen === "expression") {
        if (!isEmpty(element.displayWhenRules) && element.displayWhenRules.rules) {

           
          if (LogicEvaluator._eval(element.displayWhenRules, this.profile, { use_date_comparison: true })) {
            element.$el.removeClass("hide").children().removeClass("hide");
            this.state.displayedItems.push({
              elementId: element.id,
              fieldsSet: {},
              inputs: Object.keys(elementInputIds)
            });
            // check for guided_navigation_block_wrapper and if found, tell it the block has been shown
            element.$el.children().find(".block_submit_tracker").val("t");
          } else {
            // check for guided_navigation_block_wrapper and if found, tell it the block has been hidden
            element.$el.children().find(".block_submit_tracker").val("f");
            if (element.details.resetWhenHidden) {
              const profileKey = "matter:" + element.details.field.split(":")[1];
              // Cache value just before hiding if not already cached
              if (this.state.preHidingValues[element.id] === undefined) {
                this.state.preHidingValues[element.id] = this.profile[profileKey];
              }
            }

            element.$el.addClass("hide");

            // commented out in LS-97016 & LS-95709 so that expression hidden fields would retain their values/defaults
            // clear radio buttons when hidden
            // if (element.elementType === "field") {
            //   this.unsetStateValue("matter:" + element.details.field.split(":")[1]);
            //   this.profile["matter:" + element.details.field.split(":")[1]] = null;
            //   element.$el.find("input[type=radio]").attr("checked", null);
            //   element.$el.find("input[type=text],textarea").val("");
            //   element.$el.find("select").val("");
            // }
            somethingIsHidden = true;
          }
        } else {
           
          console.error('Element is marked as displayWhen = "expression" but displayWhenRules are invalid.', element);
        }
      } else {
        this.state.displayedItems.push({ elementId: element.id, fieldsSet: {}, inputs: Object.keys(elementInputIds) });
      }
      if (element.hasEarlyNextRules && !isEmpty(element.earlyNextRules)) {
         
        if (LogicEvaluator._eval(element.earlyNextRules, this.profile, { use_date_comparison: true })) {
          element.$el.find(".early_next_button").removeClass("hide");
          isVisibleEarlyNextButton = true;
        } else {
          element.$el.find(".early_next_button").addClass("hide");
        }
      }
    });
    if (!somethingIsHidden && !isVisibleEarlyNextButton) {
      this.$el.find(".bottom_next_button").removeClass("hide");
    } else if (isVisibleEarlyNextButton) {
      this.$el.find(".bottom_next_button").addClass("hide");
    }

    if (globalThis._paq !== null && globalThis._paq !== undefined) {
      // add watchers to next, previous, start over for matomo
      let segmentName = null;
      if (this.segment && Object.prototype.hasOwnProperty.call(this.segment, 'name')) {
        segmentName = this.segment.name;
      }
      const bottomNextButtonGroup = this.$el.find(".bottom_next_button");
      const bottomNextButton = bottomNextButtonGroup.find('.button');
      bottomNextButton.on('click', function () {
        globalThis._paq.push(['trackEvent', 'Guided Navigation Dialogue', 'Next Segment', 'Click Next Segment', segmentName]);
      });

      const earlyNextButtonGroup = this.$el.find(".early_next_button");
      const earlyNextButton = earlyNextButtonGroup.find('.button');
      earlyNextButton.on('click', function () {
        globalThis._paq.push(['trackEvent', 'Guided Navigation Dialogue', 'Early Next Segment', 'Click Early Next Segment', segmentName]);
      });

      const previousButton = this.$el.find('input[ref="previous"]');
      previousButton.on('click', function () {
        globalThis._paq.push(['trackEvent', 'Guided Navigation Dialogue', 'Previous Segment', 'Click Previous Segment', segmentName]);
      });

      const startOverButton = this.$el.find('input[ref="start_over"]');
      startOverButton.on('click', function () {
        globalThis._paq.push(['trackEvent', 'Guided Navigation Dialogue', 'Start Over', 'Click Start Over', segmentName]);
      });
    }

    this.updateStateHidden();
  }
}
