<template lang="pug">
div.api-parameter-row
    div.inputs
        div.api-parameter-dynamic-source
            div
                Multiselect(:options="fieldList",
                :placeholder="'Choose field...'",
                track-by="lookup_name",
                label="short_name",
                v-model="selectedField",
                @select="change",
                @remove="change")
        div.api-parameter-key
            input(type="text", v-model="jqFilter", placeholder="Jq Filter", @change="change")
    div.api-parameter-remove
        button.btn_bare.btn_danger(type="button", @click="$emit('remove')"): i.fa.fa-times

</template>
<script lang="ts">
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: {
    value: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    addSpecialMatterFields: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  data: function() {
    return {
      id: this.value.id,
      fieldToSet: this.value.fieldToSet,
      jqFilter: this.value.jqFilter,
      selectedField: null
    };
  },
  computed: {
    fieldList() {
      const retval = [].concat(this.fields.filter((item) => !(item.grouping) || item.grouping === ''));
      const groupedFields = {};
      this.fields.forEach((item) => {
        if (item.grouping && item.grouping !== '') {
          if (!(item.grouping in groupedFields)) {
            groupedFields[item.grouping] = [];
          }
          groupedFields[item.grouping].push(item);
        }
      });
      Object.keys(groupedFields).forEach((k) => {
        const groupItem = {
          short_name: k,
          lookup_name: k,
          children: groupedFields[k].map((child) => { return { id: child.lookup_name, text: child.short_name }; })
        };
        retval.push(groupItem);
      });
      if (this.addSpecialMatterFields) {
        retval.push({
          lookup_name: 'matter:housenumber',
          short_name: 'House number'
        });
        retval.push({
          lookup_name: 'matter:streetname',
          short_name: 'Street name'
        });
      }
      return retval;
    }
  },
  mounted() {
    this.selectedField = this.fieldList.find(item => item.lookup_name === this.value.fieldToSet) || null;
  },
  methods: {
    change() {
      this.fieldToSet = this.selectedField.lookup_name;
      this.$emit('change', this.$data);
    }
  }
};
</script>
<style lang="scss" scoped>
    .api-parameter-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 6px 0;
        .inputs {
            display: flex;
            width: calc(100% - 32px);
            .api-parameter-type {
                padding-right: 6px;
                width: 20%;
                select {
                    width: 100%;
                }
            }
            .api-parameter-key {
                padding-right: 6px;
                flex: 1;
                input {
                    line-height: 1.6em;
                    margin-top: 0;
                    width: 100%;
                }
            }
            .api-parameter-static-value {
                padding-right: 6px;
                width: 30%;
                input {
                    width: 100%;
                }
            }
            .api-parameter-dynamic-source {
                padding-right: 6px;
                width: 30%;
                select {
                    width: 100%;
                }
            }
            .api-parameter-path {
                display: flex;
                align-items: center;
            }
        }
    }
</style>
