<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label(title="Document template to generate") Document:
        div
            select(v-model="details.document",
                    @change="change")
                option(v-for="document in appState.documentTemplates" :value="document.id") {{ document.text }}
    div.form-row(v-show="expanded")
        div: label(title="Show link to document after generation?") Show link to document after it is generated:
        div: input(@change="change", type="checkbox", v-model="details.showLink")
</template>

<script lang="ts">
import InternalsBase from './internals_base.vue';
export default {
  extends: InternalsBase,
  detailsTemplate: {
    document: '',
    showLink: false
  }
};
</script>
