<template lang="pug">
div
    multiselect(:options="fields",
    :placeholder="'Choose field...'",
    track-by="lookup_name",
    label="short_name",
:group-values="'children'",
:group-select="true",
:group-label="'grouping'"
    v-model="selectedField",
    @select="change",
    @remove="change")
</template>

<script lang="ts">
import Multiselect from "vue-multiselect";
 
export default {
  components: { Multiselect },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    appState: {
      type: Object,
      required: true
    },
    removeDisplayOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'change', 'update:modelValue'],
  data: function() {
    const selectedField = this.appState.matterFieldList.find((f) => f.lookup_name === this.modelValue) || null;
    return {
      fields: [],
      selectedField
    };
  },
  watch: {
    removeDisplayOnly: function() {
      this.setFields();
    }
  },
  created: function() {
    this.setFields();
  },
  methods: {
    change: function() {
      this.$emit('input', this.selectedField ? this.selectedField.lookup_name : null);
      this.$emit('update:modelValue', this.selectedField ? this.selectedField.lookup_name : null);
      this.$emit('change');
    },
    setFields() {
      const groupedFields = { Unclassified: []};
      this.appState.matterFieldList.forEach((item) => {
        if (item.grouping && item.grouping !== '') {
          if (!(item.grouping in groupedFields)) {
            groupedFields[item.grouping] = [];
          }
          if (!this.removeDisplayOnly || !item.display_only) {
            groupedFields[item.grouping].push(item);
          }
        } else {
          groupedFields.Unclassified.push(item);
        }
      });
      Object.keys(groupedFields).forEach((k) => {
        const groupItem = {
          grouping: k,
          children: groupedFields[k].map(
            (child) => {
              if (this.removeDisplayOnly && child.display_only) {
                return;
              }
              return {
                lookup_name: child.lookup_name,
                grouping: k,
                short_name: child.short_name + (child.display_only ? ' (Display Only)' : '')
              };
            })
        };
        this.fields.push(groupItem);
      });
    }
  }
};
</script>
