<template>
  <!--eslint-disable vue/no-v-html-->
  <div
    class="expression_row"
    :data-id="initialConfig.id"
  >
    <div class="handle" />
    <div style="width:100%">
      <div style="width:100%;display:flex;align-items: flex-start">
        <div
          class="expandButton"
          :class="{ 'v-center': !expanded }"
        >
          <button
            type="button"
            class="btn_bare"
            @click="expanded = !expanded"
          >
            <i
              class="fa"
              :class="{ 'fa-caret-down' : expanded, 'fa-caret-right': !expanded }"
            />
          </button>
        </div>
        <div
          v-show="!expanded"
          class="collapseSummary"
          v-html="summary"
        />
        <div
          class="expression_builder"
          :class="{ hidden: !expanded }"
        >
          <expression-builder
            ref="expressionBuilder"
            :app-state="appState"
            :rules="rules"
            @validation="childValidated($event, 'expressionBuilder')"
            @rules-change="rulesChangeHandler"
          />
        </div>
        <destination-picker
          ref="destinationPicker"
          v-model="destination"
          :is-compact="true"
          :app-state="appState"
          :other-segments="otherSegments"
          :class="{ hidden: !expanded }"
          @validation="childValidated($event, 'destinationPicker')"
          @update:model-value="destinationChange"
          @create-segment="createSegment"
        />
        <div
          v-if="!hideRowControls"
          class="row_controls"
        >
          <button
            type="button"
            class="btn_bare btn_danger"
            title="Remove output expression"
            @click="$emit('expression-remove')"
          >
            <i class="fa fa-times" />
          </button>
        </div>
      </div>
      <div
        v-if="appState.mode === 'interactive' && appState.enableNewIntakeDestinations"
        :class="{ hidden: !expanded }"
      >
        <label>
          Start new intake on this destination:
          <input
            v-model="startNewIntake"
            type="checkbox"
            @change="$emit('new-intake-change', startNewIntake)"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ExpressionBuilder from './expression_builder.vue';
import DestinationPicker from './destination_picker.vue';
import Util from './util.js';

export default {
  components: {
    'expression-builder': ExpressionBuilder,
    'destination-picker': DestinationPicker
  },
  props: {
    appState: {
      type: Object,
      required: true
    },
    initialConfig: {
      type: Object,
      required: true
    },
    otherSegments: {
      default: function() {
        return [];
      },
      type: Array
    },
    hideRowControls: {
      default: false,
      type: Boolean
    }
  },
  emits: ['create-segment', 'expression-remove', 'rules-change', 'destination-change', 'validation', 'new-intake-change'],
  data: function() {
    return {
      destination: { },
      rules: { },
      segmentsForDestination: [ ],
      isValid: true,
      isValidated: false,
      expanded: false,
      isMounted: false,
      startNewIntake: false
    };
  },
  computed: {
    summary: function() {
      let retval = '';
      if (this.destination.is_complete) {
        retval = 'Exit dialogue';
      } else if (this.destination.segment !== '') {
        let dialogueName = '', segmentName = '', destinationDialogue = null, destinationSegment = null;
        if (this.destination.dialogue === this.appState.dialogueId) {
          dialogueName = 'this dialogue';
          destinationSegment = this.otherSegments.find(seg => seg.id === this.destination.segment);
        } else {
          const destinationDialogueId = parseInt(this.destination.dialogue);
          destinationDialogue = this.appState.allDialogues.find(d => d.id === destinationDialogueId);
          if (destinationDialogue) {
            dialogueName = 'dialogue "' + destinationDialogue.name + '"';
            destinationSegment = destinationDialogue.segments.find(seg => seg.id === this.destination.segment);
          } else {
            dialogueName = 'misconfigured dialogue';
          }
        }
        if (destinationSegment) {
          segmentName = destinationSegment.name;
        }
        retval = 'Go to ' + dialogueName + ', segment "' + segmentName + '"';
      } else {
        retval = 'Not yet fully configured';
      }


      if (this.isMounted && this.$refs.expressionBuilder && this.$refs.expressionBuilder.currentRules && this.$refs.expressionBuilder.currentRules.valid) {
        retval = 'When&nbsp;<span class="logic-text">' +
          Util.buildLogicText(this.$refs.expressionBuilder.currentRules, this.appState.matterFieldList) +
          '</span>&nbsp;then&nbsp;<span class="destination">' + retval + '</span>';
      }
      return retval;
    }
  },
  created: function() {
    this.destination = this.initialConfig.destination;
    this.rules = this.initialConfig.rules;
    this.startNewIntake = this.initialConfig.startNewIntake  ? this.initialConfig.startNewIntake : false;
    if (this.initialConfig.isNew) {
      this.expanded = true;
    }
  },
  mounted: function() {
    this.isMounted = true;
  },
  methods: {
    rulesChangeHandler: function(newValue) {
      if (newValue.valid) {
        this.$emit('rules-change', { rules: newValue });
      }
    },
    createSegment: function(callback) {
      this.$emit('create-segment', callback);
    },
    destinationChange: function(data) {
      Object.assign(this.destination, data);
      this.$emit('destination-change', this.destination);
    },
    validate: function() {
      this.isValid = true;
      // LS-122701: deleting the destinationPicker will make it null - so it's safe to skip
      if (this.$refs.destinationPicker) {
        this.$refs.destinationPicker.validate();
        this.isValid = this.isValid && this.$refs.destinationPicker.isValid;
        this.$refs.expressionBuilder.validate();
        this.isValid = this.isValid && this.$refs.expressionBuilder.isValid;
      }
      if (!this.isValid) {
        this.expanded = true;
      }
    },
    childValidated: function($errors, refKey) {
      this.isValid = this.isValid && this.$refs[refKey].isValid;
      this.$emit('validation', $errors);
    }
  }
};
</script>

<style scoped lang="scss">
    .expression_row {
        position: relative;
        display: flex;
        align-items: flex-start;
        width: 100%;
        border: 1px solid #d9d9d9;
        background: #f9f9f9;
        padding: 24px;
        margin-bottom: 12px;
        i.fa-refresh {
            font-size: 140%;
        }
        .output_select {
            flex: 1;
            padding: 4px 12px 0 12px;
            &:not(:last-child) {
                margin-bottom: 6px;
            }
            > h4 {
                padding-top: 2px;
            }
            select {
                margin-top: 0;
            }
        }
        .row_controls {
            position: absolute;
            top: 6px;
            right: 12px;
        }
        .expression_builder {
            width: 67%;
        }
        .query-builder .rules-group-container, .query-builder .rule-container, .query-builder .rule-placeholder {
            margin: 0;
        }
        .closeButton {
            position: absolute;
            top: 12px;
            right: 12px;
        }
        .expandButton {
            position: absolute;
            top: 12px;
            left: 12px;
            &.v-center {
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
        .handle {
            width: 7px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: move;
            background-image: url("/template/ls3/image/handle.png");
            background-repeat: repeat-y;
            background-position: top left;
        }
        .hidden {
            position: absolute;
            left: -10000px;
        }
        .collapseSummary {
            padding-left: 4px;
            padding-top: 3px;
            height: 100%;
            display: flex;
            align-items: center;
            line-height: 12px;
        }
    }
</style>

<style lang="scss" scoped>
    /** NOT SCOPED **/
    .guided_navigation .expression_row .collapseSummary {
        .field {
            color: blue;
        }
        .logic-text {
            background:rgba(250, 240, 210, 0.5);
            border:1px solid black;
            padding:3px;
        }
        .destination {
            background:rgba(250, 240, 210, 0.5);
            border:1px solid black;
            padding:3px;
        }
        .value {
            color:green;
        }
        .operator {
            font-weight: 700;
        }
    }
</style>

