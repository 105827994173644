<template>
  <div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="API URL"
        >URL:<span class="form-required">*</span></label>
      </div>
      <div>
        <input
          v-model="details.url"
          :class="{ 'form-error': errors['url'] }"
          type="text"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="HTTP Method"
        >Method:<span class="form-required">*</span></label>
      </div>
      <div>
        <select
          v-model="details.method"
          @change="change"
        >
          <option value="GET">
            GET
          </option><option value="POST">
            POST
          </option><option value="PATCH">
            PATCH
          </option><option value="PUT">
            PUT
          </option><option value="DELETE">
            DELETE
          </option>
        </select>
      </div>
      <div
        v-show="expanded"
        v-if="postTypeRequired"
        class="form-row"
      />
      <div>
        <label
          class="mr-2"
          title="HTTP Method"
        >Post type:</label>
      </div>
      <div>
        <select
          v-model="details.postType"
          @change="change"
        >
          <option value="form">
            Form
          </option><option value="json">
            JSON
          </option>
        </select>
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="HTTP Headers"
        >HTTP Headers:</label>
      </div>
      <div>
        <textarea
          v-model="details.headers"
          :class="{ 'form-error': errors['headers'] }"
          @change="change"
        />
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="HTTP Method"
        >Authentication:<span class="form-required">*</span></label>
      </div>
      <div>
        <div class="mb-2">
          <label><input
            v-model="details.authenticationType"
            type="radio"
            value="none"
            @change="authTypeChange"
          ><span>No authentication</span></label>
        </div>
        <div class="mb-2">
          <label><input
            v-model="details.authenticationType"
            type="radio"
            value="basic"
            @change="authTypeChange"
          ><span>HTTP basic authentication</span></label>
          <div
            v-if="details.authenticationType === 'basic'"
            class="mt-1 mb-1"
          >
            <div>
              <label>Username:</label><br><input
                v-model="details.username"
                type="text"
                @change="change"
              >
            </div>
            <div>
              <label>Password:</label><span
                v-if="passwordHint"
                class="whisper"
              >&nbsp;Current value: {{ passwordHint }}</span><br><input
                v-model="details.password"
                type="password"
                @change="change"
              >
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label><input
            v-model="details.authenticationType"
            type="radio"
            value="token"
            @change="authTypeChange"
          ><span>Bearer token</span></label>
          <div
            v-if="details.authenticationType === 'token'"
            class="mt-1"
          >
            <div>
              <label>Token:</label><span
                v-if="tokenHint"
                class="whisper"
              >&nbsp;Current value: {{ tokenHint }}</span><br><input
                v-model="details.token"
                type="password"
                @change="change"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label class="mr-2">Perform request synchronously:</label></div>
      <div>
        <input
          v-model="details.isSynchronousRequest"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="HTTP Method"
        >Parameters:</label>
      </div>
    </div>
    <div
      v-show="expanded"
      class="repeater-box"
    >
      <api-call-parameter
        v-for="(param, idx) in details.parameters"
        :key="param.id"
        :value="details.parameters[idx]"
        :app-state="appState"
        @change="parameterChange($event, idx)"
        @remove="removeParameter(idx)"
      />
      <button
        type="button"
        @click="addParameter"
      >
        Add parameter
      </button>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label class="mr-2">Modify parameters for NYC data format:</label></div>
      <div>
        <input
          v-model="details.isNycData"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label class="mr-2">Store response in a note:</label></div>
      <div>
        <input
          v-model="details.shouldStoreInNote"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      v-if="details.shouldStoreInNote"
      class="form-row"
    >
      <div><label class="mr-2">Note type:</label></div>
      <div>
        <select
          v-model="details.noteType"
          @change="change"
        >
          <option
            v-for="noteType in appState.noteTypes"
            :key="noteType.id"
            :value="noteType.id"
          >
            {{ noteType.text }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-show="expanded"
      v-if="details.shouldStoreInNote"
      class="form-row"
    >
      <div><label class="mr-2">Note Subject:</label></div>
      <div>
        <input
          v-model="details.noteSubject"
          type="text"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      v-if="details.shouldStoreInNote"
      class="form-row"
    >
      <div>
        <label
          class="mr-2"
          title="Comma separated list of parameter names"
        >Response JSON parameters to pluck:</label>
      </div>
      <div>
        <input
          v-model="details.caseNoteResponseParams"
          type="text"
          @change="change"
        >
      </div>
    </div>
    <div
      v-show="expanded"
      class="form-row"
    >
      <div><label class="mr-2">Update Case from response:</label></div>
      <div>
        <input
          v-model="details.shouldSetField"
          type="checkbox"
          @change="change"
        >
      </div>
    </div>
    <fieldset
      v-show="expanded"
      v-if="details.shouldSetField"
      class="mb-2"
      :class="{ 'form-error-border': errors['setFieldByJq'] }"
    >
      <legend>Update case with jq filters</legend>
      <div v-show="expanded">
        <jq-item
          v-for="(item, idx) in details.setFieldByJqItems"
          :key="item.id"
          :value="details.setFieldByJqItems[idx]"
          :add-special-matter-fields="true"
          :fields="appState.matterFieldList"
          @change="setFieldByJqItemsChange($event, idx)"
          @remove="removeSetFieldByJqItems(idx)"
        /><button
          type="button"
          @click="addSetFieldByJqItems"
        >
          Add field to set
        </button>
      </div>
    </fieldset>
    <fieldset
      v-show="expanded"
      v-if="details.shouldSetField"
      class="mb-2"
      :class="{ 'form-error-border': errors['setPhaseByJq'] }"
    >
      <legend>Create or Update service with jq filters</legend>
      <div class="form-row">
        <div><label class="mr-2">Update existing service:</label></div>
        <div>
          <input
            v-model="details.updateService"
            type="checkbox"
            @change="change"
          >
        </div>
      </div>
      <div
        v-show="expanded"
        v-if="details.updateService"
        class="form-row"
      >
        <div><label class="mr-2">Record Identifier query:</label></div>
        <div>
          <input
            v-model="details.updateServiceJqItem"
            type="text"
            @change="change"
          >
        </div>
      </div>
      <div
        v-show="expanded"
        class="form-row"
      >
        <div><label class="mr-2">Service type:</label></div>
        <div>
          <select
            v-model="details.serviceType"
            @change="change"
          >
            <option
              v-for="serviceType in appState.serviceTypes"
              :key="serviceType.id"
              :value="serviceType.id"
            >
              {{ serviceType.text }}
            </option>
          </select>
        </div>
      </div>
      <div v-show="expanded">
        <jq-item
          v-for="(item, idx) in details.createServiceByJqItems"
          :key="item.id"
          :value="details.createServiceByJqItems[idx]"
          :fields="appState.customServiceFields"
          :add-special-matter-fields="false"
          @change="createServiceByJqItemsChange($event, idx)"
          @remove="removeCreateServiceByJqItems(idx)"
        />
        <button
          type="button"
          @click="addCreateServiceByJqItems"
        >
          Add field to set
        </button>
      </div>
    </fieldset>
    <fieldset
      v-show="expanded"
      v-if="details.shouldSetField"
    >
      <legend>Update case with legacy simple expression</legend>
      <div class="form-row">
        <div><label class="mr-2">Field to set:</label></div>
        <div>
          <Multiselect
            v-model="fieldToSet"
            :options="appState.matterFieldList"
            placeholder="Choose field..."
            track-by="lookup_name"
            label="short_name"
            @select="changeField"
            @remove="changeField"
          />
        </div>
      </div>
      <div class="form-row">
        <div><label class="mr-2">Expression to get value:</label></div>
        <div>
          <input
            v-model="details.setFieldExpression"
            type="text"
            @change="change"
          >
        </div>
      </div>
      <div class="form-row">
        <div><label class="mr-2">Apply length function to value:</label></div>
        <div>
          <input
            v-model="details.setFieldApplyLength"
            type="checkbox"
            @change="change"
          >
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script lang="ts">
import Multiselect from "vue-multiselect";
import { v4 as uuidv4 } from 'uuid';
import InternalsBase from './internals_base.vue';
import ApiParameter from './api_call_parameter.vue';
import JqItem from './jq_item.vue';
export default {
  components: {
    'api-call-parameter': ApiParameter,
    'jq-item': JqItem,
    Multiselect
  },
  extends: InternalsBase,
  detailsTemplate: {
    url: '',
    method: 'GET',
    postType: 'form',
    isSynchronousRequest: false,
    isNycData: false,
    shouldStoreInNote: false,
    noteType: '',
    caseNoteResponseParams: '',
    shouldSetField: false,
    fieldToSet: null,
    setFieldExpression: '',
    setFieldApplyLength: false,
    parameters: [],
    setFieldByJqItems: [],
    createServiceByJqItems: [],
    serviceType: '',
    updateService: false,
    updateServiceJqItem: ''
  },
  data() {
    const fieldToSet = this.appState.matterFieldList.find(item => item.lookup_name === this.details.fieldToSet) || { id: null, short_name: null };
    return {
      fieldToSet,
      passwordHintLength: 4
    };
  },
  computed: {
    postTypeRequired() {
      return (this.details.method === 'POST' || this.details.method === 'PATCH' || this.details.method === 'PUT');
    },
    passwordHint() {
      return this.truncatePassword(this.details.password);
    },
    tokenHint() {
      return this.truncatePassword(this.details.token);
    }
  },
  methods: {
    changeField() {
      this.details.fieldToSet = this.fieldToSet.lookup_name;
      this.change();
    },
    addSetFieldByJqItems: function() {
      if (this.details.setFieldByJqItems === undefined) {
        this.details.setFieldByJqItems = [];
      }
      this.details.setFieldByJqItems.push({
        id: uuidv4(),
        fieldToSet: null,
        jqFilter: null
      });
      this.change();
    },
    removeSetFieldByJqItems: function(idx) {
      this.details.setFieldByJqItems = this.details.setFieldByJqItems.filter((item, itemIdx) => itemIdx !== idx);
      this.change();
    },
    addCreateServiceByJqItems: function() {
      if (this.details.createServiceByJqItems === undefined) {
        this.details.createServiceByJqItems = [];
      }
      this.details.createServiceByJqItems.push({
        id: uuidv4(),
        fieldToSet: null,
        jqFilter: null
      });
      this.change();
    },
    removeCreateServiceByJqItems: function(idx) {
      this.details.createServiceByJqItems = this.details.createServiceByJqItems.filter((item, itemIdx) => itemIdx !== idx);
      this.change();
    },
    addParameter: function() {
      this.details.parameters.push({
        id: uuidv4(),
        type: '',
        key: '',
        isPathParam: false,
        ignoreNull: false,
        sendLookupValue: false
      });
      this.change();
    },
    removeParameter: function(idx) {
      this.details.parameters = this.details.parameters.filter((item, itemIdx) => itemIdx !== idx);
      this.change();
    },
    setFieldByJqItemsChange: function(ev, idx) {
      const toUpdate = this.details.setFieldByJqItems[idx];
      toUpdate.fieldToSet = ev.fieldToSet;
      toUpdate.jqFilter = ev.jqFilter;
      this.change();
    },
    createServiceByJqItemsChange: function(ev, idx) {
      const toUpdate = this.details.createServiceByJqItems[idx];
      toUpdate.fieldToSet = ev.fieldToSet;
      toUpdate.jqFilter = ev.jqFilter;
      this.change();
    },
    parameterChange: function(ev, idx) {
      const toUpdate = this.details.parameters[idx];
      toUpdate.type = ev.type;
      toUpdate.key = ev.key;
      toUpdate.sendLookupValue = ev.sendLookupValue;
      toUpdate.isPathParam = ev.isPathParam;
      toUpdate.ignoreNull = ev.ignoreNull;
      toUpdate.staticValue = '';
      toUpdate.sourceField = '';
      if (ev.type === 'static') {
        toUpdate.staticValue = ev.staticValue;
      } else if (ev.type === 'dynamic') {
        toUpdate.sourceField = ev.sourceField;
      } else if (ev.type === 'structure') {
        toUpdate.dataStructureTypeConfig = ev.dataStructureTypeConfig;
      }
      this.change();
    },
    detailsWatchHandler: function() {
      if (this.details.method === 'GET' || this.previousDetails.method === 'GET') {
        this.details.postType = 'form';
      }
      if (this.details.shouldStoreInNote && this.details.shouldStoreInNote !== this.previousDetails.shouldStoreInNote) {
        this.details.noteType = this.appState.noteTypes.find(item => item.text === 'Guided Navigation Generated').id.toString();
      }
    },
    authTypeChange() {
      if (this.details.authType !== 'basic') {
        this.details.username = '';
        this.details.password = '';
      }
      if (this.details.authType !== 'token') {
        this.details.token = '';
      }
      this.change();
    },
    truncatePassword(value) {
      return value.length > this.passwordHintLength
        ? '...' + value.substring(value.length - this.passwordHintLength)
        : value;
    }
  }
};
</script>
<style lang="scss" scoped>
    .repeater-box {
        border: 1px solid #999;
        padding: 6px;
    }
    fieldset {
        border: 1px solid #000;
        padding: 6px;
        legend {
            width: auto;
            padding: 3px;
        }
    }
</style>
