<template>
  <transition name="modal">
    <div
      v-if="visible"
      class="modal-mask"
    >
      <div
        class="modal-wrapper"
        :class="{ no_vertical_center: noVerticalCenter }"
        :style="{ paddingTop: offsetTop + 'px' }"
      >
        <div
          class="modal-container"
          :class="{ is_wide: isWide, is_full_width: isFullWidth }"
        >
          <button
            class="close-button"
            @click="handleCancel"
          >
            <i class="fa fa-times" />
          </button>
          <div class="modal-header">
            <slot name="header" />
          </div>
          <div
            class="modal-body"
            :style="bodyStyle"
          >
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer" />
            <div
              v-if="showFooterButtons"
              class="clearfix"
            >
              <button
                class="modal-default-button"
                type="button"
                @click="handleConfirm"
              >
                <template v-if="!isSaving">
                  {{ confirmLabel }}
                </template>
                <template v-else>
                  <i class="fa fa-refresh fa-spin" />
                </template>
              </button>
              <button
                v-if="showCancel"
                class="modal-default-button"
                type="button"
                @click="handleCancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
export default {
  props: {
    isSaving: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String,
      default: 'OK'
    },
    isWide: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    showFooterButtons: {
      type: Boolean,
      default: true
    },
    noVerticalCenter: {
      type: Boolean,
      default: false
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    bodyStyle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  emits: ['cancel', 'confirm'],
  beforeUnmount() {
    const modalMask = document.querySelector('.modal-mask');
    if (modalMask && modalMask.parentNode) {
      modalMask.parentNode.removeChild(modalMask);
    }
  },
  mounted() {
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    document.querySelector('body').appendChild(this.$el);
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleConfirm() {
      this.$emit('confirm');
    }
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .15s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  &.no_vertical_center {
    vertical-align: inherit;
  }
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .15s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;

  &.is_wide {
    width: 600px;
  }

  &.is_full_width {
    width: 90%;
  }

  .form-row {
    display: flex;
    align-items: center;
    padding: 6px 0;

    label {
      width: 25%;
    }

    input {
      flex: auto;
    }

    textarea {
      flex: auto;
    }

    &.top-align {
      align-items: flex-start
    }
  }

  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 18px;
    color: #dc3545;
    cursor: pointer;

    &:hover {
      color: #c82333;
    }
  }
}

.modal-header {
  font-weight: 700;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
  margin-left: 6px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
