<template>
  <div :class="{ 'form-error-border': !isValid }">
    <div v-once />
  </div>
</template>

<script lang="ts">
 
export default {
  props: {
    rules: {
      type: Object,
      required: true
    },
    appState: {
      type: Object,
      required: true
    },
    alwaysEmitRules: {
      type: Boolean,
      default: false
    }
  },
  emits: ['rules-change', 'validation'],
  data: function() {
    return {
      currentRules: {},
      isValid: true,
      isValidated: false,
      errors: {}
    };
  },
  mounted: function() {
    this.expressionBuilder = new window.ExpressionBuilder({
      element: this.$el.childNodes[0],
      rulesChangeCallback: this.rulesChangeHandler,
      matter_field_list: this.appState.matterFieldList,
      optionsUpdateCallback: this.optionsUpdate,
      includeDateDiff: true
    });
    this.expressionBuilder.init(this.rules);
    this.currentRules = this.rules;
  },
  methods: {
    rulesChangeHandler: function(newValue) {
      let toSet = newValue;
      if (toSet === null) {
        toSet = { valid: false };
      }
      this.currentRules = toSet;
      if (toSet.valid || this.alwaysEmitRules) {
        this.$emit('rules-change', toSet);
      }
      if (this.isValidated) {
        this.validate();
      }
    },
    validate: function() {
      this.errors = {};
      this.isValid = this.currentRules.valid === true;
      if (!this.isValid) {
         
        this.errors['expression'] = 'invalid';
      }
      this.$emit('validation', this.errors);
      this.isValidated = true;
    },
    optionsUpdate: function(data) {
      const toUpdate = this.appState.matterFieldList.find(item => item.field_name === data.field);
      if (toUpdate) {
        toUpdate.option_items = data.options;
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>

