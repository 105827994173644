<template lang="pug">
.viz-root
    .hdr
        div
            div
                button.alt(type="button", @click="goBack")
                    i.fa.fa-arrow-left
                button(v-if="selectedItem === null", type="button", @click="resetGraph") Reset view
            h2(v-text="appState.dialogueName")
            div(:style="{ visibility: selectedSegment === null ? 'visible' : 'hidden' }")
                Multiselect(:placeholder="'Choose segment to explore...'",
                        :options="segmentSelectOptions",
                        track-by="id",
                        label="text",
                        style="display:none",
                        v-model="selectedSegmentToExplore",
                        @itemHover="segmentSelectHover",
                        @select="segmentSelectChange",
                        @remove="segmentSelectChange",
                        @close="removeHoverState")
        template(v-if="selectedSegment")
            h2.sub(v-text="selectedSegment.name")
    .isLoading(v-if="isInitialLoading")
        i.fa.fa-refresh.fa-spin
    .cy(ref="cy", :style="{ height: viewerHeight + 'px', visibility: selectedSegment === null ? 'visible' : 'hidden' }")
    .cySegment(ref="cySegment", :style="{ height: viewerHeight + 'px', visibility: selectedSegment === null ? 'hidden' : 'visible' }")
    .infoBox(v-if="infoBoxItem !== null")
        h3(v-text="infoBoxItem.heading")
        div(v-html="infoBoxItem.logic")
</template>

<script lang="ts">
import { useRouter } from 'vue-router';
import Util from './util.js';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
cytoscape.use(dagre);

import contextMenus from 'cytoscape-context-menus';
import jquery from 'jquery';
import Multiselect from "vue-multiselect";
contextMenus( cytoscape, jquery );

const graphStyles = [
  {
    selector: 'node',
    style: {
      'border-width': 3,
      'border-color': '#b9b9b9',
      'background-color': '#b9b9b9',
      'background-opacity': 0.7
    }
  },
  {
    selector: 'node.exit',
    style: {
      'shape': 'rectangle',
      'label': 'EXIT',
      'text-valign': 'center',
      'background-opacity': 0,
      'border-opacity': 0
    }

  },
  {
    selector: 'edge',
    style: {
      'line-color': '#c9c9c9',
      'opacity': 0.7,
      'width': 3,
      'curve-style': 'bezier',
      'control-point-step-size': 80,
      'mid-target-arrow-shape': 'triangle',
      'target-arrow-shape': 'none',
      'mid-target-arrow-color': '#c9c9c9',
      'target-arrow-color': '#c9c9c9',
      'arrow-scale': 1.5
    }
  },
  {
    selector: 'node.entryPoint',
    style: {
      'background-color': '#0f0'
    }
  },
  {
    selector: 'node.hover',
    style: {
      'border-opacity': 1,
      'background-opacity': 1,
      'border-color': '#333'
    }
  },
  {
    selector: 'edge.highlight-out',
    style: {
      'line-color': '#333',
      'mid-target-arrow-color': '#333',
      'target-arrow-color': '#333',
      'opacity': 1

    }
  },
  {
    selector: 'edge.highlight-in',
    style: {
      'line-color': '#333',
      'mid-target-arrow-color': '#333',
      'target-arrow-color': '#333',
      'opacity': 1

    }
  },
  {
    selector: 'edge.highlight-self',
    style: {
      'line-color': '#333',
      'mid-target-arrow-color': '#333',
      'target-arrow-color': '#333',
      'opacity': 1

    }
  },
  {
    selector: 'edge.default',
    style: {
      'line-color': '#00f',
      'target-arrow-color': '#00f',
      'mid-target-arrow-color': '#00f'
    }
  },
  {
    selector: 'edge.exit',
    style: {
      'mid-target-arrow-shape': 'none',
      'target-arrow-shape': 'triangle'
    }
  }
];

export default {
  components: { Multiselect },
  beforeRouteEnter: function(to, from, next) {
    next((vm) => {
      if (to.query.segmentId) {
        vm.toSelect = to.query.segmentId;
      }
      window.addEventListener('resize', vm.onResize);
    });

  },
  beforeRouteLeave: function(to, from, next) {
    window.removeEventListener('resize', this.onResize);
    next();
  },
  props: {
    'appState': {
      type: Object,
      default: () => {}
    },
    'entryPoint': {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      router: useRouter(),
      viewerHeight: 0,
      infoBoxItem: null,
      selectedItem: null,
      selectedSegment: null,
      isInitialLoading: true,
      toSelect: null,
      segmentToExplore: '',
      selectedSegmentToExplore: null
    };
  },
  computed: {
    segmentSelectOptions: function() {
      const retval = this.appState.segments.map((seg) => ({
        id: seg.id,
        text: seg.name
      }));

      retval.sort((a, b) => a.text > b.text ? 1 : (a.text < b.text ? -1 : 0));
      return retval;
    }
  },
  watch: {
    selectedItem: function(newValue) {
      this.infoBoxItem = null;

      const elements = [], addedDestinationSegments = {}, edgeIdx = 0;
      let exitDialogueNode;

      const addExit = (edgeData) => {
        if (exitDialogueNode === undefined) {
          exitDialogueNode = {
            data: {
              id: -1
            },
            classes: 'exit'
          };
          elements.push(exitDialogueNode);
        }
        let data = {
          id: this.selectedSegment.id + '_-1_' + (++edgeIdx),
          source: this.selectedSegment.id,
          target: -1
        };
        data = Object.assign(data, edgeData);
        elements.push({
          data: data,
          classes: (edgeData.isDefault ? 'default' : 'expr') + ' exit'
        });
      };

      const addEdge = (fromId, toId, edgeData) => {
        const otherId = fromId === this.selectedSegment.id ? toId : fromId;
        if (!(otherId in addedDestinationSegments)) {
          elements.push({
            data: {
              id: otherId
            }
          });
          addedDestinationSegments[otherId] = true;
        }
        let data = {
          id: fromId + '_' + toId + '_' + (++edgeIdx),
          source: fromId,
          target: toId
        };
        data = Object.assign(data, edgeData);
        elements.push({
          data: data,
          classes: edgeData.isDefault ? 'default' : 'expr'
        });
      };


      if (newValue === null) {
        this.buildFullGraph();
      } else {
        if (newValue.group === 'nodes') {
          this.selectedSegment = this.appState.segments.find((seg) => seg.id === newValue.id);
          elements.push({
            data: {
              id: this.selectedSegment.id
            }
          });
          if (this.selectedSegment.defaultDestination.is_complete) {
            addExit(
              {
                isDefault: true
              }
            );
          } else {
            if (this.selectedSegment.defaultDestination.dialogue === this.appState.dialogueId) {
              const destSegment = this.appState.segments.find((seg) => seg.id === this.selectedSegment.defaultDestination.segment);
              addEdge(
                this.selectedSegment.id,
                destSegment.id,
                {
                  isDefault: true
                }
              );
            }
          }
          this.selectedSegment.expressions.each((expr) => {
            if (expr.destination.is_complete) {
              addExit(
                {
                  rules: expr.rules
                }
              );
            } else {
              if (expr.destination.dialogue === this.appState.dialogueId) {
                const destSegment = this.appState.segments.find((seg) => seg.id === expr.destination.segment);
                addEdge(
                  this.selectedSegment.id,
                  destSegment.id,
                  {
                    rules: expr.rules
                  }
                );
              }
            }
          });
          const incomingNodes = {};
          const selectedItemInFullGraph = this.fullCy.$id(newValue.id);
          selectedItemInFullGraph.incomers().forEach((incomer) => {
            if (incomer.group() === 'nodes') {
              incomingNodes[incomer.id()] = true;
            }
          });
          Object.keys(incomingNodes).forEach((incomingId) => {
            const incomingSegment = this.appState.segments.find((seg) => seg.id === incomingId);
            if (incomingSegment.defaultDestination.segment === this.selectedSegment.id) {
              addEdge(
                incomingId,
                this.selectedSegment.id,
                {
                  isDefault: true
                }
              );
            }
            incomingSegment.expressions.forEach((expr) => {
              if (expr.destination.segment === this.selectedSegment.id) {
                addEdge(
                  incomingId,
                  this.selectedSegment.id,
                  {
                    rules: expr.rules
                  }
                );
              }
            });
          });
          this.cy = cytoscape({
            container: this.$refs.cySegment,
            elements: elements,
            style: graphStyles,
            layout: {
              name: 'dagre',
              rankDir: 'LR',
              spacingFactor: 1.4
            },
            userZoomingEnabled: false,
            userPanningEnabled: false
          });
          this.attachHandlers();
          this.addContextMenus();
        }
      }
    }
  },
  mounted: function() {
    this.setHeight();
    this.$nextTick(() => {
      this.getAllMissingOptions(() => {
        this.$nextTick(() => {
          this.isInitialLoading = false;
          this.buildFullGraph();
        });
      });
    });
  },
  methods: {
    setHeight: function() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      this.viewerHeight = h - 290;
    },
    resetGraph: function() {
      this.cy.resize();
      this.cy.fit();
    },
    onResize: function() {
      this.setHeight();
      this.$nextTick(() => {
        this.resetGraph();
      });
    },
    goBack: function() {
      if (this.selectedItem === null) {
        this.router.push({ name: 'root' });
      } else {
        this.selectedItem = null;
        this.selectedSegment = null;
        this.segmentToExplore = '';
        this.selectedSegmentToExplore = null;
        this.buildFullGraph();
      }
    },
    buildFullGraph: function() {
      if (!this.fullCy) {
        const elements = [], segmentIdsInGraph = {};
        this.appState.segments.forEach((seg) => {
          elements.push({
            data: {
              id: seg.id
            }
          });
          segmentIdsInGraph[seg.id] = true;
        });
        this.appState.segments.forEach((seg) => {
          if (seg.defaultDestination &&
            seg.defaultDestination.segment &&
            seg.defaultDestination.segment in segmentIdsInGraph &&
            parseInt(seg.defaultDestination.dialogue) === this.appState.dialogueId) {
            elements.push({
              data: {
                id: seg.id + '_' + seg.defaultDestination.segment,
                source: seg.id,
                target: seg.defaultDestination.segment,
                isDefault: true
              }
            });
          }
          if (seg.expressions) {
            seg.expressions.forEach((expr) => {
              if (expr.destination.segment &&
                expr.destination.segment in segmentIdsInGraph &&
                expr.destination.dialogue === this.appState.dialogueId) {
                elements.push({
                  data: {
                    id: seg.id + '_' + expr.destination.segment,
                    source: seg.id,
                    target: expr.destination.segment,
                    rules: expr.rules
                  }
                });
              }
            });
          }
        });
        this.fullCy = cytoscape({
          container: this.$refs.cy,
          elements: elements,
          style: graphStyles,
          layout: {
            name: 'dagre',
            rankDir: 'LR'
          }
        });
        this.fullCy.$id(this.entryPoint).addClass('entryPoint');
        this.initialZoom = this.fullCy.zoom();
        this.initialPan = this.fullCy.pan();
      } else {
        this.fullCy.forceRender();
      }
      this.cy = this.fullCy;
      this.attachHandlers();
      this.addContextMenus();
      if (this.toSelect) {
        this.cy.$id(this.toSelect).select();
        this.toSelect = null;
      }
    },
    hoverNode: function(node) {
      if (node.id() !== '-1') {
        node.addClass('hover');
        const hoveredSegment = this.appState.segments.find((seg) => seg.id === node.id());
        if (hoveredSegment) {
          this.infoBoxItem = {
            type: 'segment',
            heading: 'Segment: ' + hoveredSegment.name
          };
        }
        node.outgoers().forEach((outgoer) => {
          outgoer.addClass('highlight-out');
        });
        node.incomers().forEach((incomer) => {
          incomer.addClass('highlight-in');
        });
      }
    },
    removeHoverState: function() {
      this.cy.elements().removeClass('hover highlight-in highlight-out highlight-self');
      this.infoBoxItem = null;
    },
    attachHandlers: function() {
      this.cy.elements().on('mouseover', (evt) => {
        if (evt.target.group() === 'nodes') {
          this.hoverNode(evt.target);
        } else if (evt.target.group() === 'edges') {
          evt.target.addClass('hover');
          evt.target.addClass('highlight-self');
          const sourceSegment = this.appState.segments.find((seg) => seg.id === evt.target.source().id());
          if (sourceSegment) {
            let targetLabel = '---', logic = '---';
            if (evt.target.target().id() === '-1') {
              targetLabel = 'exit dialogue';
            } else {
              const targetSegment = this.appState.segments.find((seg) => seg.id === evt.target.target().id());
              if (targetSegment) {
                targetLabel = targetSegment.name;
              }
            }
            if (evt.target.data().isDefault) {
              logic = '(default destination)';
            } else {
              logic = Util.buildLogicText(evt.target.data().rules, this.appState.matterFieldList);
            }
            this.infoBoxItem = {
              type: 'edge',
              heading: sourceSegment.name + ' to ' + targetLabel,
              logic: logic
            };
          }
          evt.target.source().addClass('highlight-in');
          evt.target.target().addClass('highlight-out');
        }
      });
      this.cy.elements().on('mouseout', () => {
        this.removeHoverState();
      });
      this.cy.$('node').on('select', (evt) => {
        this.selectedItem = {
          group: evt.target.group(),
          id: evt.target.id()
        };
        this.segmentToExplore = evt.target.id();
        this.selectedSegmentToExplore = evt.target;
      });
    },
    addContextMenus: function() {
      this.currentContextMenus = this.cy.contextMenus({
        menuItems: [
          {
            id: 'guided_nav_visualizer_context_menu_0',
            content: 'Edit this segment',
            selector: 'node',
            onClickFunction: (evt) => {
              this.router.push({
                name: 'segment-edit',
                params: {
                  id: evt.target.id()
                }
              });
            }
          }
        ]
      });
    },
    getAllMissingOptions: function(nextStep) {
      let fieldsToUpdate = {};

      const getFieldsFromRule = (r) => {
        if (!(r.field_name in fieldsToUpdate) && (r.operator === 'in' || r.operator === 'not_in')) {
          const fieldFromList = this.appState.matterFieldList.find((f) => f.field_name === r.field);
          if (fieldFromList && fieldFromList.option_items === undefined) {
            fieldsToUpdate[r.field] = true;
          }
        }
        if (r.rules) {
          r.rules.forEach((rChild) => {
            getFieldsFromRule(rChild);
          });
        }
      };


      this.appState.segments.forEach((seg) => {
        seg.expressions.forEach((expr) => {
          getFieldsFromRule(expr.rules);
        });
      });

      fieldsToUpdate = Object.keys(fieldsToUpdate);

      const populateNextField = () => {
        const field = fieldsToUpdate.pop();
        jQuery.get(
          '/system/api/options_for_field/?module=matter&field=' + field,
          (data) => {
            if (data.results && data.results.length > 0) {
              const toUpdate = this.appState.matterFieldList.find((f) => f.field_name === field);
              if (toUpdate) {
                toUpdate.option_items = data.results;
              }
            }
            if (fieldsToUpdate.length > 0) {
              populateNextField();
            } else {
              nextStep();
            }
          }
        );
      };
      if (fieldsToUpdate.length > 0) {
        populateNextField();
      } else {
        nextStep();
      }
    },
    segmentSelectChange: function() {
      this.segmentToExplore = this.selectedSegmentToExplore ? this.selectedSegmentToExplore.id : null;
      this.cy.$id(this.segmentToExplore).select();
    },
    segmentSelectHover: function(ev) {
      this.removeHoverState();
      this.hoverNode(this.cy.$id(ev.id));
    }
  }
};
</script>

<style scoped lang="scss">
    .viz-root {
        position: relative;
        padding-top: 32px;
        .hdr {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            padding: 0;
            >div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                >:first-child, >:last-child {
                    width: 15%;
                }
                >h2 {
                    width: 70%;
                }
            }
            button {
                padding: 2px 5px 3px 4px;
                &:not(:last-child) {
                    margin-right: 6px;
                }
            }
            h2 {
                padding: 0;
                margin: 0;
                line-height: 16px;
                text-align: center;
                &.sub {
                    color: #f00;
                }
            }
        }
        .cy, .cySegment {
            z-index: 2;
        }
        .cySegment {
            position: absolute;
            top: 32px;
            left: 0;
            width: 100%;
        }
        .infoBox {
            position: absolute;
            left: 16px;
            bottom: 16px;
            padding: 6px;
            border: 1px solid #999;
            background: #ffffff;
            h3 {
                font-weight: bold;
                font-size: 110%;
            }
            z-index: 3;
        }
        .isLoading {
            position: absolute;
            height: 100%;
            width: 100%;
            background: transparent;
            font-size: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>

<style scoped>
    .cy-context-menus-cxt-menu {
        display:none;
        z-index:1000;
        position:absolute;
        border:1px solid #A0A0A0;
        padding: 0;
        margin: 0;
        width:auto;
    }

    .cy-context-menus-cxt-menuitem {
        display:block;
        z-index:1000;
        width: 100%;
        padding: 3px 20px;
        position:relative;
        margin:0;
        background-color:#f8f8f8;
        font-weight:normal;
        font-size: 12px;
        white-space:nowrap;
        border: 0;
        text-align: left;
        border-radius: inherit;
    }

    .cy-context-menus-cxt-menuitem:enabled {
        color: #000000;
    }

    .cy-context-menus-ctx-operation:focus {
        outline: none;
    }

    .cy-context-menus-cxt-menuitem:hover {
        color: #ffffff;
        text-decoration: none;
        background-color: #0B9BCD;
        background-image: none;
        cursor: pointer;
    }

    .cy-context-menus-cxt-menuitem[content]:before {
        content:attr(content);
    }

    .cy-context-menus-divider {
        border-bottom:1px solid #A0A0A0;
    }
</style>
