<template>
  <!--eslint-disable vue/no-v-html-->
  <div>
    <div v-if="segment.segmentType === 'legal_issue'">
      <div style="padding:6px">
        Legal issue evaluation segment
      </div>
    </div>
    <div
      v-for="element in segment.elements"
      :key="element.id"
    >
      <div
        class="wrapper"
        style=""
      >
        <div
          class="label"
          style=""
        >
          {{ makeLabel(element) }}
        </div>
        <div
          class="value"
          v-html="summarize(element)"
        />
      </div>
    </div>
    <div style="padding:6px;color:red;">
      {{ outputExpressions }}
    </div>
  </div>
</template>

<script lang="ts">
const fieldTypeLabels = {
  'instructions': 'Instructions',
  'headline': 'Headline',
  'field': 'Field',
  'block': 'Dynamic Form Block',
  'noteForSummary': 'Add summary note',
  'blockDefault': 'Set block default',
  'document': 'Generate document',
  'caseNote': 'Add case note',
  'apiCall': 'Call an API',
  'fieldSetValueAction': 'Set value of field',
  'setLanguageAction': 'Set NLU intake language'
};
export default {
  props: {
    segment: {
      type: Object,
      required: true
    },
    appState: {
      type: Object,
      required: true
    }
  },
  computed: {
    outputExpressions: function() {
      return ((this.segment.expressions && this.segment.expressions.length > 0)
        ? this.segment.expressions.length
        : 'No') +
        ' output expressions defined';
    }
  },
  methods: {
    summarize: function(element) {
      let retval = '';
      const languageId = parseInt(element.details.language);
      const languageSelection = this.appState.languageOptions.find(opt => opt.id === languageId);
      switch (element.elementType) {
      case 'headline':
        retval += element.details.headlineText;
        break;
      case 'instructions':
        retval += element.details.instructionsText;
        break;
      case 'fieldSetValueAction':
      case 'field':
        const field = this.appState.matterFieldList.find((item) => item.lookup_name === element.details.field);
        retval += field ? field.short_name : 'Missing field selection';
        break;
      case 'block':
      case 'blockDefault':
        let block = 'Unknown block';
        this.appState.blocks.forEach((group) => {
          group.children.forEach((b) => {
            if (b.id === element.details.block) {
              block = b.text;
            }
          });
        });
        retval += block;
        break;
      case 'noteForSummary':
        retval += element.details.summaryText;
        break;
      case 'document':
        // eslint-disable-next-line eqeqeq
        const doc = this.appState.documentTemplates.find((d) => d.id == element.details.document);
        retval += doc ? doc.text : 'Unknown document';
        break;
      case 'caseNote':
        retval += element.details.caseNoteText;
        break;
      case 'apiCall':
        retval += element.details.url;
        break;
      case 'setLanguageAction':
        if (languageSelection) {
          retval += 'Set NLU intake language to ' + languageSelection.name;
        } else {
          retval += 'Not configured';
        }
        break;
      default:
        retval += JSON.stringify(element.details);
      }
      return retval;
    },
    makeLabel: function(element) {
      return element.elementType in fieldTypeLabels ? fieldTypeLabels[element.elementType] : element.elementType;
    }
  }

};
</script>

<style scoped lang="scss">
    .wrapper {
        display: flex;
        padding: 6px;
        align-items: center;
    }
    .label {
        width: 100px;
    }
    .value {
        overflow:hidden;
        max-width: 90%;
        border: 1px solid #e3e3e3;
        padding: 4px;
    }
</style>

