<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row
        div: label(title="Block to render") Block:
            span.form-required *
        div(:class="{ 'form-error-border': errors['block'] }")
        Multiselect(:options="appState.blocks",
                ref="multiselect",
                placeholder="Choose block...",
                v-model="blockChoice",
                track-by="id",
                label="text",
                group-label="text"
                group-values="children"
                @remove="changeBlock",
                @select="changeBlock")
    div.form-row(v-if="details.block && details.block !== ''", v-show="expanded")
        div.block-config(ref="blockConfigForm", v-html="details.blockConfigFormHtml")
    teleport(v-if="scriptDetails" defer to="head")
        component(is="script",
          async,
          defer,
          :src="scriptDetails.src"
          :type="scriptDetails.type"
          :onload="scriptDetails.onload")
</template>
<script lang="ts">
import Multiselect from 'vue-multiselect';
import { ajaxGetFormMarker, ajaxRequest } from '@networkninja/common';
import InternalsBase from './internals_base.vue';
import { findObjectInGroupedData } from '../../util.js';
export default {
  components: { Multiselect },
  extends: InternalsBase,
  detailsTemplate: {
    block: '',
    blockConfigFormValues: [],
    blockConfigFormHtml: '',
    blockConfigFormRequiredFields: []
  },
  emits: ['element-expand'],
  data: function() {
    const blockObject = findObjectInGroupedData(this.appState.blocks, this.details.block);
    const blockChoice = blockObject ? { id: blockObject.id, text: blockObject.text } : { id: null, text: null };
    return {
      blockChoice,
      multiselect: null,
      formRequestPending: false,
      configFormLoaded: false,
      scriptDetails: null
    };
  },
  watch: {
    expanded() {
      if (!this.configFormLoaded && this.details.block && this.details.block !== '') {
        this.createBlockConfigForm();
      }
    }
  },
  created: function() {
    this.details.blockConfigFormHtml = '';
    if (this.details.blockConfigFormValues === undefined) {
      this.details.blockConfigFormValues = [];
    }
  },
  methods: {
    changeBlock() {
      this.details.block = this.blockChoice.id;
      this.change();
    },
    createBlockConfigForm: function() {
      const { formName, hidden } = ajaxGetFormMarker(this.appState.root);
      this.configFormLoaded = false;
      this.details.blockConfigFormValues = [];
      this.details.blockConfigFormRequiredFields = [];
      this.details.blockConfigFormHtml = '<div style="width:100%;text-align:center;padding:24px 0;">Loading form...</div>';
      this.formRequestPending = true;
      ajaxRequest(
        [
          this.elementId,
          this.details
        ],
        formName,
        hidden,
        'get_block_config_form',
        this.appState.root)
        .then(responseData => {
          this.details.blockConfigFormHtml = responseData.html;
          this.$nextTick(() => {
            const $inputs = jQuery(this.$refs.blockConfigForm).find('input,select,textarea');
            const updateFormValues = () => {
              $inputs.each(function() {
                if (jQuery(this).hasClass('chosen-select')) {
                  jQuery(this).show();
                }
              });
              const vals = $inputs.serializeArray();
              $inputs.each(function() {
                if (jQuery(this).hasClass('chosen-select')) {
                  jQuery(this).hide();
                }
              });
              $inputs.each((idx, inp) => {
                const $inp = jQuery(inp);
                if ($inp.attr('type') === 'checkbox' && !$inp.is(':checked')) {
                  vals.push({
                    name: $inp.attr('name'),
                    value: false
                  });
                }
              });
              this.details.blockConfigFormValues = vals;
              this.change();
            };

            const requiredFields = [];
            $inputs.each((idx, inp) => {
              const $inp = jQuery(inp);
              if ($inp.attr('aria-required')) {
                requiredFields.push({
                  name: $inp.attr('name')
                });
              }
            });
            this.details.blockConfigFormRequiredFields = requiredFields;

            $inputs.each((idx, el) => {
              jQuery(el).change(updateFormValues);
              jQuery(el).on('nni:autocomplete_change', updateFormValues);
              jQuery(el).on('nni:change', updateFormValues);
            });
            if (responseData.scriptDetails) {
              this.scriptDetails = {
                type: responseData.scriptDetails.script_path.match('es.js') ? 'module' : 'text/javascript',
                src: responseData.scriptDetails.script_path,
                onload: () => {
                  LSUtil.ExtendByNames(
                    responseData.scriptDetails.element_id,
                    responseData.scriptDetails.prototype_name,
                    responseData.scriptDetails.js_prototype_args
                  );
                }
              };
            }
            this.$nextTick(() => {
              eval(responseData.js);
              this.$nextTick(updateFormValues);
              this.formRequestPending = false;
              this.configFormLoaded = true;
            });
          });
        });
    },
    detailsWatchHandler: function() {
      if (!this.formRequestPending && (this.details.block && this.details.block !== '') && this.details.block !== this.previousDetails.block) {
        this.details.blockConfig = {};
        this.$emit('element-expand');
        this.createBlockConfigForm();
      }
    }
  }
};
</script>
