<template>
  <div class="custom-lookup-editor">
    <div class="form-row">
      <label>Description:</label>
      <input
        v-model="description"
        type="text"
        @change="change"
      >
    </div>
    <div class="values-heading">
      <h3>Lookup values:</h3>
    </div>
    <div
      ref="valuesContainer"
      class="values"
    >
      <div
        v-for="(value, idx) in values"
        :key="value.id"
        class="form-row"
        :data-id="value.id"
      >
        <input
          v-model="value.label"
          type="text"
          @change="change"
        >
        <div>
          <i
            class="fa fa-times"
            @click="removeValue(idx)"
          />
        </div>
      </div>
    </div>
    <button
      type="button"
      @click="addValue"
    >
      Add value
    </button>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
export default {
  emits: ['input', 'change'],
  data: function() {
    return {
      description: '',
      values: []
    };
  },
  created: function() {
    for (let i = 0; i < 6; ++i) {
      this.addValue();
    }
  },
  methods: {
    addValue: function() {
      this.values.push({
        id: uuidv4(),
        label: ''
      });
      this.change();
    },
    removeValue: function(idx) {
      this.values.splice(idx, 1);
      this.change();
    },
    change: function() {
      this.$emit(
        'input',
        {
          description: this.description,
          values: this.values.map((v) => Object.assign({}, v))
        }
      );
      this.$emit('change');
    }
  }
};
</script>

<style scoped lang="scss">
    .custom-lookup-editor {
        .values-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
                padding: 12px 0;
                font-weight: 700;
            }
        }
        .values {
            margin-bottom: 12px;
            .form-row {
                display: flex;
                justify-content: space-between;
                border: 1px solid #c9c9c9;
                padding: 12px;
                &:not(:last-child) {
                    margin-bottom: 6px;
                }
                input {
                    width: 400px;
                    flex: auto;
                }
                >div {
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .fa-times {
                        cursor: pointer;
                        color: #D9534F;
                    }
                }
            }
        }
        button {
            margin: 0;
        }
    }
</style>

