<template lang="pug">
extends ./internals_base.pug
block rows
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Subject for case note") Case note subject (optional):
        div: input(type="text", v-model="details.caseNoteSubject", @change="change")
    div.form-row(v-show="expanded")
        div: label.mr-2(title="Text to show in dialogue") Case note text:
            span.form-required *
        div(:class="{ 'form-error-border': errors['instructionsText'] }")
            tiptap(v-model="details.caseNoteText",
                         v-if="showEditor"
                         ref="editor")
</template>
<script lang="ts">
import TipTap from '../../../../tiptap/src/app.vue';
import InternalsBase from './internals_base.vue';

export default {
  components: {
    'tiptap': TipTap,
  },
  extends: InternalsBase,
  detailsTemplate: {
    caseNoteSubject: '',
    caseNoteText: ''
  },
  data: function() {
    return {
      showEditor: true,
      rich_text_engine: this.appState.rte_engine
    };
  }
};
</script>
