<template lang="pug">
.element(v-bind:data-id="id")
    .handle
    button.closeButton.btn_bare.btn_danger(type="button", @click="remove"): i.fa.fa-times
    button.expandButton.btn_bare(type="button",  @click="toggleExpanded")
        i.fa(:class="{ 'fa-caret-down' : expanded, 'fa-caret-right': !expanded }")
    .form-row( v-show="expanded")
        div
            label.mr-2(title="Logic controlling showing / executing this element") Entering logic:
                span.form-required *
        div
            .radio
                label
                    input(type="radio", value="always", :name="'displayWhen_' + id", v-model="displayWhen", @change="change")
                    | Always true
                label
                    input(type="radio", value="expression", :name="'displayWhen_' + id", v-model="displayWhen", @change="change")
                    | Use expression
    expression-builder(v-if="expanded && displayWhen === 'expression'",
                       :app-state="appState",
                       ref="expressionBuilder",
                       v-on:rules-change="displayWhenRulesChangeHandler",
                       v-on:invalid-expression="displayWhenRulesInvalidHandler",
                       v-bind:rules="displayWhenRules",
                       :alwaysEmitRules="true",
                       v-show="expanded",

                       :class="{ 'form-error-border': errors['enteringLogic'] }")
    .form-row
        div: label.mr-2(title="Type of element to insert") Element type:
            span.form-required *
        div
            select(v-model="elementType", @change="changeElementType(true)", :class="{ 'form-error-border': errors['elementType'] }")
                option(value="") Select element type...
                option(:value="elementTypeOption.value", v-for="elementTypeOption in elementTypeOptions") {{ elementTypeOption.label }}
    div( v-show="!expanded && displayWhenCollapseSummary !== ''", class="collapseSummary", v-html="displayWhenCollapseSummary")
    div.form-row(v-show="expanded", v-if="isAction")
      div(class="red") Actions can only be used on the first page of an intake when selecting "When segment is submitted" as the trigger. Failure to do so will result in indeterminate and undesired behavior.
    div.form-row( v-show="expanded", v-if="isAction")
        div: label(title="Should this action run when the segment is shown or submitted?") Run this action when segment is shown or submitted?
        div
            .radio
                label
                    input(type="radio", value="onRender", :name="'runActionWhen_' + id", v-model="details.runWhen", @change="change")
                    | When segment is shown
                label
                    input(type="radio", value="onSubmit", :name="'runActionWhen_' + id", v-model="details.runWhen", @change="change")
                    | When segment is submitted
    component(v-if="elementType && elementType !== ''",
        :is="elementType",
        ref="internals",
        :details="details",
        :previousDetails="previousDetails",
        :appState="appState",
        :elementId="id",
        :change="change",
        @element-expand="expanded = true",
        :errors="errors",
        :warnings="warnings",
        :expanded="expanded")
    .form-row( v-show="expanded", v-if="appState.enableNLU")
        div
            label.mr-2(title="Include only in certain channels") Include in only some channels:
                span.form-required *
        div
            .radio
                label
                    input(type="radio", :value="true", :name="'includeAllChannels_' + id", v-model="includeAllChannels", @change="change")
                    | Include in all channels
                label
                    input(type="radio", :value="false", :name="'includeAllChannels' + id", v-model="includeAllChannels", @change="change")
                    | Select channels
            div(v-if="!includeAllChannels").pt-1
              label.pr-1
                input(type="checkbox", v-model="channelsToIncludeIn", value="web", @change="change").pr-1
                | Web
              label.pr-1
                input(type="checkbox", v-model="channelsToIncludeIn", value="sms", @change="change").pr-1
                | SMS
              label
                input(type="checkbox", v-model="channelsToIncludeIn", value="voice", @change="change").pr-1
                | Voice
    div.form-row( v-show="expanded", v-if="!isAction")
        div: label(title="Set logical expression to determine if early 'next' button should be shown") Show adjacent next button with logic:
        div
            input(type="checkbox", v-model="hasEarlyNextRules")
    expression-row(
                   v-show="expanded",
                   :hideRowControls="true",
                   :appState="appState",
                   v-if="hasEarlyNextRules",
                   @destination-change="changeEarlyNextDestination($event)",
                   @rules-change="changeEarlyNextRules($event.rules)",
                   :initial-config="{ rules: earlyNextRules, destination: earlyNextDestination }",
                   :other-segments="otherSegments",
                   @create-segment="createSegment")
</template>

<script lang="ts">
import { useRoute } from 'vue-router';
import ExpressionBuilder from '../expression_builder.vue';
import ExpressionRow from '../expression_row.vue';
import Headline from './internals/headline.vue';
import Instructions from './internals/instructions.vue';
import NoteForSummary from './internals/note_for_summary.vue';
import Field from './internals/field.vue';
import Block from './internals/block.vue';
import BlockDefault from './internals/block_default.vue';
import Document from './internals/document.vue';
import CaseNote from './internals/case_note.vue';
import ApiCall from './internals/api_call.vue';
import FieldSetValueAction from './internals/field_value_set_action.vue';
import Fieldset from './internals/fieldset.vue';
import SetLanguageAction from './internals/set_language_action.vue';

import Util from '../util.js';

export default {
  components: {
    'expression-builder': ExpressionBuilder,
    'expression-row': ExpressionRow,
    'headline': Headline,
    'instructions': Instructions,
    'field': Field,
    'block': Block,
    'blockDefault': BlockDefault,
    'document': Document,
    'caseNote': CaseNote,
    'apiCall': ApiCall,
    'noteForSummary': NoteForSummary,
    'fieldSetValueAction': FieldSetValueAction,
    'fieldset-element': Fieldset,
    'setLanguageAction': SetLanguageAction
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    appState: {
      type: Object,
      required: true
    },
    otherSegments: {
      type: Array,
      required: true
    }
  },
  emits: ['create-segment', 'remove', 'update:modelValue', 'validation', 'warningsChecked'],
  data: function() {
    return {
      id: this.modelValue.id,
      expanded: false,
      elementType: this.modelValue.elementType,
      displayWhen: this.modelValue.displayWhen,
      displayWhenRules: (this.modelValue.displayWhen === 'expression' && this.modelValue.displayWhenRules) ? this.modelValue.displayWhenRules : {},
      invalidDisplayWhenRules: false,
      hasEarlyNextRules: this.modelValue.hasEarlyNextRules ? this.modelValue.hasEarlyNextRules : false,
      earlyNextRules: this.modelValue.earlyNextRules ? this.modelValue.earlyNextRules : {},

      earlyNextDestination: this.modelValue.earlyNextDestination ? this.modelValue.earlyNextDestination : { is_complete: false, dialogue: this.appState.dialogueId, segment: '' },
      details: this.modelValue.details || {},
      isValid: true,
      isValidated: false,
      isInitialDisplayWhenValidation: false,
      errors: {},
      warnings: {
        field: []
      },
      previousDetails: {},
      includeAllChannels: null,
      channelsToIncludeIn: !Array.isArray(this.modelValue.channelsToIncludeIn) ? [] : this.modelValue.channelsToIncludeIn
    };
  },
  computed: {
    isAction() {
      return this.elementType === 'blockDefault' ||
        this.elementType === 'document' ||
        this.elementType === 'caseNote' ||
        this.elementType === 'apiCall' ||
        this.elementType === 'fieldSetValueAction';
    },
    displayWhenCollapseSummary() {
      if (this.displayWhen === 'expression') {
        return 'When&nbsp;<span class="logic-text">' +
          Util.buildLogicText(this.displayWhenRules, this.appState.matterFieldList) +
          '</span>';
      } else {
        return '';
      }
    },
    elementTypeOptions() {
      const allOptions = [
        { value: 'headline', label: 'Headline' },
        { value: 'instructions', label: 'Instructions' },
        { value: 'field', label: 'Field to capture' },
        { value: 'fieldset-element', label: 'Fieldset (experimental)' },
        { value: 'block', label: 'Block' },
        { value: 'noteForSummary', label: 'Note for summary' },
        { value: 'blockDefault', label: 'Action: Default value for a block' },
        { value: 'document', label: 'Action: Generate a document' },
        { value: 'caseNote', label: 'Action: Add a case note' },
        { value: 'apiCall', label: 'Action: Perform an API call' },
        { value: 'fieldSetValueAction', label: 'Action: Set a field\'s value' }
      ];
      return allOptions.filter(o =>
        (o.value !== 'fieldset-element' || this.appState.enableFieldset) &&
        (o.value !== 'setLanguageAction' || this.appState.enableMultilingual) &&
        (
          this.appState.mode !== 'standalone' ||
          ['headline', 'instructions', 'field', 'block'].indexOf(o.value) >= 0
        )
      );
    }
  },
  watch: {
    details:  {
      deep: true,
      handler: function() {
        if (this.$refs.internals) {
          this.$refs.internals.detailsWatchHandler();
        }
        this.previousDetails = Object.assign({}, this.details);
        if (this.isValidated) {
          this.validate();
        }
        this.checkWarnings();
      }
    },
    elementType: function() {
      this.isValidated = false;
      this.errors = {};
      this.emitValidation();
      this.expanded = true;
    },
    displayWhen: function(newValue) {
      if (newValue === 'always') {
        this.displayWhenRules = {};
      }
    }
  },
  created: function() {
    const route = useRoute();
    // Copy from previous "inludeInNLU" property
    if (this.modelValue.includeAllChannels !== undefined) {
      this.includeAllChannels = this.modelValue.includeAllChannels;
    } else {
      this.includeAllChannels = (this.modelValue.includeInNLU === undefined || this.modelValue.includeInNLU);
      if (!this.includeAllChannels) {
        this.channelsToIncludeIn = ['web'];
      }
    }

    this.previousDetails = Object.assign({}, this.details);
    if (this.isAction && !this.details.runWhen) {
      this.details.runWhen = 'onRender';
    }
    if (this.elementType === 'instructions' && !this.details.editorMode) {
      this.details.editorMode = 'wysiwyg';
    }
    if (route && route.params.element === this.id) {
      this.expanded = true;
    }
    if (Object.keys(this.displayWhenRules).length === 0) {
      this.isInitialDisplayWhenValidation = true;
    }
    if (this.elementType === 'apiCall' && !this.details.authenticationType) {
      this.details.authenticationType = 'none';
    }
    this.checkWarnings();
  },
  methods: {
    toggleExpanded: function() {
      if (this.expanded) {
        this.isInitialDisplayWhenValidation = false;
        this.validate();
        this.checkWarnings();
        if (this.isValid) {
          this.expanded = !this.expanded;
        }
      } else {
        this.expanded = !this.expanded;
      }
    },
    createSegment: function(callback) {
      this.$emit('create-segment', callback);
    },
    changeElementType: function() {
      this.details = Object.assign({}, this.$options.components[this.elementType].detailsTemplate);
      if (this.isAction) {
        this.details.runWhen = 'onRender';
      }
      this.change();
    },
    change: function() {
      const toEmit = {
        id: this.id,
        elementType: this.elementType,
        displayWhen: this.displayWhen,
        hasEarlyNextRules: this.hasEarlyNextRules,
        earlyNextRules: this.earlyNextRules,
        earlyNextDestination: this.earlyNextDestination,
        includeAllChannels: this.includeAllChannels,
        details: JSON.parse(JSON.stringify(this.details)),
        channelsToIncludeIn: this.channelsToIncludeIn
      };
      if (this.displayWhen === 'expression') {
        toEmit.displayWhenRules = JSON.parse(JSON.stringify(this.displayWhenRules));
      }
      this.$emit('update:modelValue', toEmit);
    },
    remove: function() {
      this.$emit('remove', { id: this.id });
    },
    checkWarnings: function() {
      this.warnings = {
        field: []
      };

      if (this.elementType === 'field') {
        if (this.hasDuplicateElement()) {
          this.warnings.field.push('A Field To Populate is present multiple times on this page.  This may cause unexpected behavior if both fields are shown at the same time.');
        }
      }
      this.emitWarnings();
    },
    validate: function() {
      // @TODO switch to a validation framework such as the one used in offline_mode
      this.errors = {};
      this.isInitialDisplayWhenValidation = false;
      if (this.elementType === undefined || this.elementType === '') {

        this.errors['elementType'] = ['required'];
      } else {
        if (this.elementType === 'instructions') {
          if (this.details.instructionsText === undefined || this.details.instructionsText.length === 0) {

            this.errors['instructionsText'] = ['required'];
          }
        } else if (this.elementType === 'headline') {
          if (this.details.headlineText === undefined || this.details.headlineText.length === 0) {

            this.errors['headlineText'] = ['required'];
          }
        } else if (this.elementType === 'field') {
          if (this.details.field === undefined || this.details.field === null || this.details.field.length === 0) {

            this.errors['field'] = ['required'];
          }
        } else if (this.elementType === 'fieldSetValueAction') {
          if (this.details.field === undefined || this.details.field === null || this.details.field.length === 0) {

            this.errors['field'] = ['required'];
          }

          // eslint-disable-next-line eqeqeq
          if (this.details.value === undefined || this.details.value === null || this.details.value.length == 0) {

            this.errors['value'] = ['required'];
          }
        } else if (this.elementType === 'setLanguageAction') {
          if (this.details.language === undefined || this.details.language === null || this.details.language.length === 0) {
            this.errors.language = ['required'];
          }
        } else if (this.elementType === 'apiCall') {
          if (this.details.shouldSetField) {
            this.details.setFieldByJqItems.forEach(item => {
              if (!item.fieldToSet || !item.jqFilter) {
                this.errors.setFieldByJq = ['required'];
              }
            });
          }
        } else if (this.elementType === 'block') {
          // @TODO this is a bit awkward, but the only way to make it work in the existing validation framework.
          if (this.details.block === undefined || this.details.block === null || this.details.block.length === 0) {

            this.errors['block'] = ['required'];
          }
          if (this.details.blockConfigFormRequiredFields !== undefined) {
            this.details.blockConfigFormRequiredFields.forEach(item =>
            {
              if (this.details.blockConfigFormValues) {
                const field = this.details.blockConfigFormValues.find(f => f.name === item.name);
                if(field) {
                  const el = document.getElementsByName(field.name)[0];
                  const container = el.parentNode;
                  let isDisplayed = true;
                  if (container && container.getAttribute('style') && container.getAttribute('style').includes('display: none')) {
                    isDisplayed = false;
                  }
                  if (isDisplayed && (field.value === undefined || this.details.field === null || field.value.length === 0)) {
                    this.errors[el] = ['required'];
                    el.classList.add('form-error');
                  }
                }
              }
            });
          }
        }
      }
      if (this.displayWhen === 'expression') {
        // Perform some validation from outside to guard against cases where expressions were corrupted from
        // front-end issues with lookup value dropdowns
        if (this.displayWhenRules && this.displayWhenRules.rules) {
          const rulesAreValid = Util.validateLogic(this.displayWhenRules.rules);
          this.invalidDisplayWhenRules = rulesAreValid !== true;
        }

        if (this.invalidDisplayWhenRules || this.displayWhenRules === 'undefined' || this.displayWhenRules === null || this.displayWhenRules.rules === undefined) {

          this.errors['enteringLogic'] = ['required'];
        }
      }
      this.emitValidation();
      this.isValidated = true;
      this.isValid = Object.keys(this.errors).length === 0;
      if (!this.isValid) {
        this.expanded = true;
      }
    },
    // Return true if there is a sibling element of the same type with the the same details.field.
    hasDuplicateElement: function() {
      if (this.$parent.$children === undefined) {
        return false;
      }
      return this.$parent.$children.some(function(sibling) {
        return sibling !== this
          && sibling.elementType === this.elementType
          && this.details
          && sibling.details
          && this.details.field === sibling.details.field;
      }, this);
    },
    emitValidation: function() {
      this.$emit('validation', this.errors);
    },
    emitWarnings: function() {
      this.$emit('warningsChecked', this.warnings);
    },
    displayWhenRulesChangeHandler: function(newValue) {
      this.displayWhenRules = newValue;
      this.invalidDisplayWhenRules = false;
      this.change();
      if (this.isValidated) {
        this.validate();
      }
      this.checkWarnings();
    },
    displayWhenRulesInvalidHandler: function() {
      if (this.isInitialDisplayWhenValidation === false) {
        this.invalidDisplayWhenRules = true;
        this.validate();
        this.checkWarnings();
      }
    },
    changeEarlyNextRules: function(newValue) {
      if (newValue.valid) {
        this.earlyNextRules = newValue;
        this.change();
      }
    },
    changeEarlyNextDestination: function(newValue) {
      this.earlyNextDestination = newValue;
      this.change();
    }
  }
};
</script>

<style lang="scss" scoped>
    .guided_navigation .element {
        position: relative;
        border: 1px solid #d9d9d9;
        background: #f9f9f9;
        padding: 0 12px 6px 26px;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        .closeButton {
            position: absolute;
            top: 12px;
            right: 12px;
        }
        .expandButton {
            position: absolute;
            top: 12px;
            left: 12px;
        }
        button.expandButton {
            color: black;
        }
        .handle {
            width: 7px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: move;
            background-image: url("/template/ls3/image/handle.png");
            background-repeat: repeat-y;
            background-position: top left;
        }
        .form-row {
            min-height: 28px;
            .radio {
                label {
                    font-weight: normal;
                    font-size: 11px;
                }
            }
            .block-config {
                padding: 12px;
                background: #eaf2f5;
                border: 1px solid #c9c9c9;
            }
        }
        .collapseSummary {
            min-height: 28px;
            display: flex;
            align-items: center;
            .field {
                color: blue;
            }
            .logic-text {
                background:rgba(250, 240, 210, 0.5);
                border:1px solid black;
                padding:3px;
            }
            .destination {
                background:rgba(250, 240, 210, 0.5);
                border:1px solid black;
                padding:3px;
            }
            .value {
                color:green;
            }
            .operator {
                font-weight: 700;
            }
        }
        label+input[type=text] {
            margin-left: 0.5em;
        }
    }
</style>
